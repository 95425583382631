import { Button } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomTableV2 from "../../Components/CustomTable/CustomTableV2";
import {
  exportCallingDashboard,
  getCallingDashboardData
} from "../../api";
import { capitalize } from "../../utility/Formater";

const CallingDashboard = ({ type, filter }) => {
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchTableData = async () => {
    try {
      setLoading(true);
      const { data } = await getCallingDashboardData(filter);
      setTableData(data?.data);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "failed to fetch data !"
      );
    } finally {
      setLoading(false);
    }
  };
  const exportData = async (template_name) => {
    let { data } = await exportCallingDashboard(
      template_name,
      type,
      filter
    );
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${type}-${moment().format("DD-MM-YYYY")}-${filter}-${template_name}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };
  
  let columns_calling = [
    {
      label: "Template Name",
      render: (index) => {
        return capitalize(tableData?.call_data?.[index]?.template_name, /[ _]/g);
      },
      width: 200,
    },
    {
      label: "Total Calls",
      render: (index) => {
        return tableData?.call_data?.[index]?.total;
      },
      width: 200,
    },
    {
      label: "Answered",
      render: (index) => {
        return tableData?.call_data?.[index]?.answered;
      },
      width: 200,
    },
    {
      label:<span className="text-nowrap" >Not Answered</span>,
      render: (index) => {
        return tableData?.call_data?.[index]?.not_answered;
      },
      width: 100,
    },
    {
      label: "",
      render: (index) => {
        return (
          <Button
            onClick={() => {
              exportData(tableData?.call_data?.[index]?.template_name);
            }}
          >
            Download
          </Button>
        );
      },
    },
  ];

  const columns_messaging = [
    {
      label: "Template Name",
      render: (index) => {
        let temp_name = tableData?.fyno_data?.[index]?.template_name;
        return <span key={temp_name}>{capitalize(temp_name, "_")}</span>;
      },
      width: 200,
    },
    {
      label: "Sent",
      render: (index) => {
        return tableData?.fyno_data?.[index]?.sent;
      },
      width: 200,
    },
    {
      label: "Replies",
      render: (index) => {
        return tableData?.fyno_data?.[index]?.replies_count;
      },
      width: 200,
    },
    {
      label: "",
      render: (index) => {
        return (
          <Button
            onClick={() => {
              exportData(tableData?.fyno_data?.[index]?.template_name);
            }}
          >
            Download
          </Button>
        );
      },
    },
  ];


  useEffect(() => {
    fetchTableData();
  }, [filter]);

  const columns = type == "calling" ? columns_calling : columns_messaging;
  const data = type == "calling" ? tableData?.call_data : tableData?.fyno_data;

  return (
    <>
      <div>
        <div style={{ width: "100%" }}>
          <CustomTableV2
            tableData={data}
            tableHeight={500}
            columns={columns}
            loadingRows={8}
            isLoading={loading}
            borderBottom={true}
          />
        </div>
      </div>
    </>
  );
};

export default CallingDashboard;
