import customerCard from "../assets/customerCard.svg";
import drivingLicense from "../assets/drivingLicense.svg";
import otherProofIco from "../assets/otherProofIco.svg";
import loanAgreeIco from "../assets/loanAgreeIco.svg";
import stampIco from "../assets/stampIco.svg";
import dualFormIco from "../assets/dualFormIco.svg";
import chasisIco from "../assets/chasisIco.svg";
import erickIco from "../assets/erickIco.svg";
import form20Ico from "../assets/form20Ico.svg";
import form21Ico from "../assets/form21Ico.svg";
import form22Ico from "../assets/form22Ico.svg";
import form26Ico from "../assets/form26Ico.svg";
import insuranceIco from "../assets/insuranceIco.svg";
import quaotationIco from "../assets/quaotationIco.svg";
import salesInvoiceIco from "../assets/salesInvoiceIco.svg";
import trcIco from "../assets/trcIco.svg";
import warrantyIco from "../assets/warrantyIco.svg";
import passbookIco from "../assets/passbookIco.svg";
import bsvIco from "../assets/bsvIco.svg";
import cheque from "../assets/cheque.svg";
import e_ricksha from "../assets/e_ricksha.svg";
import aadhar from "../assets/aadhar.svg";
import pan from "../assets/pan.svg";
import rcIcon from "../assets/rcIcon.svg";
import recieptIcon from "../assets/recieptIcon.svg";
import inside_house from "../assets/inside_house.svg";
import outside_house from "../assets/outside_house.svg";
import electic_meter from "../assets/electic_meter.png";
import total_loans from "../../src/assets/totalLoans.svg";
import total_loan_amount from "../../src/assets/totalLoanAmount.svg";
import total_applications from "../../src/assets/totalApplications.svg";
import repaid_loans from "../../src/assets/repaidLoans.svg";
import repaid_loan_amount from "../../src/assets/repaidLoanAmount.svg";
import collected_amount from "../../src/assets/collectedAmount.svg";
import aum from "../../src/assets/aum.svg";
import nbfc_icon from "../../src/assets/nbfc_icon.svg"
import embifi_icon from "../../src/assets/embifi_icon.svg"
import active_loans from "../../src/assets/activeLoans.svg";
import WhatsAppImg from "../../src/assets/WhatsApp.svg";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DealerMarker from "../../src/assets/DealerMarker.svg";
import CustomerMarker from "../../src/assets/CustomerMarker.svg";
import AgentMarker from "../../src/assets/AgentMarker.svg"

export const icons = {
  aadhaar_front: aadhar,
  aadhaar_back: aadhar,
  applicant_pan: pan,
  driving_license: drivingLicense,
  customer_photo: customerCard,
  other_ownership_proof: otherProofIco,
  co_customer_pan: pan,
  co_customer_aadhaar_front: aadhar,
  co_customer_aadhaar_back: aadhar,
  co_customer_ownership_proof: otherProofIco,
  co_customer_photo: customerCard,
  customer_house_inside: inside_house,
  customer_house_outside: outside_house,
  customer_photo_with_electricity_meter: electic_meter,
  loan_agreement: loanAgreeIco,
  stamp_paper: stampIco,
  dual_form: dualFormIco,
  sales_invoice: salesInvoiceIco,
  quotation: quaotationIco,
  erick_delivery_photo: e_ricksha,
  chassis_number: chasisIco,
  battery_warranty: warrantyIco,
  insurance: insuranceIco,
  form_20: form20Ico,
  form_21: form21Ico,
  form_22: form22Ico,
  form: form26Ico,
  trc: trcIco,
  cheques: cheque,
  bsv: bsvIco,
  cibil_report: otherProofIco,
  bank_passbook: passbookIco,
  registration_certificate: rcIcon,
  courier_receipt: recieptIcon,
  total_loans: total_loans,
  total_loan_amount: total_loan_amount,
  total_applications: total_applications,
  repaid_loans: repaid_loans,
  repaid_loan_amount: repaid_loan_amount,
  collected_amount: collected_amount,
  aum: aum,
  active_loans: active_loans,
  embifi:embifi_icon,
  nbfc:nbfc_icon
};

export const WhatsappIco = ({ size = 50, className = {}, color = null }) => {
  return color ? (
    <WhatsAppIcon className={className} sx={{ fontSize: `${size-2}px`, color: color }} />
  ) : (
    <img
      className={className}
      src={WhatsAppImg}
      width={`${size}px`}
      height={`${size}px`}
    />
  );
};

export const mapMarkers={
  "customer":CustomerMarker,
  "dealer":DealerMarker,
  "agent":AgentMarker,
  "gps":e_ricksha,
  [undefined]:null
}