import React, { useEffect, useState } from "react";
import ImageCropper from "./Crop";
import CloseIcon from "@mui/icons-material/Close";
import CameraIcon from "@mui/icons-material/Camera";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import CropIcon from "@mui/icons-material/Crop";
import ReplayIcon from "@mui/icons-material/Replay";
import { CircularProgress, DialogActions, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CropScreen = ({
  onClose,
  onCrop,
  onRecapture,
  image,
  open,
  isLoading,
}) => {
  const [cropImgTemp, setTempCrop] = useState("");

  const navigate = useNavigate();

  return (
    // <div className="crop-screen">
    //   <CloseIcon className="crop-close pointer" onClick={onClose} />
    //   <div className="content-center">
    //     <ImageCropper
    //       onImageCropped={(result) => {
    //         setTempCrop(result);
    //       }}
    //       imageToCrop={image}
    //     />
    //   </div>
    //   <div className={`action-buttons-holder`}>
    //     <div className="responsive-div">
    //       <div className="action-buttons w-100">
    //         <button className="capture-btn" onClick={() => onCrop(cropImgTemp)}>
    //           <CameraIcon className="me-2" />
    //           Crop and Save
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          backdropFilter: "blur(10px)",
        },
      }}
      // TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", bgcolor: "transparent" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {/* <Button autoFocus color="inherit" onClick={onRecapture}>
            <ReplayIcon className="me-2" sx={{ fontSize: 16 }} /> Retake
          </Button> */}
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
          ></Typography>

          {/* <Button autoFocus color="inherit" onClick={() => onCrop(cropImgTemp)}>
            <CropIcon className="me-2" sx={{ fontSize: 16 }} /> Crop
          </Button> */}
        </Toolbar>
      </AppBar>
      {/* <List>
      <ListItem button>
        <ListItemText primary="Phone ringtone" secondary="Titania" />
      </ListItem>
      <Divider />
      <ListItem button>
        <ListItemText
          primary="Default notification ringtone"
          secondary="Tethys"
        />
      </ListItem>
    </List> */}
      <div
        className="content-center align-items-center"
        style={{
          background: "transparent",
          backdropFilter: "blur(10px)",
          height: "100%",
        }}
      >
        <ImageCropper
          onImageCropped={(result) => {
            setTempCrop(result);
          }}
          imageToCrop={image}
        />
      </div>
      <DialogActions>
        <div className="w-100 content-center">
          {!isLoading ? (
            <Button
              variant="outlined"
              autoFocus
              color="inherit"
              sx={{ color: "white", padding: "5px 25px", borderRadius: "20px" }}
              onClick={() => onCrop(cropImgTemp)}
            >
              <CropIcon
                className="me-2"
                sx={{ fontSize: 16, color: "white" }}
              />{" "}
              Crop
            </Button>
          ) : (
            <div
              style={{ color: "white", display: "flex", alignItems: "center" }}
            >
              {" "}
              <CircularProgress size={16} sx={{ color: "white", mr: 1 }} />{" "}
              Processing...
            </div>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default CropScreen;
