import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AgentDetails from "./Steps/AgentDetails";
import useAuth from "../../hooks/useAuth";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  CssBaseline,
  Grid,
  ThemeProvider,
  createTheme,
  styled,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { createAgent, updateAgentData, deleteAgent } from "../../api";
import { toast } from "react-toastify";
import { fetchAllDealers } from "../../api";
import DeletePopup from "../../Components/DeletePopup";
import { Check } from "@mui/icons-material";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import useAgent from "../../hooks/useAgent";
import { GlobalContext } from "../../Context/GlobalContext";
import LoadingOver from "../../Components/Loading";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const theme = createTheme();
const steps = ["Agent Details"];

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: "#1976D2",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function getStepContent(step, setActiveStep, isDisabled) {
  switch (step) {
    case 0:
      return <AgentDetails isDisabled={isDisabled} />;
    default:
      throw new Error("Unknown step");
  }
}

function CreateAgent({ isUpdate }) {
  const { agentDetailState } = useContext(GlobalContext);
  const [agentDetails, setAgentDetails] = agentDetailState;
  const { buildPayload, isValid, getAgentDetails } = useAgent();
  const navigate = useNavigate();
  const [confDltPopup, setConfDltPopup] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [overLoading, setOverLoading] = useState({ open: false, text: '' });
  const [isLoading, setLoading] = useState(false);

  const { state } = useLocation();
  const { agentId } = useParams();

  const handleCreateAgent = async () => {
    try {
      setOverLoading({
        open: true,
        text: 'Creating Agent...'
      })
      let error = isValid();
      if (error !== "") {
        toast.error(`enter valid ${error}`);
        return;
      }
      let payload = buildPayload();
      let response = await createAgent(payload);
      if (!response.status) {
        toast.error(response?.data?.message || "Failed to Create Agent !!!");
        return;
      }
      toast.success(response?.data?.message || "Agent Created Successfully");
      navigate(`/partners`);
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || "failed to create agent !!!"
      );
    } finally {
      setOverLoading({
        open: false,
        text: ''
      })

    }
  };

  async function handleAgentUpdate() {
    try {
      setOverLoading({
        open: true,
        text: 'Updating Agent ...'
      })

      let error = isValid();
      if (error !== "") {
        toast.error(`enter valid ${error}`);
        return;
      }

      let payload = {
        agent_code: agentId,
        ...buildPayload(),
      }
      const { data } = await updateAgentData(payload);
      if (data?.status) {
        toast(data?.message || "updated successfully !!!");
      }
      setIsDisabled(true);
    } catch (error) {
      toast?.error(error?.response?.data?.message || 'failed to update agent !!!');
    } finally {
      setOverLoading({
        open: false,
        text: ''
      })
    }
  }

  function handleSubmit() {
    if (isUpdate) {
      handleAgentUpdate();
    } else {
      handleCreateAgent();
    }
  }

  async function handleDelete() {
    try {
      setOverLoading({
        open: true,
        text: 'deleting agent'
      })
      const { data } = await deleteAgent(agentId);
      if (data?.status) {
        toast(data?.message || "deleted agent");
        navigate(`/partners`);
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message || "failed to delete agent!!!")
    } finally {
      setOverLoading({
        open: false,
        text: ''
      })
    }
  }

  useEffect(() => {
    if (isUpdate) {
      getAgentDetails(agentId, setLoading)
    }
    setIsDisabled(isUpdate);
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="update-anchor-wrapper">
          <div className="update-anchor-sidebar">
            <div
              style={{
                cursor: "pointer",
                marginBottom: 30,
              }}
              onClick={() => {
                if (state?.path) {
                  navigate(state?.path);
                } else {
                  navigate("/partners");
                }
              }}
            >
              <ArrowBackIcon /> Go back Home
            </div>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {step}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Button
              className="mb-5 anchor-update-btn"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={(() => {
                if (
                  isDisabled ||
                  overLoading?.open ||
                  [null, undefined, ""].includes(agentDetails?.name) ||
                  [null, undefined, ""].includes(agentDetails?.anchors?.[0]) ||
                  [null, undefined, ""].includes(agentDetails?.mobile_number?.[0])
                ) {
                  return true;
                }
                return false;
              })()}
            >
              {overLoading?.open ? "Loading" : isUpdate ? "Update" : "Submit"}
            </Button>
          </div>
          <div className="section">
            <div className="steps-holder mb-5">
              <div className="">
                <Grid
                  container
                  spacing={2}
                  className="pb-3"
                  style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    zIndex: 5,
                    position: "sticky",
                    top: -25,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h5
                        style={{
                          color: "#2C71C2",
                        }}
                      >
                        {steps[activeStep]}
                      </h5>
                    </div>
                    {isUpdate && (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2,1fr)",
                          width: "400px"
                        }}
                      >
                        <div>
                          <Button
                            style={{
                              textTransform: "capitalize",
                              margin: "10px 10px",
                            }}
                            onClick={() => {
                              // if (!isDisabled) {
                              //   getAnchorDetails(
                              //     anchorId,
                              //     setApiLoading,
                              //     setInitialState
                              //   );
                              // }
                              setIsDisabled((prev) => !prev);
                            }}
                            color={isDisabled ? "primary" : "error"}
                          >
                            {isDisabled ? (
                              <EditSharpIcon
                                style={{
                                  fontSize: 18,
                                  marginRight: 5,
                                }}
                              />
                            ) : (
                              <CloseSharpIcon
                                style={{
                                  fontSize: 18,
                                  marginRight: 5,
                                }}
                              />
                            )}{" "}
                            Edit Agent
                          </Button>
                        </div>
                        <div>
                          {isDisabled && (
                            <Button
                              style={{
                                textTransform: "capitalize",
                                margin: "10px 10px",
                              }}
                              onClick={() => {
                                setConfDltPopup(true);
                              }}
                              color={"error"}
                            >
                              <DeleteIcon
                                style={{
                                  fontSize: 18,
                                  marginRight: 5,
                                }}
                              />
                              Delete Agent
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {getStepContent(activeStep, setActiveStep, isDisabled)}
                  </Grid>
                </Grid>
              </div>
              <div style={{ marginBottom: 50 }}>&nbsp;</div>
            </div>
          </div>
        </div>
      </ThemeProvider>

      {(overLoading?.open || isLoading) && (
        <LoadingOver text={overLoading?.text} />
      )}
      <DeletePopup
        confDltPopup={confDltPopup}
        setConfDltPopup={setConfDltPopup}
        handleDeleteDoc={handleDelete}
        text={"Are you sure you want to delete this Agent ?"}
      />
    </>
  );
}

export default CreateAgent;
