import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAllApplications, fetchDRFApplications } from "../../api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import viewIcon from "../../assets/view-application.jpg";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import moment from "moment";
import LoadingOver from "../../Components/Loading";
import * as XLSX from "xlsx";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DRFSheet = () => {
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGenerating, setGenerating] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const theme = useTheme();
  const [selectedApps, setSelectedApps] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedApps(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const getApplications = async (list = true) => {
    if (list) setLoading(true);
    let { data } = await fetchDRFApplications(
      list ? `?list=true` : null,
      list
        ? null
        : {
            ...(allSelected
              ? { all: true, applicationIds: [] }
              : {
                  applicationIds: selectedApps?.map(
                    (val) => val?.application_id
                  ),
                }),
          }
    );
    if (list) {
      setApplications(data?.applications || []);
      setLoading(false);
    } else {
      return data?.applications || [];
    }
  };

  const handleDownload = async () => {
    try {
      setGenerating(true);
      let applications = await getApplications(false);

      let data = [];
      (applications || []).forEach((row) => {
        let sub_hold_type = (() => {
          if (
            row?.subvention_amount &&
            Number(row?.subvention_amount) > 0 &&
            row?.hold_back_amount &&
            Number(row?.hold_back_amount) > 0
          )
            return "Both";
          else if (
            row?.hold_back_amount &&
            Number(row?.hold_back_amount) > 0
          )
            return "Hold Back";
          else if (
            row?.subvention_amount &&
            Number(row?.subvention_amount) > 0
          )
            return "Subvention";
          else return "N/A";
        })();
        
        let disbursal_amount =
          Number(row?.loan_amount || 0) -
          (row?.processing_fee_mode == "UPFRONT_DEDUCTED"
            ? Number(row?.processing_charge || 0)
            : 0) -
          (row?.gps_charges_mode == "UPFRONT_DEDUCTED"
            ? Number(row?.gps_charges || 2650)
            : 0) -
          (row?.hold_back_mode == "UPFRONT_DEDUCTED"
            ? Number(row?.hold_back_amount || 0)
            : 0) -
          (row?.subvention_mode == "UPFRONT_DEDUCTED"
            ? Number(row?.subvention_amount || 0)
            : 0);

        data.push({
          "EMBIFI Loan ID": row?.application_id,
          "Loan status": "ACTIVE",
          UMRN:
            row?.physical_nach_data?.mandate_id || row?.enach_data?.mandate_id,
          "Product/Service_Name": "E Rickshaw Loan",
          "Borrower Name": row?.customerData?.pan_details?.name,
          "NBFC Name": row?.nbfc_name,
          "Loan Amount": row?.loan_amount,
          "Processing Fee mode": row?.processing_fee_mode,
          "Gps charge mode": row?.gps_charges_mode,
          "Dealer Fee mode":
            Number(row?.subvention_amount) > 0 ? row?.subvention_mode : "N/A",
          "Hold back mode":
            Number(row?.hold_back_amount) > 0 ? row?.hold_back_mode : "N/A",
          "Subvention/Hold Back": sub_hold_type,
          "Processing Fees (Rs.) Excl GST": Math.ceil(
            Number(row?.processing_charge) / 1.18
          ),
          "GST on PF":
            Number(row?.processing_charge) -
            Math.ceil(Number(row?.processing_charge) / 1.18),
          "Total PF (Incl GST)": row?.processing_charge,
          "Anchor ID": row?.anchor_id,
          "Hold Back Amt (If Applicable)": row?.hold_back_amount,
          "Dealer Fees/Subvention (Excl GST)": row?.subvention_amount
            ? Math.ceil(Number(row?.subvention_amount) / 1.18)
            : "",
          "GST on Dealer's Fees/Subvention": row?.subvention_amount
            ? Number(row?.subvention_amount) -
              Math.ceil(Number(row?.subvention_amount) / 1.18)
            : "",
          "Total Dealer's Fees/Subvention (Incl GST)": row?.subvention_amount,
          "GPS Device Cost (Rs.) Excl GST": Math.ceil(
            Number(row?.gps_charges || 2650) / 1.18
          ),
          "GST on GPS device":
            Number(row?.gps_charges || 2650) -
            Math.ceil(Number(row?.gps_charges || 2650) / 1.18),
          "Total GPS Device Cost (Incl GST)": row?.gps_charges || 2650,
          "Fund out Amt (AMT TO BE DISB)": disbursal_amount,
          "No. of EMI to be received (Based on Frequency)": row?.tenure_value,
          "Interest Rate (Flat)": row?.annual_interest_rate
            ? Number(row?.annual_interest_rate).toFixed(2) + "%"
            : "--",
          "Tenure (in months)": row?.tenure_value,
          "EMI AMT":
            row?.nbfc_id === "MF00006" || row?.nbfc_id === "NAM0007"
              ? Math.round(row?.emi_amount)
              : Math.ceil(row?.emi_amount),
          "EMI Frequency": "Monthly",
          "First EMI Date": row?.emi_start_date,
          "Last EMI Date": row?.emi_end_date,
          "Bneficiary (Merchant) Name":
            row?.anchor_bankdetails?.beneficiary_name,
          "Beneficiary Bank A/c": row?.anchor_bankdetails?.account_number,
          "Beneficiary(Merchant) IFSC code": row?.anchor_bankdetails?.ifsc_code,
          "Disbursed Status": row?.is_disbursed ? "Disbursed" : "Pending",
          "UTR No": row?.utr || "--",
          "Disbursement Amount": disbursal_amount,
          "Tentative Disbursement Date": moment(new Date(row?.is_disbursed ? row?.disbursed_date : Date.now())).format(
            "DD/MM/YYYY"
          ),
          "Hypothecation Status": "Done",
        });
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);

      XLSX.utils.book_append_sheet(workbook, worksheet, "Applications");

      // customize header names
      // XLSX.utils.sheet_add_aoa(worksheet, [
      //   ["Product ID", "Product Name", "Product Category"],
      // ]);

      XLSX.writeFile(
        workbook,
        `DRF-sheet-${moment(new Date()).format("DD/MM/YYYY")}.xlsx`,
        { compression: true }
      );
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message || error?.message || "Failed to fetch"
      );
    }
    setGenerating(false);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  useEffect(() => {
    getApplications();
  }, []);

  return (
    <>
      <p className="go-back-btn-fixed" onClick={() => navigate("/")}>
        <ArrowBackIcon /> Go back Home
      </p>
      <div className="wrapper-center">
        <div className="calculate-form bg-white">
          <Grid item xs={12} sm={12}>
            <FormControl sx={{ width: "100%", mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  className="w-100"
                  multiple
                  limitTags={2}
                  disabled={allSelected}
                  id="checkboxes-tags-demo"
                  options={applications}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option?.application_id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.application_id} -{" "}
                      {option.customerData?.pan_details?.name}
                    </li>
                  )}
                  onChange={(e, value) => {
                    setSelectedApps(value);
                  }}
                  value={selectedApps}
                  style={{ width: 500 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Applications"
                      placeholder="Application ID"
                    />
                  )}
                />
              </Grid>
            </FormControl>
          </Grid>

          <div className="mt-4">
            <p className="text-center">
              <img src={viewIcon} alt="upload" width={200} />
            </p>
            <p
              className="text-center mb-2"
              style={{ color: "purple", fontSize: 20 }}
            >
              <FormControlLabel
                className={"mt-3 me-3"}
                onChange={(e) => {
                  setAllSelected(e.target.checked);
                }}
                control={<Checkbox value={allSelected} />}
                label="All"
              />
              <Button
                className={"mt-3 me-3"}
                disabled={selectedApps?.length === 0 || isGenerating || allSelected}
                onClick={() => {
                  setSelectedApps([]);
                }}
              >
                Clear
              </Button>
              <Button
                className={"mt-3 generate-btn"}
                disabled={
                  (!allSelected && selectedApps?.length === 0) || isGenerating
                }
                onClick={handleDownload}
                style={{
                  background: "#800080",
                }}
              >
                {isGenerating ? (
                  <>
                    <CircularProgress
                      size={13}
                      sx={{ color: "white", mr: 1 }}
                    />{" "}
                    Generating sheet...
                  </>
                ) : (
                  "Generate Sheet"
                )}
              </Button>
            </p>
          </div>
        </div>
      </div>
      {loading && <LoadingOver />}
    </>
  );
};

export default DRFSheet;
