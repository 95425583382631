import axios from "axios";
import { URL, environment } from "../config";
import { id } from "date-fns/locale";
import { identifyUser, trackEvent } from "../utility/Mixpanel";
import moment from "moment";

export const baseURL = URL[environment];
const API = axios.create({
  baseURL,
  withCredentials: true,
});
API.interceptors.request.use(
  (config) => {
    config.headers["application"] = "EMBEDDED-TOOLS";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.request.use(
  (config) => {
    config.metadata = { startTime: new Date() };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to log the execution time
// API.interceptors.response.use(
//   (response) => handleResponse(response),
//   (error) => {
//     handleResponse(error)
//     return Promise.reject(error);
//   }
// );

// API.interceptors.response.use(
//   (response) =>response,
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       console.log(error.response.url);
//       window.location.href = '/login';
//     }
//     return Promise.reject(error);
//   }
// );

function handleResponse(response) {
  const endTime = new Date();
  const startTime = response.config.metadata.startTime;
  const duration = endTime - startTime;

  const userJson = localStorage.getItem("userInfo");
  const userData = userJson ? JSON.parse(userJson) : {};

  if (userData?.user_id) {
    identifyUser(userData?.user_id);
  }

  // Log the execution time for both success and error cases
  console.log(`API Request to ${response.config.url} took ${duration}ms`);

  // Define the event data object with common properties
  const eventData = {
    environment,
    req_user: userData?.name,
    req_user_email: userData?.email,
    req_user_id: userData?.user_id,
    url: response.config.url,
    req_start_time: moment(new Date(startTime)).format("DD/MM/YYYY hh:mm A"),
    req_end_time: moment(new Date(endTime)).format("DD/MM/YYYY hh:mm A"),
    req_execution_time: duration + "ms",
  };

  if (response.status === 401) {
    // Handle 401 Unauthorized error case
    console.error(
      `API Request to ${response.config.url} failed with status code 401`
    );
    trackEvent("api_request_error", {
      ...eventData,
      error_message: "Unauthorized (401)",
    });
  } else if (response instanceof Error) {
    // Handle error case
    console.error(
      `API Request to ${response.config.url} failed: ${response.message}`
    );
    trackEvent("api_request_error", {
      ...eventData,
      error_message: response.message,
    });
  } else {
    // Handle success case
    trackEvent("api_request", eventData);
  }

  return response;
}

// export const createUser = (payload: any) => { return postData({ completeUrl: ApiEndpoints.UPDATE_PASSWORD_USER, payload }) }

export const createUser = (payload) => {
  return API.post(`/common/create-user`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const createAnchor = (payload) => {
  return API.post(`/common/create-anchor`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const createAgent = (payload) =>
  API.post(`/common/create-agent`, payload);

export const shareDealersData = (payload) =>
  API.post(`/common/create-dealerShared`, payload);

export const login = (payload) => API.post(`/lms/user/login`, payload);
export const logout = (payload) => API.post(`/lms/user/logout`, payload);
export const verifyAuth = () => API.get(`/lms/user/verify-user?getToken=true`);

export const getCustomers = (nbfc) => API.get(`/common/customers/${nbfc}`);
export const getAnchors = (nbfc) => API.get(`/common/anchors/${nbfc}`);
export const getBanks = (nbfc) => API.get(`/common/banks/${nbfc}`);
export const getFetchPan = (pan_number, nbfc_id) =>
  API.post(`/common/checkPanAndNbfc`, { nbfc_id, pan_number });
export const createApplication = (data) =>
  API.post(`/common/createLoanApplication`, data);
export const submitCreditDate = (payload) =>
  API.post(`/common/update-credit-done-date`, payload);

export const fetchAllApplications = () => API.get(`/common/get-loans`);
export const fetchApplicationDetails = (id) => API.get(`/common/loan/${id}`);
export const updateApplication = (payload, id) =>
  API.put(`/common/loan/${id}`, payload);
export const findCocustomer = (id) =>
  API.get(`/common/co-customer-exist/${id}`);
export const fetchDocuments = (id) => API.get(`/common/getAllDocuments/${id}`);
export const deleteDocument = (payload) =>
  API.delete(`/common/delete-doc`, { data: payload });
export const addOtherDocuments = (id, payload) =>
  API.post(`/common/add-other-docs/${id}`, payload);
export const replaceDocPage = (payload) =>
  API.post(`/erick/v2/single-page-upload`, payload);
export const combineDocs = (payload) =>
  API.put(`/erick/v2/doc-page-completed`, payload);

export const fetchCollections = (limit) =>
  API.get(`/collection/?page=1&limit=${limit}`);
export const fetchCollectionData = (id) =>
  API.get(`/collection/${id}/loan-details`);
export const updateCollection = (payload, id) =>
  API.put(`/collection/${id}/update-schedule`, payload);
export const fetchOverallCollectionStatus = (payload) => API.post(`/common/dynamic-overall-collection-status`, payload);
export const updateBankDetails = (payload) => API.post(`/common/penny-drop-and-update-bank`, payload);
export const getBankNames = () => API.get(`/common/get-nachable-bank-list`);

//other collections
export const fetchOtherCollections = (params) =>
  API.get(`/common/get-other-collections?${params}`);
export const getOtherTransactions = () =>
  API.get(`/other-transactions/export-other-transactions`);
export const updateOtherTransactions = (params, payload) =>
  API.post(`/other-transactions/update-other-transaction?${params}`, payload);
export const getAllOtherTransactions = (params) =>
  API.get(`/other-transactions/get-other-transactions?${params}`);
export const deleteOtherTransaction = (params) =>
  API.delete(`/other-transactions/delete-other-transactions?${params}`);

export const getFile = (key) => API.get(`/common/view?key=${key}`);
export const uploadDocuments = (payload, setProgress) =>
  API.put(`/common/upload-docs`, payload, {
    onUploadProgress: (data) => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    },
  });
export const uploadDocumentV2 = (id, params, payload) =>
  API.post(`/erick/v2/document-upload/${id}?${params}`, payload);

export const updateStage = (payload, id) =>
  API.put(`/common/update-stage/${id}`, payload);
export const changeStageToCoCustomer = (id) =>
  API.post(`/common/delete-co-customer/${id}`);

// export const getAllAnchors = () => API.get(`/common/get-anchors`);
export const getAllAnchors = () => API.get(`/common/get-all-data-anchors`);
export const updateAnchorData = (id, payload) =>
  API.put(`/common/update-anchor/${id}`, payload);
export const updateAnchorStatus = (payload) =>
  API.put(`/lms/update-anchor-status`, payload);
// export const aadhaarOcr = (payload) =>
//   axios.post(
//     "https://api.common-wrapper.embifi.in/v1/kyc/aadhaar-parser",
//     payload
//   );
//pasrtners status
export const updatePartnerStatus = (payload) =>
  API.put(`/common/update-partner-status`, payload);

//Agents API's
export const getAgentData = (id) => API.get(`/agent/v2/get-agent-by-id/${id}`);
export const updateAgentData = (payload) =>
  API.patch(`/agent/v2/update-agent`, payload);
export const deleteAgent = (id) => API.delete(`/agent/v2/delete-agent/${id}`);

//Oem API's
export const createOem = (payload) => {
  return API.post(`/oem/create-oem`, payload)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
export const createOemV2 = (payload) => API.post(`/oem/v2/create-oem`, payload);
export const getOemDetails = (id) => API.get(`/oem/v2/get-oem/${id}`);
export const deleteOem = (id) => API.delete(`/oem/v2/delete-oem/${id}`);
export const updateOemDetails = (payload) =>
  API.patch(`/oem/v2/update-oem`, payload);

//Anchor Delete
export const deleteAnchor = (id) =>
  API.delete(`/anchor/v2/delete-anchor/${id}`);

//Partners Tab
export const fetchAllPartners = (params) =>
  API.get(`/common/get-all-partners?${params}`);
export const exportPartners = (params) =>
  API.get(`/export/partner-data?${params}`);

export const aadhaarOcr = (payload) =>
  axios.post("http://localhost:4005/v1/kyc/aadhaar-parser", payload);
export const validateCrifB2C = (payload) =>
  axios.post(
    "https://api.engine.embifi.in/engine/v1/crif-b2c-validation",
    payload
  );

export const fetchAllOems = () => API.get(`/common/get-all-distinct-oems`);
export const fetchAllNbfc = () => API.get(`/common/get-all-distinct-nbfcs`);
export const fetchAllDealers = () =>
  API.get(`/common/get-all-distinct-anchors-by-type?type=DEALER`);
export const fetchLatLng = (pincode) =>
  API.get(`/common/get-lat-lon-with-ZipAddy?options=${pincode}`);
export const fetchRejectedCoapps = (appId) =>
  API.get(`/lms/rejected-application/${appId}`);
export const fetchSharedDealers = (payload) =>
  API.get(`/common/get-dealerShared-for-oem?oem_id=${payload}`);
export const updateDealerShared = (payload) =>
  API.put(`/common/update-dealerShared`, payload);
//

export const fetchAllApplications2 = (params) =>
  API.get(`/common/get-all-applications-v2?${params}`);
export const fetchAllCollection2 = (params) => API.get(`/collection?${params}`);
export const getOutstandingAmount = (id, params) =>
  API.get(`/collection/v2/get-outstanding-amount/${id}/?end_date=${params}`);
export const fetchRefreshCollectionData = () =>
  API.get(`/collection/v2/calculate-collection-data`);
export const fetchAllTransactions = (params) =>
  API.get(`/collection/all-transactions?${params}`);
export const getDashboardData = () => API.get(`/lms/dashboard-details`);
export const getCollectionHistory = (id) => API.get(`/collection/${id}`);
export const getCollectionHistoryConvert = (id) =>
  API.get(`/common/convert-collection/${id}`);
export const updateCollectionData = (payload, id) =>
  API.put(`/collection/${id}/reconcile-schedule`, payload);
export const editTransaction = (payload, id) =>
  API.put(`/collection/edit-transaction/${id}`, payload);
export const fetchSchedule = (id) => API.get(`/lms/loan/schedule-v2/${id}`);
export const fetchSingleApplication = (id) =>
  API.get(`/lms/all-applications?type=search&param=${id?.trim()}&limit=${1}`);
export const uploadCollectionCsv = (payload, setProgress) =>
  API.post(`/collection/bulk-upload`, payload, {
    onUploadProgress: (data) => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    },
    timeout: 180000,
  });

export const fetchFilterValue = (id) => API.get(`/collection/get-filter-data`);

export const fetchAllAnchors = (id) => API.get(`/common/get-all-data-anchors`);
export const fetchAllAnchorsTable = (params) =>
  API.get(`/lms/anchors?${params}`);
export const fetchUsersTable = (params) => API.get(`/lms/users?${params}`);
export const fetchAllNBFCs = (id) => API.get(`/common/get-all-distinct-nbfcs`);
export const fetchTransactionHistory = (id) =>
  API.get(`/collection/transactions/${id}`);

export const updateDocStatus = (payload) =>
  API.post(`/common/approve-reject-docs`, payload);
export const creditEngine = (payload) =>
  API.post(`/common/credit-engine`, payload);
export const copyToDev = (id) =>
  API.post(`/common/copy-application-to-dev/${id}`);

export const copyToDemo = (id) =>
  API.post(`/common/copy-application-to-demo/${id}`);

export const decryptData = (payload) =>
  API.post(`/common/cryptography/decrypt`, payload);
export const updatePassword = (payload) =>
  API.post(`/lms/user/updatePassword`, payload);
export const sendToNBFC = (id) => API.post(`/common/send-to-nbfc/${id}`);
export const resendToNBFC = (payload) =>
  API.post(`/common/one-fin?method=oneFinUploadDocument`, payload);
export const uploadMandate = (payload) =>
  API.post(`/common/uploadNach`, payload);
export const fetchSingleDocData = (payload) =>
  API.get(`/common/doc/`, { params: payload });
export const viewFile = (key) =>
  API.get(`common/view?key=${key}`, { responseType: "blob" });
export const updateStageV2 = (payload, id) =>
  API.put(`/common/update-stage/v2/${id}`, payload);
export const deleteApplication = (params) =>
  API.delete(`/common/delete-loan-application/${params}`);
export const fetchRepaymentScore = (payload) =>
  API.post(`/common/repayment-score`, payload);
export const monthlyDisbursedData = (loanType, months, sourceBy, isMoney) =>
  API.get(
    `/common/monthwise-disbursed-data?loanType=${loanType}&noOfMonths=${months}${
      sourceBy !== "all" ? `&sourcedBy=${sourceBy}` : ""
    }${isMoney ? "&moneyReceived=true" : ""}`
  );
export const documentStatus = (id) =>
  API.get(`/erick/v2/document-status/${id}`);

export const toogleApprovalStatus = (appId, payload) =>
  API.put(`/collection/transaction-approval/${appId}`, payload);

export const sendOTP = (payload) =>
  API.post(`/common/send-custom-otp`, payload);
export const verifyOTP = (payload) =>
  API.post(`/common/verify-custom-otp`, payload);

// TEMPERORY API
export const assignNBFC = (payload, id) =>
  API.put(`/common/update-stage/v2/${id}`, payload);
export const changeNBFC = (payload, id) =>
  API.put(`/lms/update-application-nbfc/${id}`, payload);

export const checkNachStatus = (payload) =>
  API.post(`/common/update-physical-nach-data`, payload);

export const updateNachId = (payload) =>
  API.post(`/common/update-mandateId-manual`, payload);

export const downloadTxnReciept = (payload) =>
  API.post(`/common/get-receipt`, payload);

// V2 Collections

export const fetchAllTransactionsV2 = (params) =>
  API.get(`/collection/v2/all-transactions?${params}`);
export const fetchAllCollectionV2 = (params) =>
  API.get(`/collection/v2?${params}`);
export const fetchAllAssignee = (params) =>
  API.get(`/common/get-all-embifi-users?departments=${params}`);
export const getCollectionHistoryV2 = (id, type) =>
  API.get(`/common/convert-collection-v2/${id}?conversion_type=${type}`);
export const getCollectionsDPD = (payload) =>
  API.post(`/common/calculate-dpd`, payload);
export const fetchTransactionHistoryV2 = (id) =>
  API.get(`/collection/v2/transactions/${id}`);
export const editTransactionV2 = (payload, id) =>
  API.put(`/collection/v2/edit-transactionv2/${id}`, payload);
export const fetchCollectionDataV2 = (id) =>
  API.get(`/collection/${id}/loan-details`);
export const updateCollectionV2 = (payload, id) =>
  API.put(`/collection/v2/add-transaction/${id}`, payload);
export const assignCollectionExecutive = (payload) =>
  API.post("/common/assign-user-to-collection", payload);
export const fetchCollectionOverview = (id) =>
  API.get(`/collection/v2/collection-data-for-application/${id}`);
export const downloadTxnRecieptV2 = (payload) =>
  API.post(`/common/get-receipt-v2`, payload);
export const updatePaymentBasis = (payload) =>
  API.post(`/collection/v2/change-payment-baisis`, payload);
export const changeApprovalStatusV2 = (payload) =>
  API.post(`/collection/v2/change-transaction-approval`, payload);
export const getDashboardDataV2 = () => API.get(`/lms/dashboard-details-v2`);
export const fetchAllCollectionHistory = (params) =>
  API.get(`/common/collection-data-dealer`);
export const AddCommentCollection = (application_id, payload) =>
  API.post(`/collection/v2/add-comment/${application_id}`, payload);
export const GetAllCommentCollection = (id, tag) =>
  API.get(`/collection/v2/get-all-comments/${id}${tag ? "?tag=" + tag : ""}`);
export const uploadCollectionCsvV2 = (payload, setProgress) =>
  API.post(`/collection/v2/bulk-upload-transactions`, payload, {
    onUploadProgress: (data) => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    },
    timeout: 180000,
  });
export const connectToCustomer = (payload) =>
  API.post(`/lms/connect-agents`, payload);

export const uploadGPSCsv = (payload, setProgress) =>
  API.post(`/lms/generate-gps-invoice`, payload, {
    onUploadProgress: (data) => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    },
    timeout: 180000,
  });

export const uploadAssignCsvV2 = (payload, setProgress) =>
  API.post(`/common/bulk-assign-collections`, payload, {
    onUploadProgress: (data) => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    },
    timeout: 180000,
  });

export const updateMMINumber = (payload) =>
  API.put(`/common/update-mmi-number`, payload);
export const loanClosure = (payload) =>
  API.post(`/common/close-loan-application`, payload);
export const createAnchorV2 = (payload) =>
  API.post(`/anchor/v2/create-anchor`, payload);
export const updateAnchorV2 = (payload) =>
  API.patch(`/anchor/v2/update-anchor`, payload);
export const anchorOCR = (payload) =>
  API.post(`/anchor/v2/anchor-pan-ocr`, payload);
export const anchorPennyDrop = (payload) =>
  API.post(`/anchor/v2/anchor-penny-drop`, payload);
export const fetchAnchorDetails = (id) =>
  API.get(`/anchor/v2/get-anchor-by-id/${id}`);
export const fetchLoanFilterValue = () =>
  API.get(`/common/get-applications-filter-data`);
export const fetchedLoadFilteredValues = (params) =>
  API.get(`/common/get-all-applications-v2?${params}`);
export const detailsUpdateBeforeNBFC = (id, payload) =>
  API.patch(`/common/loan-details-update/${id}`, payload);
export const saveLoanAgreementData = (params, payload) =>
  API.patch(`/common/prefill-loan-agreement-before-nbfc?${params}`, payload);
export const fetchAnchorGPSInventory = (params) =>
  API.get(`/common/fetch-anchors-gps-inventory?${params}`);
export const updateAnchorGPS = (payload) =>
  API.post(`/common/add-gps-details`, payload);
export const gpsBulkUplaod = (payload) =>
  API.post(`/common/bulk-upload-gps`, payload);
export const deleteAnchorGPS = (id) => API.delete(`/common/delete-gps/${id}`);
export const appGPSData = (id) => API.get(`/common/fetch-gps-data/${id}`);

export const fetchKYCDetails = (id) => API.get(`/kyc/video/kyc-data/${id}`);
export const getAllKYCs = () => API.get(`/kyc/video/all-kycs`);
export const editAnswer = (payload) =>
  API.put(`/kyc/video/change-final-answer`, payload);
export const updateStatus = (payload) =>
  API.post(`/kyc/video/approve-reject-kyc-docs`, payload);
export const addComment = (payload) =>
  API.post(`/kyc/video/add-review`, payload);
export const approveAll = (payload) =>
  API.put(`/kyc/video/approve-all-docs`, payload);

export const editComment = (payload) =>
  API.put(`/collection/v2/edit-comment`, payload);
export const deleteComment = (id, commentId) =>
  API.delete(`/collection/v2/delete-comment/${id}/${commentId}`);
export const addVehicleConfiscate = (payload, setProgress) =>
  API.post(`/common/upload-vehicle-confiscated-docs`, payload, {
    onUploadProgress: (data) => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    },
    timeout: 180000,
  });
export const getVehicleConfiscate = (id) =>
  API.get(`/common/vehicle-confiscated-details/${id}`);
export const uploadSingleConfistDoc = (payload, setProgress) =>
  API.post(`common/upload-single-confiscated-doc`, payload, {
    onUploadProgress: (data) => {
      setProgress(Math.round((100 * data.loaded) / data.total));
    },
    timeout: 180000,
  });
export const updateFIDate = (payload) =>
  API.post(`/common/change-fi-date`, payload);
export const updateDPDCollection = (payload) =>
  API.get(`/common/update-dpd-in-collections`, {
    timeout: 6000000,
  });
export const updateCollectionOutstandingAmount = () =>
  API.patch(`/collection/v2/update-outstanding-amount`);
export const updateAnchorPercentage = () =>
  API.get(`/common/update-anchor-percentage`);
export const fetchDRFApplications = (param, payload) =>
  API.post(`/common/get-application-drf${param ? param : ""}`, payload);
export const fetchDPDschedule = (payload) =>
  API.post(`/common/show-calculated-dpd`, payload);
export const updateMoneyReceived = (payload) =>
  API.put(`/common/update-money-received`, payload);
export const fetchFileStatus = () => API.get(`/common/dashboard-file-status`);
export const updateDummyStage = (payload) =>
  API.post(`/lms/update-additional-stages`, payload);
export const addContactInfo = (payload) =>
  API.post(`/common/add-additional-contacts`, payload);
export const updateContactInfo = (payload) =>
  API.put(`/common/update-contact-details`, payload);
export const updateTelepd = (payload) =>
  API.post(`/common/update-telepd-questions`, payload);
export const fetchDealerScore = (payload, params) =>
  API.post(`/anchor/v2/get-dealer-score${params ? params : ""}`, payload);
export const fetchExportData = (query) => API.get(`/export/${query}`);
export const sendPaymentReceipt = (id) =>
  API.get(`/common/send-transaction-receipt/${id}`);
export const sendSactionLetter = (payload) =>
  API.post(`/common/send-sanction-letter`, payload);
export const fetchSanctionLoanCount = (params = "") =>
  API.get(`lms/sanction-agreement-data${params}`);
export const fetchProfileData = (params = "") =>
  API.get(`lms/profile-dashboard${params}`);

export const EsignWebhook = (payload) =>
  API.post(`/common/esign-webhook`, payload);
export const EsignGenerate = (payload) =>
  API.post(`common/esign-generate`, payload);
export const updateEmiReminder = (payload) =>
  API.put(`/common/restrict-emi-reminder`, payload);
export const refreshBeFiSc = (payload) =>
  API.put(`/common/update-aadhaar-pan-linked-details`, payload);
export const generateMufinPDF = (payload) =>
  API.post(`/common/generate-pdf-mufin`, payload);
export const fetchRCdashData = (params) =>
  API.get(`/lms/rc-dashboard${params ? params : ""}`);
export const fetchNachdashData = (params) =>
  API.get(`/lms/nach-dashboard${params ? params : ""}`);
export const fetchNachdashTransData = (params) =>
  API.get(`/lms/nach-dashboard-trans${params ? params : ""}`);
export const fetchOSdashData = (params) =>
  API.get(`/lms/os-dashboard${params ? params : ""}`);
export const updateTelepdCo = (payload) =>
  API.post(`/common/update-telepd-co-customer`, payload);
export const fetchSettledCollectionCsv = () =>
  API.get(`/export/settled-collection-data`);
export const removeMandatoryDoc = (params) =>
  API.get(`/common/remove-mandatory-doc-status/${params}`);
export const updateVehicleNumber = (payload) =>
  API.post(`/lms/update-vehicle-number`, payload);
export const fetchDpdDataMoment = (payload) =>
  API.post(`/common/dpd-dashboard-data`, payload);
export const fetchDpdData = (payload) =>
  API.post(`/lms/get-month-wise-dpd-dashboard`, payload);

export const getDisbursedAppList = (payload) =>
  API.get(`/common/get-disbursed-loans-list`);
export const fetchCashReciepts = (params) =>
  API.get(`/common/fetch-cash-receipts?${params ? params : ""}`);
export const updateCashReciept = (payload) =>
  API.post(`/common/update-cash-receipt`, payload);
export const deleteCashReceipt = (id) =>
  API.delete(`/common/delete-cash-receipt-by-id/${id}`);
export const generateSingleGPSinvoice = (id) =>
  API.get(`/lms/get-generated-gps-invoice/${id}`);
export const getGpsInventory = (params) =>
  API.get(`/common/dealerwise-gps-dashboard?${params}`);
export const getGpsInventoryV2 = (params) =>
  API.get(`/common/dealerwise-gps-dashboard-v2?${params}`);
export const exportGpsInventory = () => API.get(`/export/gps`);
export const getAllCities = (params) => API.get(`/common/get-all-cities-list?${params}`);
export const telepdTrigger = (params) => API.post(`/common/trigger-telepd-msg/${params}`);
export const fetchTelePDdata = (id) => API.get(`/kyc/video/telepd-data/${id}`);
export const fetchGpsTrackingLink = (application_id) =>
  API.get(`/common/get-mmi-gps-link/${application_id}`);

export const exportCashreceipt = (params) =>
  API.get(`/export/cash-receipt?${params}`);

export const getNachPresentData = (params) => API.get(`/lms/get-nach-present/${params}`);
export const addNachPresent = (payload) => API.post(`/lms/create-nach-present`, payload);
export const changeNachPresentStatus = (payload) => API.put(`/lms/change-nach-present-state`, payload);
export const getStaticPoolDashboardData = (params) =>
  API.get(`collection/v2/getDashboardData?${params}`);
export const uploadCheck = (app_id, params, payload) =>
  API.post(
    `/common/upload-bank-cheques-recieved-doc/${app_id}?${params}`,
    payload
  );
export const deleteCheck = (params, query) =>
  API.delete(`/common/delete-bank-cheques-recieved-doc/${params}?${query}`);

export const getHoldBackDashData = (params) =>
  API.get(`/common/hold-back-nbfc-wise-dashboard?${params}`);

export const getAllWhatsappMessages = (mobile_number) =>
  API.get(`common/get-whatsapp-chat/${mobile_number}`);

export const getAllNachPresents = (query) =>
  API.get(`/lms/get-all-nach-presents?${query}`);
export const getNotification = (query) =>
  API.get(`erick/v2/get-all-notifications?${query}`);
export const markReadNotification = (body) =>
  API.put(`erick/v2/mark-read-notification`, body);
export const getUserByDepartment = (department) =>
  API.get(`lms/user/get-specific-users?departments=${department}`);
export const exportNachPresents = (query) =>
  API.get(`/export/nach-presents?${query}`);

export const AutoCallTwilio = (query) => API.post("/common/AutoCaller", query);
export const fetchAllTemplates = () => API.get("/common/template");
export const createTemplate = (payload) =>
  API.post("/common/createTemplate", payload);
export const updateTemplate = (template_id, payload) =>
  API.post(`/common/updateTemplate/${template_id}`, payload);

export const getHoldBackApplicants = (anchor_id, exportData) =>
  API.get(
    `/anchor/v2/get-holdback-applications?anchor_id=${anchor_id}&exportData=${exportData}`
  );

export const bulkCalling = (payload) =>
  API.post("/common/BulkCalling", payload);
export const fetchAgentTrackData = (params) =>
  API.get(`/common/get-agent-tracker-data${params ? params : ""}`);

export const fetchWACountCollection = () =>
  API.get(`/common/get-msg-count-collection`);
export const getAgentLocations = (params) =>
  API.get(`/common/get-agent-locations/${params}`);
export const getTwilioLogsData = (application_id) =>
  API.get(`/common/get-call-logs-application?application_id=${application_id}`);

export const getInstallationPie = () => API.get(`/gps/gps-installation-stats`);
export const getGPSInstallationTableData = (params) =>
  API.get(`/gps/gps-installation-data${params}`);
export const getGpsStatics = (params) =>
  API.get(`/gps/gps-dashboard-data${params}`);
export const unassignCollectionExecutive = () =>
  API.post(`collection/v2/unassign-collection-executive`);
export const getGpsMovTrend = (params) =>
  API.get(`/gps/gps-last-7-days-trend${params ? params : ""}`);
export const getGpsCitywise = (params) =>
  API.get(`/gps/gps-city-wise-distribution${params ? params : ""}`);
export const getGpsOemwise = (params) =>
  API.get(`/gps/gps-oem-wise-distribution${params ? params : ""}`);
export const getGpsDailyTrend = (params) =>
  API.get(`/gps/gps-daily-trend${params ? params : ""}`);
export const getGpsInactiveVehicles = (params) =>
  API.get(`/gps/gps-inactive-vehicles-and-dpd${params ? params : ""}`);
export const getGpsInstallationChase = (params) =>
  API.get(`/gps/gps-installation-chase-with-dpd${params ? params : ""}`);
export const getGpsInstallationDisbursal = () =>
  API.get(`/gps/get-disbursed-installation`);

export const BulkTransationData = () =>
  API.get(`/collection/v2/bulk-transactions-data`);

export const updateRefId = (payload) =>
  API.patch(`/common/update-nbfc-reference-id`, payload);
export const generateDFInvoice = (payload) =>
  API.post(`/common/generate-dealer-fees-invoices`, payload);
export const generateSingleDFinvoice = (id) =>
  API.get(`/common/get-generated-dealer-fees-invoice/${id}`);
export const updateDealerShopImages = (id, payload) =>
  API.patch(`/anchor/v2/update-shop-image/${id}`, payload);
export const anchorCrifReport = (payload) =>
  API.post(`/anchor/v2/anchor-crif`, payload);
export const getCallingDashboardData = (filter) =>
  API.get(`/common/calling-dashboard?filter=${filter}`);
export const exportCallingDashboard = (template_name, type, filter) =>
  API.get(
    `/export/calling-dashboard?template_name=${template_name}&type=${type}&filter=${filter}`
  );
export const getAllLocations = (params) =>
  API.get(`/common/get-all-locations?${params}`);

export const agentTargetAnchors = (code, date) =>
  API.get(
    `/common/get-anchors-assigned-to-agent?agent_code=${code}&month_date=${date}`
  );
export const setAgentTarget = (payload) =>
  API.post(`/common/set-agent-targets`, payload);

export const getAllAgents = () => API.get(`/common/get-all-agents`);

export const sentWhatsappReply = (payload) =>
  API.post(`/common/send-wsap-reply`, payload);
export const refreshAllNachPresents = () =>
  API.get(`/lms/refetch-all-nach-presents`, { timeout: 600000 });
export const gpsCallingDashboard = (params) =>
  API.get(`/common/gps-calling-dashboard?${params}`);
export const getAgentTargetData = (agentCode, date) =>
  API.get(
    `/common/get-agent-target-info?target_date=${date}&agent_code=${agentCode}`
  );
