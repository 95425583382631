import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { fetchApplicationDetails } from "../api";
import { useContext } from "react";
import { useEffect } from "react";
import { GlobalContext } from "../Context/GlobalContext";
import moment from "moment";

export function getLatestNach(physical_nach_data, enach_data) {
  let N_A = {
    latest: {
      bank_details: {
        state: "NA",
      },
    },
    old:{
      bank_details: {
        state:"NA"
      }
    }
  };

  
  const physical_state = physical_nach_data?.bank_details?.state;
  const esign_state = enach_data?.bank_details?.state;

  const latest_is_physical = { latest:physical_nach_data, old:esign_state ? enach_data : N_A.old }
  const latest_is_enach = { latest:enach_data, old:physical_state ? physical_nach_data : N_A.old }
  
  if (!Boolean(physical_state) && !Boolean(esign_state)) {
    return N_A;
  }
  if (!Boolean(physical_state) && Boolean(esign_state)) {
    return latest_is_enach;
  }
  if (!Boolean(esign_state) && Boolean(physical_state)) {
    return latest_is_physical;
  }

  if (
    physical_state === "register_success" &&
    esign_state !== "register_success"
  )
    return latest_is_physical;

  if (
    physical_state !== "register_success" &&
    esign_state === "register_success"
  )
  return latest_is_enach;

  const momentDate1 = moment(
    physical_nach_data?.updated_at,
    "YYYY-MM-DD HH:mm:ss"
  );
  const momentDate2 = moment(enach_data?.updated_at, "YYYY-MM-DD HH:mm:ss");

  if (moment(momentDate1).isAfter(momentDate2)) {
    return latest_is_physical;
  }
  return latest_is_enach;
}

export default function useApplication() {
  const { updateDetailState, customersList, panData } =
    useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;

  const getValue = (field, defaultValue) => {
    if (field) {
      return field;
    } else return defaultValue;
  };

  function isEsignEnabled(loanData) {
    if (loanData?.coCustomerPresent) {
      console.log(
        loanData?.esign_status?.customer?.signed,
        loanData?.esign_status?.co_customer?.signed
      );
      return (
        loanData?.esign_status?.customer?.signed &&
        loanData?.esign_status?.co_customer?.signed
      );
    }
    return loanData?.esign_status?.customer?.signed;
  }

  const getApplicationDetails = async (application_id, setLoading) => {
    if (setLoading) setLoading(true);
    try {
      let { data: response } = await fetchApplicationDetails(application_id);
      const {
        customerData,
        bank_details,
        coCustomerData,
        anchorData,
        nbfcData,
        credit_engine,
        loanAdditionalData,
        collectionData,
        // enach_data = {},
        // physical_nach_data = {}
      } = response?.data;
      const loanData = response?.data;

      let status = await getCurrentStatus(loanData);

      setUpdateDetails((st) => ({
        ...st,
        application_id,
        loan_id: loanData?.loan_id,
        oem_id: loanData?.oem_id,
        current_status: status,
        application_status: status,
        embifi_approval: loanData?.embifi_approval_details?.status,
        rejected_reason: getValue(
          loanData?.embifi_approval_details?.remark,
          null
        ),
        customer_id: customerData?.customer_id,
        nbfc_id: loanData?.nbfc_id,
        nbfc_name: nbfcData?.name,
        pan_number: customerData?.is_corporate
          ? customerData?.corporate_pan_details?.number
          : customerData?.pan_details?.number,
        pan_type: customerData?.is_corporate ? "corporate" : "individual",
        customer_name: customerData?.is_corporate
          ? customerData?.corporate_pan_details?.name
          : customerData?.pan_details?.name,
        is_corporate: customerData?.is_corporate,
        dob: customerData?.dob,
        mobile_number: customerData?.mobile_number,
        email: customerData?.email_id,
        gender: customerData?.gender,
        call_first: loanData?.call_first,
        customer_contact_history:
          customerData?.contact_info?.contact_change_history || [],
        customer_call_picker: customerData?.contact_info?.call_picked_by,

        residential_address: customerData?.residential_address?.address,
        residential_address_pincode: customerData?.residential_address?.pincode,
        residential_address_city: customerData?.residential_address?.city,
        residential_address_district:
          customerData?.residential_address?.district,
        residential_address_state: customerData?.residential_address?.state,
        is_property_owned: customerData?.residential_address?.property_owned,
        owned_by: customerData?.residential_address?.current_resident_owned_by,
        fi_distance: customerData?.residential_address?.fi_distance,

        aadhaar_address: customerData?.aadhaar_details?.address,
        aadhaar_name: customerData?.aadhaar_details?.name,
        aadhaar_number: customerData?.aadhaar_details?.id_number,
        aadhaar_number_unmasked: customerData?.aadhaar_details?.UID,
        aadhaar_gender: customerData?.aadhaar_details?.gender,
        aadhaar_mobile_number_masked:
          customerData?.aadhaar_details?.masked_mobile_number,
        aadhaar_mobile_number_matched:
          customerData?.aadhaar_details?.aadhaar_mobile_matched,

        income: customerData?.other_details?.income,
        education: customerData?.other_details?.education,

        // Credit Data

        credit_engine_run_count: loanData?.credit_engine?.run_count,
        credit_eligible: loanData?.credit_engine?.eligible,
        credit_isCocustomer: loanData?.credit_engine?.isCoAppReq,
        credit_response: loanData?.credit_engine?.message,
        crif_data: loanData?.credit_pull?.crif_data,
        co_crif_data: coCustomerData?.credit_pull?.crif_data,

        // Loan Details

        creation_stage: loanData?.creation_stage,
        app_creation_stage: loanData?.customerData?.creation_stage,
        isCollectionApprove:loanData?.loan_stage_data?.collection?.status === "APPROVED",
        start_date: loanData?.application_start_date ?? null,
        last_updated: loanData?.updatedAt ?? null,
        credit_done_date: loanData?.credit_done_date ?? null,
        anchor_id: loanData?.anchor_id,
        anchor_name: anchorData?.dealership_name,
        oem_details: anchorData?.oem_details || [], // Not application level oem details
        anchor_hold_back_type: anchorData?.hold_back_type || null,
        anchor_hold_back_amount: anchorData?.hold_back_amount || {},
        anchor_hold_back_nbfc_limits: anchorData?.hold_back_nbfc_limits || [],
        origin: loanData?.origin,
        agent_id: loanData?.agent_code,
        disbursal_date_og: loanData?.disbursed_date,
        disbursal_date: loanData?.disbursed_date
          ? moment(loanData?.disbursed_date).format("DD/MM/YYYY")
          : null,
        is_disbursed: loanData?.is_disbursed,
        nbfc_loan_id: loanData?.nbfc_loan_id,
        nbfc_sent_documents: loanData?.other_details?.fintree_details || {},
        nbfcData: nbfcData,
        oem_id: loanData?.oem_id ? loanData?.oem_id : null,
        loan_type: loanData?.loan_type,
        product_type: "",
        loan_amount: loanData?.loan_amount || 0,
        interest: loanData?.interest_amount || 0,
        processing_fee: loanData?.processing_charge,
        processing_rate: loanData?.processing_charge_rate,
        crif_score: getValue(
          loanData?.credit_pull?.credit_data?.crif_score,
          null
        ),
        gst: loanData?.gst_amount,
        installment: loanData?.installment_amount,
        disbursal_amount: loanData?.disbursed_amount,
        repayment_amount: loanData?.total_repayment || null,
        tenure_type: loanData?.tenure_type,
        tenure_value: loanData?.tenure_value,
        payment_basis: loanData?.payment_basis,
        no_of_installments: loanData?.no_installments,
        interest_rate: loanData?.annual_interest_rate,
        interest_collected: loanData?.interest_collection_type,
        pf_collected: loanData?.processing_fee_mode,
        other_charges: loanData?.other_charges,
        subvention_amount: loanData?.subvention_amount || 0,
        subvention_rate:
          loanData?.subvention_amount && loanData?.loan_amount
            ? (
                (loanData?.subvention_amount / loanData?.loan_amount) *
                100
              )?.toFixed(2)
            : 0,
        subvention_mode: loanData?.subvention_mode,
        hold_back_amount: loanData?.hold_back_amount || 0,
        hold_back_mode: loanData?.hold_back_mode,
        utr: loanData?.utr || "",
        principal_amount: loanData?.principal_amount || 0,

        embifi_disbursed_date: loanData?.embifi_disbursed_date
          ? moment(loanData?.embifi_disbursed_date).format("DD/MM/YYYY")
          : null,

        nbfc_reference_id : loanData?.nbfc_reference_id || null, 

        //prefill loan details
        enablePOAEsign: isEsignEnabled(loanData),
        poa_e_signed: loanData?.esign_status?.poa?.signed,
        document_id: loanData?.esign_status?.document_id,
        prefill_agreement_details: loanData?.prefill_agreement_details || {},
        prefilled_after_esign:
          loanData?.documents?.loan_agreement?.[0]?.prefilled_after_esign,
        app_version: loanData?.documents?.loan_agreement?.[0]?.app_version,

        // Bank details
        bank_id: getValue(bank_details?.bank_id, null),
        account_number: getValue(bank_details?.account_number, null),
        ifsc_code: getValue(bank_details?.ifsc_code, null),
        bank_name: getValue(bank_details?.bank_name, null),
        account_type: getValue(bank_details?.account_type, null),
        benificiary_name: getValue(
          bank_details?.penny_drop_data?.beneficiary_name_with_bank,
          null
        ),
        penny_drop_data: bank_details?.penny_drop_data || null,
        nachable_bank_name:
          loanData?.nachable_bank_name === "Others"
            ? "Others (Customer don't have Nachable bank)"
            : loanData?.nachable_bank_name || "",
        bank_verified: getValue(
          bank_details?.penny_drop_data?.verified,
          null
        ),

        // co-applicant details
        coApplicantExist: loanData?.coCustomerPresent,
        co_app_pan_number: coCustomerData?.pan_details?.number,
        co_app_pan_type: coCustomerData?.pan_details?.pan_type,
        co_app_dob: coCustomerData?.dob,
        co_app_type: coCustomerData?.customer_type,
        co_app_relation_borrower: coCustomerData?.relation_with_borrower,
        co_app_customer_name: coCustomerData?.pan_details?.name,
        co_app_mobile_number: coCustomerData?.mobile_number,
        co_app_email: coCustomerData?.email_id,
        co_app_gender: coCustomerData?.gender,
        co_app_contact_history:
          coCustomerData?.contact_info?.contact_change_history || [],
        co_app_call_picker: coCustomerData?.contact_info?.call_picked_by,
        // co_app_address: coCustomerData?.residential_address?.address,
        co_app_city: coCustomerData?.residential_address?.city,
        co_app_district: coCustomerData?.residential_address?.district,
        co_app_state: coCustomerData?.residential_address?.state,
        co_app_pincode: coCustomerData?.residential_address?.pincode,
        co_app_income: coCustomerData?.income,
        co_app_education: coCustomerData?.education,
        co_app_current_residential_owned:
          coCustomerData?.residential_address?.property_owned,
        co_app_owned_by:
          coCustomerData?.residential_address?.current_resident_owned_by,
        co_app_current_residential_address:
          coCustomerData?.residential_address?.address,
        co_app_aadhaar_name: coCustomerData?.aadhaar_details?.name,
        co_app_aadhaar_number: coCustomerData?.aadhaar_details?.id_number,
        co_app_aadhaar_number_unmasked: coCustomerData?.aadhaar_details?.UID,
        co_app_aadhaar_gender: coCustomerData?.aadhaar_details?.gender,
        co_app_aadhaar_address: coCustomerData?.aadhaar_details?.address,
        co_app_creation_stage: coCustomerData?.creation_stage,
        co_app_aadhaar_mobile_number_masked:
          coCustomerData?.aadhaar_details?.masked_mobile_number,
        co_app_aadhaar_mobile_number_matched:
          coCustomerData?.aadhaar_details?.aadhaar_mobile_matched,
        co_app_crif_score: getValue(
          coCustomerData?.credit_pull?.credit_data?.crif_score,
          null
        ),
        co_app_dealer_distance:
          coCustomerData?.credit_engine?.details?.current_distance,

        // Co Credit Data

        co_credit_eligible: coCustomerData?.credit_engine?.eligible,
        co_credit_isCocustomer: coCustomerData?.credit_engine?.isCoAppReq,
        co_credit_response: coCustomerData?.credit_engine?.message,

        // Vehicle Details

        vehicle_model: loanData?.other_details?.vehicle_model,
        vehicle_price_onroad: loanData?.other_details?.vehicle_price_on_road,
        vehicle_price_ex: loanData?.other_details?.vehicle_price_ex_showroom,
        vehicle_number:
          loanAdditionalData?.registration_details?.registration_number,

        current_stage_data: loanData?.current_stage_and_status,
        other_details_customer: customerData?.other_details,
        investigator: loanData?.field_investigation_agent,

        send_to_nbfc: loanData?.enableSendToNbfc,
        is__sent_to_nbfc: loanData?.nbfc_approval_details?.send_to_nbfc,

        // Mandate

        mandate_id: loanData?.physical_nach_data?.mandate_id,
        physical_nach_status: loanData?.physical_nach_status,
        digio_nach_data :getLatestNach(
          loanData?.physical_nach_data,
          loanData?.enach_data
        ),
        physical_nach_data:loanData?.physical_nach_data || {},
        enach_mandate_id: loanData?.enach_data?.mandate_id,

        // Other Details
        closure_details: loanData?.closure_details ?? {},
        other_details: loanData?.other_details,
        is_field_investigation_done:
          Object.keys(loanData?.field_investigation_agent || {})?.length > 0,

        // penny drop logs
        penny_drop_logs: loanData?.bank_details?.penny_drop_logs,

        loanDocs: loanData?.documents,
        coCustomerDoc: coCustomerData?.documents,
        customerDoc: customerData?.documents,

        // credit engine
        credit_engine,
        gps_unique_number: loanData?.gpsData?.gps_unique_number || null,
        gps_procurer: loanData?.gpsData?.gps_procurer || null,
        gps_charges: loanData?.gps_charges,
        gps_charges_mode: loanData?.gps_charges_mode,
        gps_installation_date: loanData?.gpsData?.gps_installation_date,
        gps_activated: loanData?.gpsData?.gps_activated,
        gps_sim_number: loanData?.gpsData?.gps_sim_number,
        money_disbursed: loanData?.money_disbursed || false,
        gps_unique_identifier: loanData?.gpsData?.identifier || "",

        additional_stages: loanData?.additional_stages || {},
        other_contact_numbers: loanData?.other_contact_numbers || [],
        video_kyc_data: loanData?.videokycData || null,
        sanction_letter_sent:
          loanData?.other_details?.sanction_letter_data?.sent || false,
        sanction_letter_data:
          loanData?.other_details?.sanction_letter_data || null,

        customerTelePdDone:
          Object.keys(loanData?.videokycData?.audio_kyc || {}).length > 0 ||
          Object.keys(loanData?.videokycData?.other_details || {}).length > 0,
        coCustomerTelePdDone:
          Object.keys(loanData?.videokycData?.co_customer_data || {}).length >
          0,
        stampData: loanData?.stampData,

        //collection data
        collectionData,
        landmark_data: loanAdditionalData?.customer_nearby_locations || [],
        bank_cheques_received:
          loanData?.loanAdditionalData?.bank_cheques_received || [],

        // Battery warranty

        battery_warranty_info: {
          ...getBatteryWarrantyInfo(
            loanData?.vehicle_details,
            loanData?.documents
          ),
          brand: loanData?.other_details?.battery_brand || null,
          period: loanData?.vehicle_details?.battery_warranty_months || null,
        },

        customer_mobile_upi: customerData?.customer_mobile_upi_id
      }));

      //   getCurrentStatus(loanData)
    } catch (err) {
      console.log(err);
      return false;
    }
    if (setLoading) setLoading(false);
  };

  const getCurrentStatus = async (loanData) => {
    let status;

    if (loanData?.embifi_approval_details?.status) {
      if (loanData?.is_disbursed) {
        if (loanData?.is_closed) {
          status = "closed";
        } else {
          status = "disbursed";
        }
      } else if (loanData?.is_disbursed === null) {
        status = "approved";
      } else {
        status = "Disbursal Rejected";
      }
    } else if (loanData?.embifi_approval_details?.status === null) {
      status = "pending";
    } else {
      status = "rejected";
    }

    return status;
  };

  const getNbfc = (id) => {
    switch (id) {
      case "NY00002":
        return "NY Leasing";
        break;
      case "VA00001":
        return "Vani Commercials";
        break;
      case "PL00003":
        return "Prestloans";
        break;
    }
  };

  const getBatteryWarrantyInfo = (data, docs) => {
    let start_date = data?.battery_warranty_start_date || null;
    let custom_case = false;
    if (start_date === "invoice_date" || start_date === null) {
      let doc_date = docs?.sales_invoice?.[0]?.uploadedDateMS || null;
      if (doc_date) {
        custom_case = start_date === null;
        start_date = moment(new Date(doc_date)).format("DD/MM/YYYY");
      } else {
        start_date = null;
      }
    }

    let final = {
      start_date,
      end_date: null,
      status: null,
      custom_case
    };
    if (start_date) {
      final.end_date = moment(start_date, "DD/MM/YYYY")
        .add(data?.battery_warranty_months ?? 12, "months")
        .format("DD/MM/YYYY");

      const currentDate = moment();
      final.status = currentDate.isBetween(
        moment(start_date, "DD/MM/YYYY"),
        moment(final.end_date, "DD/MM/YYYY"),
        null,
        "[]"
      )
        ? "ACTIVE"
        : "INACTIVE";
    }

    return final;
  };

  return {
    getApplicationDetails,
    getNbfc,
    updateDetails,
  };
}
