import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  deleteApplication,
  detailsUpdateBeforeNBFC,
  resendToNBFC,
  sendToNBFC,
  submitCreditDate,
  updateRefId,
  updateVehicleNumber,
} from "../../../api";
import CreditRiskCard from "../../../Components/CrifCard/CreditCard";
import {
  Exceptional,
  Fair,
  Good,
  NewToCredit,
  Poor,
  VeryGood,
} from "../../../Components/StatusChip";
import { GlobalContext } from "../../../Context/GlobalContext";
import { capitalize, f } from "../../../utility/Formater";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoadingOver from "../../../Components/Loading";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { environment } from "../../../config";
import UpdateDisbursalData from "../../../Components/AssignNBFC/UpdateDisbursal";
import { UserContext } from "../../../Context/UserContext";
import ResendToNBFC from "../../../Components/AssignNBFC/ResendToNBFC";
import useApplication from "../../../hooks/useApplication";
import MufinDailog from "../../../Components/Dialogs/MufinePDF";
import CreditDatePopup from "../../../Components/CreditDatePopup";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import PercentIcon from "@mui/icons-material/Percent";
import LockIcon from "@mui/icons-material/Lock";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

const initialParams = {
  deleteCollection: true,
  deleteCoCustomer: true,
  deleteCollectionSchedule: true,
  deleteCustomer: true,
  deleteBusiness: true,
  deleteBankDetails: true,
};

const topButtonStyle = {
  float: "right",
  fontSize: 10,
  boxShadow: "none",
  borderRadius: 2,
};

const LoanDetails = ({ application_id, getDetails }) => {
  const { updateDetailState } = useContext(GlobalContext);
  const { getApplicationDetails } = useApplication();
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [isLoader, setIsLoader] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDisbursal, setOpenDisbursal] = useState(false);
  const [deleteParams, setDeleteParams] = useState(initialParams);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [installmentAmounts, setInstallmentAmounts] = useState({
    emi: 0,
    edi: 0,
  });
  const [resendNBFC, setResendNBFC] = useState(false);
  const [loadingSendNBFC, setLoadingSendNBFC] = useState(false);
  const [actionLoading, setActionLoading] = useState(null);
  const [openSchedulePopup, setCreditDatePopup] = useState(false);

  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const handleCloseDisbursal = () => {
    setOpenDisbursal(null);
  };

  const handleResendToNBFC = async () => {
    setResendNBFC(true);
  };

  const handleSendToNBFC = async () => {
    setOpenDisbursal(true);
  };

  const handleSubmitEdit = async (updatedData) => {
    // console.log('nach data: ', updateDetails?.nbfc_id, updateDetails?.enach_mandate_id, updateDetails?.mandate_id)

    // if (environment !== "development") {
    //   if (
    //     updatedData?.nbfc_id == "FI00005" &&
    //     (updatedData?.enach_mandate_id == "" ||
    //       !updatedData?.enach_mandate_id) &&
    //     (!updatedData?.mandate_id || updatedData?.mandate_id == "")
    //   ) {
    //     toast.error("Nach-ID is mandatory");
    //     return;
    //   }
    // }

    let {
      loan_amount,
      interest,
      interest_rate,
      interest_collected,
      processing_rate,
      processing_fee,
      pf_collected,
      other_charges,
      // installment,
      principal_amount,
      hold_back_amount,
      hold_back_mode,
      subvention_amount,
      subvention_mode,
      disbursal_amount,
      repayment_amount,
      utr,
      tenure_type,
      gps_charges,
      payment_basis,
      tenure_value,
      disbursal_date,
      mandate_id,
      enach_mandate_id,
      isAgent = true,
      fiDate,
    } = updatedData;

    let dealerAmounts = {
      hold_back_amount: Number(hold_back_amount),
      hold_back_mode: "N/A",
      subvention_amount: Number(subvention_amount),
      subvention_mode: "N/A",
    };

    if (!!hold_back_amount && hold_back_amount != 0) {
      dealerAmounts = {
        ...dealerAmounts,
        hold_back_mode,
      };
    }

    if (!!subvention_amount && subvention_amount != 0) {
      dealerAmounts = {
        ...dealerAmounts,
        subvention_mode,
      };
    }

    let payload = {
      ...dealerAmounts,
      loan_amount: Number(loan_amount),
      down_payment: updateDetails?.vehicle_price_onroad
        ? Number(updateDetails?.vehicle_price_onroad) - Number(loan_amount)
        : 0,
      interest_rate: Number(interest_rate),
      interest_amount: Number(interest),
      interest_collection_type: interest_collected,
      processing_charge_rate: Number(processing_rate),
      processing_charge: Number(processing_fee) || null,
      processing_fee_mode: pf_collected,
      other_charges: Number(other_charges),
      // installment_amount: installment,
      principal_amount: Number(principal_amount),
      repayment_amount: Number(repayment_amount),
      utr,
      tenure_type,
      tenure_value: Number(tenure_value),
      payment_basis,
      gps_charges: Number(gps_charges),
      mandate_id,
      enach_mandate_id,
      disbursed_amount: Number(disbursal_amount),
    };

    let errors = {};
    let valid = true;

    for (let key in payload) {
      if (
        [
          "disbursed_amount",
          // "disbursed_date",
          "loan_amount",
          "interest_rate",
          "tenure_value",
          "tenure_type",
          "annual_interest_rate",
          "payment_basis",
        ].includes(key) &&
        [undefined, null, ""].includes(payload[key])
      ) {
        errors = { ...errors, [key]: "Cannot be blank" };
        valid = false;
      }
    }

    if (!isAgent) {
      payload = {
        ...payload,
        fi_date: Number(fiDate),
      };
    }

    if (valid) {
      try {
        setIsLoader(true);
        let { data } = await detailsUpdateBeforeNBFC(application_id, {
          loan_details: payload,
        });
        if (data?.status) {
          setUpdateDetails({
            ...updateDetails,
            ...updatedData,
          });
          let { data: sentResponse } = await sendToNBFC(application_id);
          toast.success("Sent to NBFC successfully!");
        }
        handleCloseDisbursal();
        getDetails();
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
      setIsLoader(false);
    } else {
      // console.log(errors);
      toast.error("Fill required fields and try again");
    }
  };

  const handleDeleteApplication = async () => {
    try {
      setLoadingDelete(true);
      let params = application_id;
      let first = true;
      Object.keys(deleteParams).forEach((key) => {
        if (deleteParams[key]) {
          params += `${first ? "?" : "&"}${key}=true`;
          first = false;
        }
      });
      setIsLoader(true);
      let { data } = await deleteApplication(params);
      navigate("/loan-applications");
      toast.success(data?.message);
    } catch (error) {
      // enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      toast.error(error?.response?.data?.message);
    }

    setLoadingDelete(false);
  };

  async function handleResendNBFCSubmit(documents) {
    try {
      let promises = [];
      setLoadingSendNBFC(true);
      Object.keys(documents)?.map((item) => {
        if (documents?.[item]) {
          promises.push(
            resendToNBFC({
              application_id: updateDetails?.application_id,
              document_type: item,
            })
          );
        }
      });
      if (promises?.length == 0) {
        const { data } = await resendToNBFC({
          application_id: updateDetails?.application_id,
        });
        toast(data?.message);
        setLoadingSendNBFC(false);
      } else {
        await Promise.allSettled(promises)
          .then((results) => {
            toast("All documents resent to NBFC");
          })
          .catch((error) => {
            toast.error(error?.message);
          });
      }
      await getApplicationDetails(updateDetails?.application_id);
    } catch (error) {
      toast.error(error?.message);
    }
    setLoadingSendNBFC(false);
  }

  const handleDeleteParams = (e, name) => {
    setDeleteParams({
      ...deleteParams,
      [name]: e.target.checked,
    });
  };

  useEffect(() => {
    if (openDelete) {
      setDeleteParams(initialParams);
      setConfirmDelete(false);
      setConfirmText("");
    }
  }, [openDelete]);

  const calculateInstallment = (loan_amount, interest_rate, tenure) => {
    let startDate = moment(new Date());
    let endDate = moment(new Date()).add(Number(tenure), "months");

    let ediDays = moment(endDate).diff(moment(startDate), "days");

    let numerator =
      Number(loan_amount) +
      Number(loan_amount) *
      (Number(interest_rate) / 100) *
      (Number(tenure) / 12);

    // console.log(numerator);

    let emi = (numerator / Number(tenure)).toFixed(2);
    let edi = (numerator / ediDays).toFixed(2);
    return { emi, edi };
  };

  async function handleCreditDateSubmit(payload) {
    try {
      setActionLoading("updating...");
      payload = {
        ...payload,
        application_id: updateDetails?.application_id,
      };
      const { data } = await submitCreditDate(payload);
      toast(data?.message || "updated successfully!!!");
      await getApplicationDetails(updateDetails?.application_id);
    } catch (error) {
      toast?.error(error?.response?.data?.message || "failed to generate");
    } finally {
      setCreditDatePopup(false);
      setActionLoading(null);
    }
  }

  async function handleUpdateVehicleNumber(value, setEdit) {
    try {
      setActionLoading("Updating Vehicle Number");
      const { data } = await updateVehicleNumber({
        application_id: updateDetails?.application_id,
        vehicle_number: value,
      });
      toast(data?.message || "Updated successfully!!!");
      setUpdateDetails({
        ...updateDetails,
        vehicle_number: value,
      });
      setEdit(false);
    } catch (error) {
      toast?.error(error?.response?.data?.message || "Operation failed");
    } finally {
      setActionLoading(null);
    }
  }

  async function handleUpdateNBFCRefNumber(value, setEdit) {
    try {
      setActionLoading("Updating NBFC Ref Number");
      const { data } = await updateRefId({
        application_id: updateDetails?.application_id,
        nbfc_reference_id: value,
      });
      toast(data?.message || "Updated successfully!!!");
      setUpdateDetails({
        ...updateDetails,
        nbfc_reference_id: value,
      });
      setEdit(false);
    } catch (error) {
      toast?.error("Error updating ref ID");
    }
    setActionLoading(null);
  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  useEffect(() => {
    let data = calculateInstallment(
      updateDetails?.loan_amount,
      updateDetails?.interest_rate,
      updateDetails?.tenure_value
    );
    let emi = ["MF00006", "NAM0007"].includes(updateDetails?.nbfc_id)
      ? Math.round(data?.emi)
      : Math.ceil(data?.emi);
    setInstallmentAmounts({
      emi,
      edi: Math.ceil(data?.edi),
    });
  }, [
    updateDetails?.loan_amount,
    updateDetails?.interest_rate,
    updateDetails?.tenure_value,
  ]);

  function getFeeType(type) {
    const oem = updateDetails?.oem_details?.find(
      (obj) => obj?.oem_id == updateDetails?.oem_id
    );
    if (!oem) {
      return <span></span>;
    }
    switch (type) {
      case "pf": {
        if (!updateDetails?.processing_fee) {
          return <span></span>;
        }
        if (Boolean(oem?.processing_fees)) {
          return (
            <span
              style={{
                color: "grey",
                backgroundColor: "#e8e8e8",
                fontSize: "10px",
                padding: "2px 5px",
                borderRadius: "5px",
              }}
            >
              <CurrencyRupeeIcon
                sx={{
                  fontSize: "13px",
                }}
              />
              <LockIcon
                sx={{
                  fontSize: "13px",
                }}
              />
            </span>
          );
        }
        if (Boolean(oem?.processing_fees_percent)) {
          return (
            <span
              style={{
                color: "grey",
                backgroundColor: "#e8e8e8",
                fontSize: "10px",
                padding: "2px 5px",
                borderRadius: "5px",
              }}
            >
              <PercentIcon
                sx={{
                  fontSize: "13px",
                }}
              />
              <LockIcon
                sx={{
                  fontSize: "13px",
                }}
              />
            </span>
          );
        }
        break;
      }
      case "df": {
        if (!updateDetails?.subvention_amount) {
          return <span></span>;
        }
        if (Boolean(oem?.subvention?.amount)) {
          return (
            <span
              style={{
                color: "grey",
                backgroundColor: "#e8e8e8",
                fontSize: "10px",
                padding: "2px 5px",
                borderRadius: "5px",
              }}
            >
              <CurrencyRupeeIcon
                sx={{
                  fontSize: "13px",
                }}
              />
              <LockIcon
                sx={{
                  fontSize: "13px",
                }}
              />
            </span>
          );
        }
        if (Boolean(oem?.subvention?.percent)) {
          return (
            <span
              style={{
                color: "grey",
                backgroundColor: "#e8e8e8",
                fontSize: "10px",
                padding: "2px 5px",
                borderRadius: "5px",
              }}
            >
              <PercentIcon
                sx={{
                  fontSize: "13px",
                }}
              />
              <LockIcon
                sx={{
                  fontSize: "13px",
                }}
              />
            </span>
          );
        }
        break;
      }
    }
  }

  return (
    <>
      <Grid container spacing={2} className="mb-4">
        <Grid item xs={12}>
          <div className="overview-card">
            {/* <p className="card-heading">Loan Details</p> */}
            <div className="card-content">
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ position: "relative" }}>
                  <ListItemComp
                    heading={"Application ID:"}
                    text={f(updateDetails?.application_id)}
                  />
                  <ListItemComp
                    heading={"Loan ID:"}
                    text={f(updateDetails?.loan_id)}
                  />

                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      right: 5,
                      top: 12,
                    }}
                  >
                    {!isLoader &&
                      Number(user?.role) !== 3 &&
                      updateDetails?.nbfc_id === "MF00006" && (
                        <BootstrapTooltip
                          title={
                            updateDetails?.credit_done_date
                              ? moment(updateDetails?.credit_done_date).format(
                                "DD/MM/yyyy"
                              )
                              : ""
                          }
                        >
                          <Button
                            style={{
                              textTransform: "none",
                              alignItems: "center",
                              marginRight: 1,
                              color: updateDetails?.credit_done_date
                                ? "#10B800"
                                : "",
                            }}
                            onClick={() => {
                              if (!!updateDetails?.credit_done_date) return;
                              setCreditDatePopup(true);
                            }}
                            disabled={isLoader}
                          >
                            Credit Check Date{" "}
                            <CalendarMonthOutlinedIcon
                              sx={{ fontSize: 16, ml: 1 }}
                            />
                          </Button>
                        </BootstrapTooltip>
                      )}

                    {updateDetails?.nbfc_id === "MF00006" &&
                      (updateDetails?.send_to_nbfc ||
                        updateDetails?.is__sent_to_nbfc) && <MufinDailog />}
                    {Number(user?.role) !== 3 &&
                      updateDetails?.is__sent_to_nbfc ? (
                      !(updateDetails?.nbfcData?.nbfc_id === "FI00005") ? (
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: 500,
                            color: "gray",
                            marginTop: 5,
                            marginLeft: 5,
                          }}
                        >
                          <CheckCircleIcon
                            sx={{ color: "green", fontSize: 25 }}
                          />{" "}
                          SENT TO NBFC
                        </div>
                      ) : (
                        <Button
                          // style={{ position: "absolute", right: 0, top: 5 }}
                          onClick={() => {
                            handleResendToNBFC();
                          }}
                          disabled={isLoader}
                        >
                          {!isLoader ? "Re-send to NBFC" : "Loading..."}
                        </Button>
                      )
                    ) : (
                      <Button
                        // style={{ position: "absolute", right: 0, top: 5 }}
                        onClick={() => {
                          handleSendToNBFC();
                        }}
                        disabled={isLoader || !updateDetails?.send_to_nbfc}
                      >
                        {!isLoader ? "Send to NBFC" : "Loading..."}
                      </Button>
                    )}
                  </div>

                  <hr />
                </Grid>

                <Grid item xs={6}>
                  <ListItemComp
                    heading={"Loan Amount:"}
                    text={f(updateDetails?.loan_amount, "cur")}
                  />
                  {/* <ListItemComp
                    heading={"Principal Amount:"}
                    text={f(updateDetails?.loan_amount, "cur")}
                  /> */}

                  <ListItemComp
                    heading={"Annual Interest Rate:"}
                    text={f(updateDetails?.interest_rate, "rate")}
                  />
                  <ListItemComp
                    heading={"Interest Amount:"}
                    text={f(updateDetails?.interest, "cur")}
                  />
                  <ListItemComp
                    heading={"Interest Collection Type:"}
                    text={f(updateDetails?.interest_collected)}
                  />

                  <ListItemComp
                    heading={"Processing Fee Rate"}
                    text={f(updateDetails?.processing_rate, "rate")}
                  />
                  <ListItemComp
                    heading={"Processing Fee "}
                    text={f(updateDetails?.processing_fee, "cur")}
                    extraData={getFeeType("pf")}
                  />
                  <ListItemComp
                    heading={"PF Collection Type"}
                    text={f(updateDetails?.pf_collected)}
                  />
                  <ListItemComp
                    heading={"Dealer Fees %"}
                    text={f(updateDetails?.subvention_rate, "rate")}
                  />
                  <ListItemComp
                    heading={"Dealer Fees "}
                    text={f(updateDetails?.subvention_amount, "cur")}
                    extraData={getFeeType("df")}
                  />
                  <ListItemComp
                    heading={"Dealer Fees Mode"}
                    text={f(
                      capitalize(
                        updateDetails?.subvention_mode !== "N/A"
                          ? updateDetails?.subvention_mode
                          : null,
                        "_"
                      )
                    )}
                  />
                  <ListItemComp
                    heading={"Hold Back Amount"}
                    text={f(updateDetails?.hold_back_amount, "cur")}
                  />
                  <ListItemComp
                    heading={"Hold Back Mode"}
                    text={f(
                      capitalize(
                        updateDetails?.hold_back_mode !== "N/A"
                          ? updateDetails?.hold_back_mode
                          : null,
                        "_"
                      )
                    )}
                  />
                  <ListItemComp
                    heading={"GPS charges"}
                    text={f(updateDetails?.gps_charges, "cur")}
                  />
                  <ListItemComp
                    heading={"GPS Type"}
                    text={f(
                      capitalize(updateDetails?.gps_charges_mode || "", "_")
                    )}
                  />
                  <ListItemComp
                    heading={"Other Charges"}
                    text={f(updateDetails?.other_charges, "cur")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ListItemComp
                    heading={"Disbursal Amount:"}
                    text={f(updateDetails?.disbursal_amount, "cur")}
                  />
                  {Number(user?.role) !== 2 && (
                    <ListItemComp
                      heading={"Disbursal Utr:"}
                      text={f(updateDetails?.utr)}
                    />
                  )}
                  <ListItemComp
                    heading={"Disbursal Date:"}
                    text={f(updateDetails?.disbursal_date)}
                  />
                  {updateDetails?.nbfc_id === "NAM0007" && (
                    <ListItemComp
                      heading={"Embifi Disbursal Date:"}
                      text={f(updateDetails?.embifi_disbursed_date)}
                    />
                  )}
                  <ListItemComp
                    heading={"Tenure:"}
                    text={f(
                      updateDetails?.tenure_value &&
                      updateDetails?.tenure_type &&
                      `${updateDetails?.tenure_value} ${updateDetails?.tenure_type}`
                    )}
                  />
                  <ListItemComp
                    heading={"Installment Amount:"}
                    text={f(updateDetails?.installment, "cur")}
                  />
                  <ListItemComp
                    heading={"Payment Basis"}
                    text={f(updateDetails?.payment_basis)}
                  />
                  <ListItemComp
                    heading={"EMI Amount:"}
                    text={f(installmentAmounts?.emi)}
                  />
                  <ListItemComp
                    heading={"EDI Amount:"}
                    text={f(installmentAmounts?.edi)}
                  />
                  <ListItemComp
                    heading={"Repayment Amount:"}
                    text={f(
                      updateDetails?.loan_amount + updateDetails?.interest,
                      "cur"
                    )}
                  />
                  {updateDetails?.nbfc_reference_id ||
                  updateDetails?.loanDocs?.delivery_order?.length > 0 ? (
                    <EditableListItem
                      heading={"NBFC Reference ID:"}
                      text={updateDetails?.nbfc_reference_id}
                      onChange={handleUpdateNBFCRefNumber}
                    />
                  ) : (
                    <ListItemComp
                      heading={"NBFC Reference ID:"}
                      text={f(updateDetails?.nbfc_reference_id)}
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={6}>
                  <ListItemComp
                    heading={"Loan Type:"}
                    text={f(updateDetails?.loan_type)}
                  />
                  {updateDetails?.loan_type === "ERICKLN" && (
                    <>
                      <ListItemComp
                        heading={"Vehicle Model:"}
                        text={f(updateDetails?.vehicle_model)}
                      />
                      <EditableListItem
                        heading={"Vehicle Number:"}
                        text={updateDetails?.vehicle_number}
                        onChange={handleUpdateVehicleNumber}
                      />
                      <ListItemComp
                        heading={"OEM:"}
                        text={f(updateDetails?.oem_id)}
                      />
                      {Number(user?.role) !== 3 && (
                        <ListItemComp
                          heading={"Onroad Price:"}
                          text={f(updateDetails?.vehicle_price_onroad, "cur")}
                        />
                      )}
                      {Number(user?.role) !== 3 && (
                        <ListItemComp
                          heading={"Ex Showroom Price:"}
                          text={f(updateDetails?.vehicle_price_ex, "cur")}
                        />
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <ListItemComp
                    heading={"Battery Brand:"}
                    text={f(updateDetails?.battery_warranty_info?.brand)}
                  />
                  <ListItemComp
                    heading={"Battery WTY Period:"}
                    text={
                      <span>
                        {updateDetails?.battery_warranty_info?.custom_case
                          ? 12
                          : f(
                              updateDetails?.battery_warranty_info?.period
                            )}{" "}
                        {updateDetails?.battery_warranty_info?.custom_case
                          ? "*"
                          : ""}
                      </span>
                    }
                  />
                  <ListItemComp
                    heading={"Battery WTY start date:"}
                    text={f(updateDetails?.battery_warranty_info?.start_date)}
                  />
                  <ListItemComp
                    heading={"Battery WTY end date:"}
                    text={f(updateDetails?.battery_warranty_info?.end_date)}
                  />
                  <ListItemComp
                    heading={"Battery WTY Status:"}
                    text={
                      f(updateDetails?.battery_warranty_info?.status) ===
                      "ACTIVE" ? (
                        <span style={{ color: "green", fontWeight: 600 }}>
                          ACTIVE
                        </span>
                      ) : f(updateDetails?.battery_warranty_info?.status) ===
                        "INACTIVE" ? (
                        <span style={{ color: "red", fontWeight: 600 }}>
                          EXPIRED
                        </span>
                      ) : (
                        f(null)
                      )
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        {(environment === "development" || updateDetails?.current_status === "rejected") && Number(user?.role) !== 3 && (
          <Grid item xs={12}>
            <Button
              sx={{ float: "right", color: "red" }}
              onClick={() => {
                setOpenDelete(true);
              }}
            >
              <DeleteIcon sx={{ fontSize: 20, mr: 1 }} /> Delete Application
            </Button>
          </Grid>
        )}
      </Grid>

      <Dialog
        onClose={() => {
          setOpenDelete(false);
        }}
        open={openDelete}
      >
        <DialogContent>
          <div className="p-3">
            <p
              style={{
                marginBottom: 0,
                fontWeight: 500,
                color: "red",
                fontSize: 20,
              }}
            >
              Are you sure to DELETE this application ?
            </p>
            {!confirmDelete ? (
              <>
                <p style={{ color: "gray" }}>
                  This will also delete the following associated data :
                </p>
                <FormGroup>
                  <FormControlLabel
                    onChange={(e) => handleDeleteParams(e, "deleteCustomer")}
                    control={
                      <Checkbox defaultChecked={deleteParams?.deleteCustomer} />
                    }
                    label="Customer"
                  />
                  <FormControlLabel
                    onChange={(e) => handleDeleteParams(e, "deleteCoCustomer")}
                    control={
                      <Checkbox
                        defaultChecked={deleteParams?.deleteCoCustomer}
                      />
                    }
                    label="Co-customer"
                  />
                  <FormControlLabel
                    onChange={(e) => handleDeleteParams(e, "deleteCollection")}
                    control={
                      <Checkbox
                        defaultChecked={deleteParams?.deleteCollection}
                      />
                    }
                    label="Collection"
                  />
                  <FormControlLabel
                    onChange={(e) =>
                      handleDeleteParams(e, "deleteCollectionSchedule")
                    }
                    control={
                      <Checkbox
                        defaultChecked={deleteParams?.deleteCollectionSchedule}
                      />
                    }
                    label="Collection Schedule"
                  />
                  <FormControlLabel
                    onChange={(e) => handleDeleteParams(e, "deleteBusiness")}
                    control={
                      <Checkbox defaultChecked={deleteParams?.deleteBusiness} />
                    }
                    label="Business Details"
                  />
                  <FormControlLabel
                    onChange={(e) => handleDeleteParams(e, "deleteBankDetails")}
                    control={
                      <Checkbox
                        defaultChecked={deleteParams?.deleteBankDetails}
                      />
                    }
                    label="Bank Details"
                  />
                </FormGroup>
              </>
            ) : (
              <div className="mt-3">
                Confirm by typing "DELETE" in the box below
                <TextField
                  fullWidth
                  value={confirmText}
                  placeholder="DELETE"
                  onChange={(e) => {
                    setConfirmText(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDelete(false);
            }}
          >
            Cancel
          </Button>
          {!confirmDelete ? (
            <Button
              onClick={() => {
                // handleDeleteApplication();
                setConfirmDelete(true);
              }}
            >
              Confirm
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleDeleteApplication();
                // setConfirmDelete(true);
              }}
              disabled={confirmText !== "DELETE"}
            >
              DELETE
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <ResendToNBFC
        open={resendNBFC}
        handleClose={setResendNBFC}
        options={[
          "aadhar_back",
          "aadhar_front",
          "customer_pan",
          "customer_adhar",
        ]}
        handleResendNBFCSubmit={handleResendNBFCSubmit}
      />

      {loadingDelete && <LoadingOver text={"Deleting"} />}
      {loadingSendNBFC && <LoadingOver text={"Sending Documents"} />}
      <UpdateDisbursalData
        open={openDisbursal}
        isDisbursal={false}
        handleClose={handleCloseDisbursal}
        onSubmit={handleSubmitEdit}
        isLoading={isLoader}
      />

      {openSchedulePopup && (
        <CreditDatePopup
          open={openSchedulePopup}
          handleClose={() => {
            setCreditDatePopup(false);
          }}
          handleSubmit={handleCreditDateSubmit}
        />
      )}

      {actionLoading && <LoadingOver text={actionLoading} />}
    </>
  );
};

export default LoanDetails;

const ListItemComp = ({ icon, heading, text, extraData }) => {
  return (
    <ListItem disablePadding>
      <ListItemIcon className="list-key loan">
        {
          <span>
            {icon && icon}
            {heading}
            {extraData && extraData}
          </span>
        }
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "13px" }}
        className="list-value"
        primary={text}
      />
    </ListItem>
  );
};

const EditableListItem = ({ icon, heading, text, onChange }) => {
  const [value, setValue] = useState("");
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (edit) setValue(text || "");
    else setValue("");
  }, [edit]);
  return (
    <ListItem disablePadding>
      <ListItemIcon className="list-key loan">
        {
          <span>
            {icon && icon}
            {heading}
          </span>
        }
      </ListItemIcon>
      <div className="d-flex">
        {!edit ? (
          <ListItemText
            primaryTypographyProps={{ fontSize: "13px" }}
            className="list-value"
            primary={f(text)}
          />
        ) : (
          <FormControl variant="outlined">
            <OutlinedInput
              sx={{ height: 30, fontSize: 13 }}
              id="outlined-adornment-weight"
              // endAdornment={<InputAdornment position="end">kg</InputAdornment>}
              value={value}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </FormControl>
        )}

        <Button
          sx={{ height: 30, minWidth: 10, marginLeft: 0.5 }}
          onClick={() => setEdit(!edit)}
        >
          {edit ? (
            <CloseIcon sx={{ fontSize: "16px", color: "red" }} />
          ) : (
            <EditIcon sx={{ fontSize: "16px" }} />
          )}
        </Button>
        {edit && (
          <Button
            sx={{
              height: 30,
              minWidth: 10,
              fontSize: 12,
              boxShadow: "none",
              marginLeft: 1,
            }}
            variant="contained"
            disabled={["", null, undefined]?.includes(value)}
            onClick={() => {
              onChange(value, setEdit);
            }}
          >
            <SaveOutlinedIcon sx={{ fontSize: "14px", marginRight: 1 }} /> Save
          </Button>
        )}
      </div>
    </ListItem>
  );
};
