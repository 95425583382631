import {
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  MenuList,
  TextField,
} from "@mui/material";
import moment from "moment/moment";
import { useEffect, useState, useContext } from "react";
import {
  fetchAllAnchorsTable,
  fetchAllPartners,
  fetchAllApplications2,
  fetchDealerScore,
  updateAnchorStatus,
  updatePartnerStatus,
  exportPartners,
} from "../../api";
import CustomTable from "../../Components/CustomTable/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import {
  Approved,
  Closed,
  Disbursed,
  NotAvailable,
  Pending,
  Rejected,
} from "../../Components/StatusChip";
import SideNavBar from "../../Components/SideNavBar/SideNavBar";
import { useNavigate } from "react-router-dom";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ShareLocationOutlinedIcon from "@mui/icons-material/ShareLocationOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PreviewIcon from "@mui/icons-material/Preview";
import { environment } from "../../config";
import AnchorStatusDropdown from "../../Components/AnchorStatusDropdown";
import { toast } from "react-toastify";
import DealerScore from "../Features/DealerScore";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import GetAppIcon from "@mui/icons-material/GetApp";
import AnchorSort from "../../Components/Anchor/TableSort";
import GPStable from "../../Components/PartnersTab/GPStable";
import { UserContext } from "../../Context/UserContext";
import GPSOverviewTable from "../../Components/PartnersTab/GPSOverviewTable";
import { getAnchorCrifColor } from "../../utility/anchorUtils";

const colors = {
  DEALER: {
    background: "#369FFF !important",
    layers: "#006ED3",
    opacity: "20%",
  },
  AGENT: {
    background: "#FF6B00 !important",
    layers: "#006ED3",
    opacity: "20%",
  },
  OEM: {
    background: "#B743FF !important",
    layers: "#006ED3",
    opacity: "20%",
  },
  [undefined]: {
    background: "#929292 !important",
    layers: "#006ED3",
    opacity: "20%",
  },
};

function daysSinceDate(dateString) {
  const givenDate = new Date(dateString);
    const now = new Date();
  
  const timeDifference = now - givenDate;
  
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
  return daysDifference;
}

export const PartnersTable = () => {
  const [tableData, setTableData] = useState([]);
  const [anchorData, setAnchorData] = useState({});
  const [totalCount, setCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const navigate = useNavigate();
  const [filter, setFilter] = useState("all");
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [anchorStatus, setAnchorStatus] = useState(null);
  const [openDealerScore, setOpenDealerScore] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorExportEl, setAnchorExportEl] = useState(null);
  const [disableExport, setDisableExport] = useState(false);
  const [openGPS, setOpenGPS] = useState({
    open: false,
    data: {},
  });
  const [gpsOverviewTable, setGpsOverviewTable] = useState(false);

  const { user } = useContext(UserContext);
  const open = Boolean(anchorEl);
  const openExport = Boolean(anchorExportEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportClick = (event) => {
    setAnchorExportEl(event.currentTarget);
  };

  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportClose = () => {
    setAnchorExportEl(null);
  };

  const handleFilter = (event, value) => {
    setFilter(value);
    setPage(1);
    setLimit(100);
    if (value === "all") {
      fetchTableData(1, 100);
    }
    fetchTableData(1, 100, "filter", value);
  };

  // const changeAnchorStatus = async (
  //   anchorId,
  //   dealership_name,
  //   value,
  //   otherreason,
  //   index
  // ) => {
  //   try {
  //     setAnchorStatus(index);
  //     let { data } = await updateAnchorStatus({
  //       anchorId,
  //       status: value,
  //       otherreason,
  //     });
  //     let allData = [...tableData];
  //     allData[index] = data?.responseData || [];
  //     setTableData(allData);
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   }
  //   setAnchorStatus(null);
  // };

  async function changePartnerStatus(
    partner_id,
    partnership_name,
    value,
    other_reason,
    index
  ) {
    try {
      setAnchorStatus(index);
      let { data } = await updatePartnerStatus({
        partner_id,
        status: value,
        other_reason,
        partner_type: partnership_name,
      });
      let allData = [...tableData];
      allData[index][`status`] =
        data?.updatedPartner?.status || allData[index]?.[`status`];
      setTableData(allData);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setAnchorStatus(null);
    }
  }

  const handleRefreshScore = async () => {
    setUpdating(true);
    try {
      let { data } = await fetchDealerScore(null, "?update_db=true");
      toast.success("Dealers score data updated successfully!");
      fetchTableData(page, limit);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"
      );
    }
    setUpdating(false);
  };

  function handleNavigate(partnership_name, rowData) {
    switch (partnership_name) {
      case "DEALER":
        navigate(`/update-anchor-v2/${rowData?.partner_id}`, {
          state: {
            path: `/partners`,
          },
        });
        return;
      case "OEM":
        navigate(`/update-oem/${rowData?.partner_id}`, {
          state: {
            path: `/partners`,
          },
        });
        return;
      case "AGENT":
        navigate(`/update-agent/${rowData?.partner_id}`, {
          state: {
            path: `/partners`,
          },
        });
        return;
      default:
        toast?.error("invalid route !!!!");
    }
  }

  const ToggleButtons = () => {
    return (
      <div>
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilter}
          aria-label="text alignment"
          size="small"
          className="toggle-btn-holder"
        >
          <ToggleButton
            value="all"
            aria-label="left aligned"
            className="toggle-btn"
          >
            All
          </ToggleButton>
          <ToggleButton
            value="oems"
            aria-label="left aligned"
            className="toggle-btn"
            sx={{
              color: colors["OEM"].background,
            }}
          >
            OEM
          </ToggleButton>
          <ToggleButton
            value="anchors"
            aria-label="centered"
            className="toggle-btn"
            sx={{
              color: colors?.["DEALER"]?.background,
            }}
          >
            Dealer
          </ToggleButton>
          <ToggleButton
            value="agent"
            aria-label="centered"
            className="toggle-btn"
            sx={{
              color: colors?.["AGENT"]?.background,
            }}
          >
            Agent
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  const statusChipFunc = (edx) => {
    if (edx?.embifi_approval_details?.status) {
      if (edx?.is_disbursed) {
        if (edx?.is_closed) {
          return <Closed />;
        } else {
          return <Disbursed />;
        }
      } else {
        return <Approved />;
      }
    } else if (edx?.embifi_approval_details?.status === null) {
      return <Pending />;
    } else {
      return <Rejected />;
    }
  };

  let columns = [
    { label: "Partner Id", value: "partner_id", copy: true, width: 220 },
    {
      label: "Firm Name",
      value: "firm_name",
      width: 220,
    },

    {
      label: "Partnership Type",
      value: "partnership_name",
      width: 220,
    },

    {
      label: "Status",
      render: (i) => {
        return anchorStatus === i ? (
          <CircularProgress size={20} />
        ) : (
          <AnchorStatusDropdown
            value={tableData[i]?.status || 1}
            ddList={[1, 2]}
            isNormal={true}
            onChange={(val, reason) => {
              changePartnerStatus(
                tableData[i]?.partner_id,
                tableData?.[i]?.partnership_name,
                val,
                reason,
                i
              );
            }}
          />
        );
      },
      value: 1,
      width: 150,
    },

    {
      // label: "RM Number (1st)",
      label: "Number",
      // render: (i) => {
      //   return tableData[i]?.mobile_number?.[0] || "--";
      // },
      value: "mobile_number",
      width: 150,
    },
    {
      label: "Onboarding Date",
      render: (i)=>{
        return (
          <span>{moment(tableData[i]?.createdAt).format("DD-MM-YYYY")}</span>
        )
      },
      width: 150,
    },
    {
      label: "Days since onboarded",
      render: (i)=>{
        return (
          <span>{daysSinceDate(tableData[i]?.createdAt)}</span>
        )
      },
      width: 150,
    },
    {
      label: "Disbursed Count",
      render: (i)=>{
        if(tableData[i]?.partnership_name != "DEALER"){
          return <NotAvailable/>
        }
        else{
          return <span>{tableData[i]?.disbursed_count}</span>
        }
      },
      width: 150,
    },
    {
      label: "Name",
      value: "name",
      width: 150,
    },
  ];
  if (filter !== "agent") {
    columns = columns.concat([
      {
        label: "GST Status",
        render: (i) => {
          return tableData[i]?.gst_status || "NA";
        },
        width: 150,
      },
    ]);
  }
  if (!["oems", "agent"]?.includes(filter)) {
    columns = columns.concat([
      {
        label: "CRIF Score",
        render: (i) => {
          return (
            <div
              style={{
                color: getAnchorCrifColor(Number(tableData[i]?.crif_score))
                  ?.text,
                backgroundColor: getAnchorCrifColor(
                  Number(tableData[i]?.crif_score)
                )?.background,
                display: "flex",
                borderRadius: "5px",
                paddingLeft: "3px",
                paddingRight: "3px",
                width: 100,
                height: "40px",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <b>{tableData[i]?.crif_score ?? "N/A"}</b>
            </div>
          );
        },
        width: 150,
      },
    ]);
  }

  columns = columns.concat([
    {
      label: "Score",
      render: (index) => {
        return (
          <div className="d-flex">
            <Button
              disabled={["OEM", "AGENT"].includes(
                tableData[index]?.partnership_name
              )}
              onClick={() => {
                navigate(`/dealer-profile/${tableData[index]?.partner_id}`, {
                  state: {
                    path: `/partners`,
                    anchorData: tableData[index],
                    colors: {
                      background:
                        colors?.[tableData[index]?.partnership_name]
                          ?.background,
                      layers:
                        colors?.[tableData[index]?.partnership_name]?.layers,
                      opacity:
                        colors?.[tableData[index]?.partnership_name]?.opacity,
                    },
                  },
                });
              }}
              sx={{
                fontSize: "12px",
                width: 100,
                padding: 0,
                overflow: "hidden",
                backgroundColor:
                  colors?.[tableData[index]?.partnership_name]?.background,
                "&:hover": {
                  backgroundColor:
                    colors?.[tableData[index]?.partnership_name]?.background,
                },
              }}
              variant="contained"
            >
              <div
                style={{
                  width: 100,
                  height: "100%",
                  position: "relative",
                }}
              >
                {tableData[index]?.partnership_name === "AGENT" ? (
                  <div
                    style={{
                      clipPath: "ellipse(100% 40% at 4% 0%)",
                      background:
                        colors?.[tableData[index]?.partnership_name]?.layers,
                      opacity:
                        colors?.[tableData[index]?.partnership_name]?.opacity,
                      height: "45px",
                      width: "100%",
                      position: "absolute",
                    }}
                  ></div>
                ) : (
                  <>
                    <div
                      style={{
                        clipPath:
                          "polygon( 90.483% 0%,90.483% 0%,92.135% 0.446%,93.643% 1.713%,94.975% 3.694%,96.097% 6.28%,96.975% 9.364%,97.576% 12.838%,97.866% 16.595%,97.812% 20.528%,97.379% 24.528%,96.534% 28.488%,96.534% 28.488%,94.74% 34.59%,92.603% 40.837%,90.097% 47.144%,87.195% 53.427%,83.87% 59.6%,80.095% 65.58%,75.843% 71.282%,71.088% 76.621%,65.802% 81.512%,59.958% 85.872%,59.958% 85.872%,52.678% 90.32%,45.809% 93.797%,39.359% 96.388%,33.332% 98.177%,27.738% 99.25%,22.582% 99.692%,17.871% 99.587%,13.612% 99.02%,9.813% 98.076%,6.478% 96.841%,6.478% 96.841%,5.35% 96.085%,4.309% 94.864%,3.361% 93.223%,2.515% 91.203%,1.778% 88.847%,1.159% 86.199%,0.663% 83.3%,0.3% 80.195%,0.076% 76.925%,0% 73.534%,0% 64.87%,0% 64.87%,0.292% 54.348%,1.137% 44.366%,2.489% 35.058%,4.302% 26.559%,6.531% 19%,9.129% 12.516%,12.051% 7.241%,15.251% 3.307%,18.682% 0.849%,22.299% 0%,90.483% 0% )",
                        background:
                          colors?.[tableData[index]?.partnership_name]?.layers,
                        opacity:
                          colors?.[tableData[index]?.partnership_name]?.opacity,
                        height: "10px",
                        width: "30%",
                        position: "absolute",
                      }}
                    ></div>
                    <div
                      style={{
                        clipPath:
                          " polygon( 99.997% 3.834%,99.997% 65.957%,99.997% 65.957%,99.821% 70.989%,99.311% 75.763%,98.494% 80.214%,97.399% 84.28%,96.054% 87.895%,94.485% 90.995%,92.721% 93.518%,90.789% 95.4%,88.717% 96.575%,86.533% 96.981%,5.776% 96.981%,5.776% 96.981%,4.612% 96.706%,3.579% 95.932%,2.698% 94.736%,1.989% 93.196%,1.473% 91.39%,1.17% 89.395%,1.1% 87.289%,1.285% 85.149%,1.744% 83.053%,2.498% 81.079%,2.498% 81.079%,5.219% 75.879%,8.436% 70.671%,12.162% 65.587%,16.41% 60.756%,21.195% 56.31%,26.53% 52.379%,32.429% 49.094%,38.906% 46.586%,45.975% 44.985%,53.648% 44.423%,53.648% 44.423%,63.535% 43.657%,72.003% 41.519%,79.146% 38.25%,85.059% 34.089%,89.837% 29.276%,93.573% 24.051%,96.363% 18.654%,98.301% 13.326%,99.481% 8.306%,99.997% 3.834% )",
                        background:
                          colors?.[tableData[index]?.partnership_name]?.layers,
                        opacity:
                          colors?.[tableData[index]?.partnership_name]?.opacity,
                        height: "20px",
                        width: "50%",
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                      }}
                    ></div>
                  </>
                )}
                <p
                  style={{
                    zIndex: 1,
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    color: "#fff",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  {tableData?.[index]?.score || "N/A"}
                </p>
              </div>
            </Button>
            {/* <Button
              variant="outlined"
              onClick={() => {
                handleNavigate(
                  tableData?.[index]?.partnership_name,
                  tableData?.[index]
                );
              }}
              sx={{
                fontSize: "12px",
                marginLeft: 3,
                padding: "8px 40px",
                display: "flex",
                alignItems: "center",
                background: "#fff",
              }}
            >
              View
            </Button> */}
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenGPS({ open: true, data: tableData[index] });
              }}
              disabled={
                !["DEALER"].includes(tableData[index]?.partnership_name)
              }
              sx={{
                fontSize: "12px",
                marginLeft: 3,
                padding: "8px 40px",
                display: "flex",
                alignItems: "center",
                background: "#fff",
              }}
            >
              GPS &nbsp;&nbsp; <ShareLocationOutlinedIcon fontSize="small" />
            </Button>
          </div>
        );
      },
      width: 400,
      align: "left",
    },
  ]);

  const fetchTableData = async (
    page,
    limit,
    type,
    typeValue,
    sortType,
    sortField,
    textSrch
  ) => {
    setLoading(true);
    try {
      let params = `&page=${page}`;
      if (limit) params += `&limit=${limit}`;

      switch (type) {
        case "filter":
          params += `&partnerType=${typeValue}`;
          break;
        case "search":
          params += `&limit=1000&partnerType=${typeValue}&search=${textSrch}`;
          break;
      }

      let savedSort = localStorage.getItem("anchorSort");
      if (savedSort) {
        params = params + `&sortType=${savedSort}`;
      }

      let { data } = await fetchAllPartners(params);

      setTableData(data?.partners);
      setCount(data?.count);
    } catch (error) {}
    setLoading(false);
  };

  async function handleExport(type) {
    try {
      setDisableExport(true);
      const params = `type=${type}`;
      const { data } = await exportPartners(params);
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      const fileName = `${type === "anchor" ? "dealer" : type}s`;
      a.download = `${fileName}.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(error?.response?.data?.message || "failed to export");
    } finally {
      setDisableExport(false);
    }
  }

  // const fetchTableData = async (
  //   page,
  //   limit,
  //   type,
  //   typeValue,
  //   sortType,
  //   sortField
  // ) => {
  //   setLoading(true);
  //   try {
  //     let params = `&page=${page}`;
  //     if (limit) params += `&limit=${limit}`;

  //     switch (type) {
  //       case "filter":
  //         params += `&type=filter&filter=${typeValue}`;
  //         break;
  //       case "search":
  //         params += `&type=search&param=${typeValue}`;
  //         break;
  //     }

  //     let savedSort = localStorage.getItem("anchorSort");
  //     if (savedSort) {
  //       params = params + `&sort=${savedSort}`;
  //     }

  //     let { data } = await fetchAllAnchorsTable(params);
  //     setTableData(data?.data);
  //     setCount(data?.count);
  //   } catch (error) {}
  //   setLoading(false);
  // };

  useEffect(() => {
    fetchTableData(1, 100);
  }, []);

  let timeOut;

  const handleSearch = (value) => {
    if (value !== "") {
      setSearch(true);
      fetchTableData(1, null, "search", filter, null, null, value);
    } else {
      setSearchTxt("");
      setSearch(false);
      fetchTableData(1, 100, "filter", filter, null, null, null);
    }
  };

  return (
    <>
      <div className="px-3">
        {/* {!isLoading && ( */}
        <div className="d-flex p-3" style={{ justifyContent: "space-between" }}>
          <div className="d-flex flex-row">
            <ToggleButtons />
            <div className="ms-3">
              <AnchorSort
                onApply={() => {
                  fetchTableData(page, limit);
                }}
              />
            </div>
            <div className="ms-1">
              <Button
                variant="contained"
                disableElevation
                endIcon={<RemoveRedEyeIcon />}
                sx={{ height: 30 }}
                onClick={() => {
                  setGpsOverviewTable(true);
                }}
              >
                GPS{" "}
              </Button>
            </div>
          </div>
          <div className="d-flex">
            <Button
              sx={{
                height: 30,
              }}
              onClick={() => {
                handleRefreshScore();
              }}
              disabled={isUpdating}
            >
              <CachedIcon className={isUpdating ? "rotate-class" : ""} />{" "}
              Reconcile Score
            </Button>
            <Button
              sx={{
                height: 30,
              }}
              onClick={() => {
                setOpenDealerScore(true);
              }}
            >
              <AssessmentOutlinedIcon /> Dealer Score
            </Button>
            <div
              style={{
                width: 150,
              }}
            >
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  fontSize: "14px",
                  height: 30,
                }}
                disableElevation
              >
                <AddIcon /> Add Partner
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  color="info"
                  onClick={(e) => {
                    navigate(`/create-oem`);
                    handleClose(e);
                  }}
                  sx={{
                    width: 140,
                    color: "rgba(158, 0, 255, 1)",
                  }}
                >
                  OEM
                </MenuItem>
                <MenuItem
                  sx={{
                    width: 140,
                    color: "rgba(54, 159, 255, 1)",
                  }}
                  onClick={(e) => {
                    navigate(`/create-anchor-v2`);
                    handleClose(e);
                  }}
                  disabled={Number(user?.role) === 2}
                >
                  Dealer
                </MenuItem>
                <MenuItem
                  sx={{
                    width: 140,
                    color: "rgba(255, 107, 0, 1)",
                  }}
                  onClick={(e) => {
                    navigate(`/create-agent`);
                    handleClose(e);
                  }}
                >
                  Agent
                </MenuItem>
              </Menu>
            </div>
            <div
              style={{
                width: 150,
              }}
            >
              <Button
                id="basic-button"
                aria-controls={openExport ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openExport ? "true" : undefined}
                onClick={handleExportClick}
                variant="outlined"
                color="primary"
                sx={{
                  textTransform: "capitalize",
                  fontSize: "14px",
                  height: 30,
                }}
                disableElevation
                disabled={disableExport}
              >
                <GetAppIcon className="me-1" /> Export
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorExportEl}
                open={openExport}
                onClose={handleExportClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  color="info"
                  onClick={(e) => {
                    handleExport("oem");
                    handleExportClose(e);
                  }}
                  sx={{
                    width: 140,
                    color: "rgba(158, 0, 255, 1)",
                  }}
                >
                  OEM
                </MenuItem>
                <MenuItem
                  sx={{
                    width: 140,
                    color: "rgba(54, 159, 255, 1)",
                  }}
                  onClick={(e) => {
                    handleExport("anchor");
                    handleExportClose(e);
                  }}
                >
                  Dealer
                </MenuItem>
                <MenuItem
                  sx={{
                    width: 140,
                    color: "rgba(255, 107, 0, 1)",
                  }}
                  onClick={(e) => {
                    handleExport("agent");
                    handleExportClose(e);
                  }}
                >
                  Agent
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <div className="d-flex ps-3 mb-4">
          <TextField
            className="search-bar-table me-2"
            id="input-with-icon-textfield"
            placeholder="Search"
            variant="standard"
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: "gray" }} />,
              disableUnderline: true,
              style: {
                color: "black",
                fontWeight: 400,
                height: 20,
                width: 215,
              },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(e.target.value);
              }
            }}
          />
        </div>
        {/* )} */}
        <CustomTable
          tableHeight={"75vh"}
          tableData={tableData}
          columns={columns}
          tablePage={page}
          tableLimit={limit}
          // serialNo={true}
          defaultLimit={100}
          totalCount={totalCount}
          isLoading={isLoading}
          onPageChange={(page, limit) => {
            setLimit(limit);
            setPage(page);
            if (isSearch) {
              fetchTableData(page, limit, "search", searchTxt);
            } else {
              if (filter === "all") {
                fetchTableData(page, limit);
              } else {
                fetchTableData(page, limit, "filter", filter);
              }
            }
          }}
          onLimitChange={(page, limit) => {
            setLimit(limit);
            setPage(page);
            if (filter === "all") {
              fetchTableData(1, limit);
            } else {
              fetchTableData(1, limit, "filter", filter);
            }
          }}
          onSortChange={(page, limit, sortValue, sortField) => {
            // fetchTableData(page, limit, "sort", null, sortValue, sortField);
          }}
          onDoubleClick={(data) => {
            let partnership_name = data?.partnership_name;
            if (partnership_name === "DEALER") {
              navigate(`/update-anchor-v2/${data?.partner_id}`);
            } else if (partnership_name === "AGENT") {
              navigate(`/update-agent/${data?.partner_id}`);
            } else if (partnership_name === "OEM") {
              navigate(`/update-oem/${data?.partner_id}`);
            }
          }}
        />
      </div>
      <GPStable
        open={openGPS?.open}
        handleClose={() => {
          setOpenGPS({ open: false, data: {} });
        }}
        partnerData={openGPS?.data}
      />
      <GPSOverviewTable
        open={gpsOverviewTable}
        handleClose={() => {
          setGpsOverviewTable(false);
        }}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openDealerScore}
        onClose={() => {
          setOpenDealerScore(false);
        }}
        aria-labelledby="draggable-dialog-title"
      >
        <div
          style={{
            position: "absolute",
            right: 5,
            top: 5,
          }}
        >
          <IconButton onClick={() => setOpenDealerScore(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <DealerScore isDialog={true} setOpenDealerScore={setOpenDealerScore} />
      </Dialog>
    </>
  );
};
