import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Slide,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import {
  baseURL,
  updateMoneyReceived,
  refreshBeFiSc,
  submitCreditDate,
} from "../../../api";
import React, { useContext, useEffect, useState } from "react";
import {
  assignNBFC,
  changeNBFC,
  creditEngine,
  decryptData,
  fetchAllNbfc,
  fetchSingleApplication,
} from "../../../api";
import CreditRiskCard from "../../../Components/CrifCard/CreditCard";
import { statusColors } from "../../../Constants/colors";
import { GlobalContext } from "../../../Context/GlobalContext";
import {
  f,
  fStatus,
  responseFormat,
  scoreCheckText,
} from "../../../utility/Formater";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate, useParams } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CreditEngineComp from "../../../Components/CreditEngineComp/CreditEngineComp";
import CopyToDemoComp from "../../../Components/CopyToDemo";
import { UserContext } from "../../../Context/UserContext";
import { toast } from "react-toastify";
import useApplication from "../../../hooks/useApplication";
import AssignNBFC from "../../../Components/AssignNBFC/AssignNBFC";
import ReactJson from "react-json-view";
import { NotAvailable } from "../../../Components/StatusChip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { stageBeforeCo } from "../../../utility/StagesV2";
import DummyStagePicker from "../../../Components/DummyStagePicker";
import SanctionLetterSend from "../../../Components/Dialogs/SanctionLetterSend";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { RefreshOutlined } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const intialStageData = {
  stage: "",
  status: "",
  sub_status: "",
  remarks: "",
  nbfc_id: "",
};

const topButtonStyle = {
  float: "right",
  fontSize: 10,
  boxShadow: "none",
  borderRadius: 2,
};

const Overview = ({ application_id, refresh, isLoading, setOpenCSTC }) => {
  const { updateDetailState, customersList, panData } =
    useContext(GlobalContext);

  const { user } = useContext(UserContext);
  const { getApplicationDetails } = useApplication();

  const [updateDetails, setUpdateDetails] = updateDetailState;
  const [stageData, setStageData] = React.useState(intialStageData);
  const [creditEngineData, setCreditEngineData] = React.useState({
    open: false,
    type: "customer",
  });
  const [copyToDemoData, setCopyToDemoData] = useState({
    open: false,
    close: "hello",
  });
  const navigate = useNavigate();

  const { stage } = useParams();
  const [openCrifData, setOpenCrifData] = useState({
    open: false,
    loading: false,
  });
  const [openNBFC, setOpenNBFC] = useState({ open: false, type: "" });
  const [crifData, setCrifData] = useState({});
  const [nbfcList, setNbfcList] = useState([]);
  const [nbfcAssignData, setNbfcAssignData] = useState({
    isLoading: false,
    nbfc_id: "",
  });
  const [demo, setDemo] = useState({ demo: " " });
  const [dev, setDev] = useState({ dev: " " });
  const [openSactionLetter, setOpenSactionLetter] = useState(false);
  const [isAadhaarLoading, setIsAadhaarLoading] = useState(false);

  const handleClickOpen = () => {
    setOpenCrifData({
      open: true,
      loading: true,
    });
  };
  const [actionLoading, setActionLoading] = useState(null);

  const handleClose = () => {
    setOpenCrifData({
      open: false,
      loading: false,
    });
    // window.location.reload();
  };

  useEffect(() => {
    setStageData({
      ...intialStageData,
      stage: updateDetails?.current_stage_data?.stage,
      status: updateDetails?.current_stage_data?.status,
      sub_status:
        typeof updateDetails?.current_stage_data?.sub_status === "string"
          ? updateDetails?.current_stage_data?.sub_status
          : "",
      remarks:
        typeof updateDetails?.current_stage_data?.remark === "string"
          ? updateDetails?.current_stage_data?.remark
          : "",
    });
  }, [updateDetails?.application_id]);

  const handleViewCrif = async () => {
    setOpenCrifData({
      open: true,
      loading: true,
    });
    try {
      let { data } = await decryptData({
        data: updateDetails?.crif_data,
      });
      if (data?.status) {
        setCrifData(data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setOpenCrifData({ open: true, loading: false });
  };

  const fetchNBFCs = async () => {
    try {
      let { data } = await fetchAllNbfc();
      setNbfcList((data?.data || []).filter((item) => item?._id !== "EM00004"));
    } catch (error) {}
  };

  const handleMoneyReceived = async () => {
    if (updateDetails?.is_disbursed) {
      toast.warning(
        "Already disbursed application, you can't do this operation"
      );
      return;
    }
    setActionLoading("money");
    try {
      let { data } = await updateMoneyReceived({
        application_id,
      });
      setUpdateDetails({
        ...updateDetails,
        money_disbursed: data?.money_received,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setActionLoading(null);
  };

  async function handleGenerateCAMSheet() {
    try {
      window.open(
        `${baseURL}/common/download-cam/${updateDetails?.application_id}`
      );
    } catch (error) {
      toast?.error(
        error?.response?.data?.message || "cam generation failed !!!"
      );
    }
  }

  async function handleBeFiSc() {
    try {
      setIsAadhaarLoading(true);
      const { data } = await refreshBeFiSc({
        application_id: updateDetails?.application_id,
      });
      toast(data?.message || "update aadhaar details details");
      if (data?.status) {
        setUpdateDetails((prev) => ({
          ...prev,
          aadhaar_mobile_number_matched: JSON.stringify(
            data?.aadhaar_mobile_matched
          ),
          aadhaar_mobile_number_masked: data?.masked_mobile_number,
        }));
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    } finally {
      setIsAadhaarLoading(false);
      setActionLoading(null);
    }
  }

  useEffect(() => {
    if (openNBFC?.open) {
      fetchNBFCs();
    }
  }, [openNBFC?.open]);

  useEffect(() => {
    if (
      stage === "assign" &&
      updateDetails?.creation_stage === "NBFC_ASSIGN_PENDING"
    ) {
      setOpenNBFC({ open: true, type: "assign" });
    }
  }, [stage, updateDetails?.creation_stage]);

  return (
    <>
      <Grid container spacing={2} className="mb-4">
        <Grid item xs={12} className="pt-2">
          {!isLoading &&
            [1, 5].includes(Number(user?.role)) &&
            !updateDetails?.is_disbursed &&
            !stageBeforeCo?.includes(updateDetails?.creation_stage) && (
              <Button
                variant="contained"
                sx={{ ...topButtonStyle, marginLeft: 1 }}
                onClick={() => {
                  //change stage to co-customer -> CSTC
                  setOpenCSTC(true);
                }}
              >
                Change Stage to Co-Customer
              </Button>
            )}

          {!isLoading &&
            Number(user?.role) !== 3 &&
            (updateDetails?.send_to_nbfc ||
              updateDetails?.is__sent_to_nbfc) && (
              <Button
                variant="contained"
                sx={{ ...topButtonStyle, marginLeft: 1 }}
                onClick={() => {
                  handleGenerateCAMSheet();
                }}
                disabled={false}
              >
                CAM Sheet
              </Button>
            )}

          {!isLoading &&
            updateDetails?.nbfc_id !== "EM00004" &&
            updateDetails?.nbfc_id === "FI00005" && (
              <Button
                variant="contained"
                sx={{ ...topButtonStyle }}
                style={{
                  background: updateDetails?.sanction_letter_sent
                    ? "green"
                    : "gray",
                }}
                onClick={() => {
                  setOpenSactionLetter(true);
                }}
              >
                Sanction Letter{" "}
                <EmailOutlinedIcon sx={{ fontSize: 13, ml: 1 }} />
              </Button>
            )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className="overview-card">
            <p className="card-heading">
              Application Details
              {Number(user?.role) === 1 &&
              baseURL === "https://prod.api.lms.embifi.in/api/v1" ? (
                <span
                  style={{
                    float: "right",
                    cursor: "pointer",
                    padding: "2px 10px",
                    background: "#1d1b31",
                    color: "white",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    // handleRunEngine("customer");
                    setCopyToDemoData({ open: true });
                    setDev({ dev: "dev" });
                  }}
                >
                  <ContentCopyIcon sx={{ fontSize: 16 }} /> Copy To Dev
                </span>
              ) : Number(user?.role) === 1 &&
                baseURL === "https://api.lms.embifi.in/api/v1" ? (
                <span
                  style={{
                    float: "right",
                    cursor: "pointer",
                    padding: "2px 10px",
                    background: "#1d1b31",
                    color: "white",
                    borderRadius: "20px",
                  }}
                  onClick={() => {
                    // handleRunEngine("customer");
                    setCopyToDemoData({ open: true });
                    setDemo({ demo: "valueDemo" });

                    // navigate({state:{checkDemo: "demo"}})
                  }}
                >
                  <ContentCopyIcon sx={{ fontSize: 16 }} /> Copy To Demo
                </span>
              ) : null}
              {/* {Number(user?.role) === 1 &&
                baseURL === "https://prod.api.lms.embifi.in/api/v1" && (
                  <span
                    style={{
                      float: "right",
                      cursor: "pointer",
                      padding: "2px 10px",
                      background: "#1d1b31",
                      color: "white",
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      // handleRunEngine("customer");
                      setCopyToDemoData({ open: true });
                    }}
                  >
                    <ContentCopyIcon sx={{ fontSize: 16 }} /> Copy To Demo
                  </span>
                )} */}
            </p>
            <div className="card-content">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={7}>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Application ID:"}
                    text={f(updateDetails?.application_id)}
                  />
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Customer ID:"}
                      text={f(updateDetails?.customer_id)}
                    />
                  )}

                  <ListItemComp
                    loading={isLoading}
                    heading={"NBFC:"}
                    text={
                      <span>
                        {updateDetails?.creation_stage !==
                        "NBFC_ASSIGN_PENDING" ? (
                          <>
                            {f(
                              updateDetails?.nbfc_id === "EM00004" ? (
                                <span style={{ fontSize: 10, color: "gray" }}>
                                  Not Assigned
                                </span>
                              ) : (
                                updateDetails?.nbfc_name
                              )
                            )}
                            {updateDetails?.nbfc_id !== "EM00004" &&
                              updateDetails?.is_disbursed === null &&
                              Number(user?.role) !== 3 && (
                                <Button
                                  sx={{ fontSize: 12 }}
                                  onClick={() => {
                                    setOpenNBFC({ open: true, type: "CHANGE" });
                                  }}
                                >
                                  Change
                                </Button>
                              )}
                          </>
                        ) : (
                          <Button
                            onClick={() => {
                              setOpenNBFC({ open: true, type: "ASSIGN" });
                            }}
                          >
                            Assign NBFC
                          </Button>
                        )}
                      </span>
                    }
                  />

                  <ListItemComp
                    loading={isLoading}
                    heading={"Anchor:"}
                    text={
                      <div
                        className="pointer"
                        onClick={() => {
                          if (updateDetails?.anchor_name) {
                            navigate(
                              `/update-anchor-v2/${updateDetails?.anchor_id}`,
                              {
                                state: {
                                  path: `/view-application/${application_id}`,
                                },
                              }
                            );
                          }
                        }}
                      >
                        {f(updateDetails?.anchor_name)}
                      </div>
                    }
                  />
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Agent:"}
                      text={
                        <div
                          className="pointer"
                          onClick={() => {
                            if (updateDetails?.agent_id) {
                              navigate(
                                `/update-agent/${updateDetails?.agent_id}`,
                                {
                                  state: {
                                    path: `/view-application/${application_id}`,
                                  },
                                }
                              );
                            }
                          }}
                        >
                          {f(updateDetails?.agent_id)}
                        </div>
                      }
                    />
                  )}
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Origin:"}
                      text={f(updateDetails?.origin)}
                    />
                  )}
                </Grid>
                {Number(user?.role) !== 3 && (
                  <Grid item xs={12} lg={5}>
                    <ListItemComp
                      loading={isLoading}
                      heading={"Creation Date:"}
                      text={f(updateDetails?.start_date, "date/time")}
                    />
                    <ListItemComp
                      loading={isLoading}
                      heading={"Last Updated:"}
                      text={f(updateDetails?.last_updated, "date/time")}
                    />
                  </Grid>
                )}

                {/* {!updateDetails?.is_disbursed &&
                  updateDetails?.nbfc_id !== "EM00004" && ( */}
                {Number(user?.role) !== 3 && (
                  <Grid item xs={12} className="pt-0 mt-3">
                    <ListItemComp
                      loading={isLoading}
                      heading={"Dummy Stage:"}
                      text={<DummyStagePicker applicationId={application_id} />}
                    />
                  </Grid>
                )}
                {/* )} */}

                {Number(user?.role) !== 3 && (
                  <Grid item xs={12} sm={12} style={{ position: "relative" }}>
                    <hr />
                    <Button
                      color="secondary"
                      style={{
                        position: "absolute",
                        right: 0,
                        zIndex: 1000,
                        fontSize: "12px",
                      }}
                      disabled={Number(user?.role) == 2}
                      onClick={() => {
                        navigate(
                          `/update-stage-v2/${updateDetails?.application_id}`,
                          {
                            state: {
                              path: `/view-application/${updateDetails?.application_id}`,
                            },
                          }
                        );
                      }}
                    >
                      <EditIcon sx={{ fontSize: 15, mr: 1 }} /> Edit
                    </Button>
                    <ListItemComp
                      loading={isLoading}
                      heading={"Overall Status"}
                      text={
                        <>
                          <span
                            style={{
                              color:
                                statusColors?.[updateDetails?.current_status],
                              border: `1px solid ${
                                statusColors?.[updateDetails?.current_status]
                              }`,
                              padding: "2px 8px",
                              fontSize: "12px",
                              borderRadius: "10px",
                            }}
                          >
                            {updateDetails?.current_status?.toUpperCase()}
                          </span>
                        </>
                      }
                    />
                    <ListItemComp
                      loading={isLoading}
                      heading={"Current Stage:"}
                      text={fStatus(updateDetails?.current_stage_data?.stage)}
                    />
                    <ListItemComp
                      loading={isLoading}
                      heading={"Current Status:"}
                      text={fStatus(updateDetails?.current_stage_data?.status)}
                    />
                    <ListItemComp
                      loading={isLoading}
                      heading={"Sub-status:"}
                      text={fStatus(
                        updateDetails?.current_stage_data?.sub_status
                      )}
                    />
                    {/* <ListItemComp loading={isLoading}
                    heading={"Remarks:"}
                    text={f(updateDetails?.current_stage_data?.remark)}
                  /> */}
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} lg={6}>
          <div className="overview-card">
            <p className="card-heading">
              Credit Engine Data{" "}
              {[1, 5].includes(Number(user?.role)) &&
                updateDetails?.app_creation_stage !==
                  "CUSTOMER_BASIC_DETAILS" && (
                  <span
                    style={{
                      float: "right",
                      cursor: "pointer",
                      padding: "2px 10px",
                      background: "#1d1b31",
                      color: "white",
                      borderRadius: "20px",
                    }}
                    onClick={() => {
                      setCreditEngineData({ open: true, type: "customer" });
                    }}
                  >
                    <AutorenewIcon sx={{ fontSize: 16 }} />
                    Run Engine
                  </span>
                )}
            </p>
            <div className="card-content">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Eligibility:"}
                      text={f(updateDetails?.credit_eligible, "bool")}
                    />
                  )}
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Co-customer Required:"}
                      text={f(updateDetails?.credit_isCocustomer, "bool")}
                    />
                  )}
                  <ListItemComp
                    loading={isLoading}
                    heading={"Score Remark:"}
                    text={scoreCheckText(updateDetails?.crif_score)}
                  />
                  {Number(user?.role) !== 3 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Response:"}
                      text={responseFormat(
                        updateDetails?.credit_response,
                        updateDetails?.credit_eligible
                      )}
                    />
                  )}
                  {[1, 5].includes(Number(user?.role)) && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Customer Distance:"}
                      text={
                        updateDetails?.credit_engine?.details?.current_distance?.toFixed(
                          2
                        ) ?? 0
                      }
                    />
                  )}
                  {[1, 5].includes(Number(user?.role)) && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Co-Customer Distance:"}
                      text={
                        updateDetails?.coApplicantExist ? (
                          updateDetails?.co_app_dealer_distance?.toFixed(2) ?? 0
                        ) : (
                          <NotAvailable />
                        )
                      }
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={6} className="pb-2">
                  <CreditRiskCard
                    application={{
                      credit_pull: {
                        credit_data: { crif_score: updateDetails?.crif_score },
                      },
                    }}
                  />
                </Grid>

                {Number(user?.role) === 1 &&
                  updateDetails?.crif_data !== null && (
                    <Grid item xs={12}>
                      <hr />

                      <Button
                        onClick={() => {
                          handleViewCrif();
                        }}
                      >
                        <RemoveRedEyeIcon sx={{ mr: 1 }} /> View Report
                      </Button>
                    </Grid>
                  )}
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="overview-card">
            <p className="card-heading">Customer Details</p>
            <div className="card-content">
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={3}>
                  <p className="sub-heading">Pan Details</p>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Name:"}
                    text={f(updateDetails?.customer_name)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Number:"}
                    text={f(updateDetails?.pan_number)}
                  />

                  {Number(user?.role) !== 3 && (
                    <>
                      <div className="pe-4">
                        <hr />
                      </div>

                      <p className="sub-heading">Other Details</p>
                      <ListItemComp
                        loading={isLoading}
                        heading={"Income:"}
                        text={f(updateDetails?.income)}
                      />
                      <ListItemComp
                        loading={isLoading}
                        heading={"Education:"}
                        text={f(updateDetails?.education)}
                      />
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <p className="sub-heading">Aadhaar Details</p>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Name:"}
                    text={f(updateDetails?.aadhaar_name)}
                  />
                  {[1, 5].includes(Number(user?.role)) && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"UID:"}
                      text={
                        [1, 5].includes(Number(user?.role)) &&
                        updateDetails?.aadhaar_number_unmasked
                          ? f(updateDetails?.aadhaar_number_unmasked)
                          : f(updateDetails?.aadhaar_number)
                      }
                    />
                  )}
                  <ListItemComp
                    loading={isLoading}
                    heading={"Gender:"}
                    text={f(updateDetails?.aadhaar_gender)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Address:"}
                    text={f(updateDetails?.aadhaar_address)}
                  />
                  {[1, 2, 5].includes(Number(user?.role)) && (
                    <div style={{ backgroundColor: "hsla(0, 0%, 96%, 1)" }}>
                      <ListItemComp
                        loading={isLoading || isAadhaarLoading}
                        heading={"Aadhaar Linked Mobile No.:"}
                        text={f(updateDetails?.aadhaar_mobile_number_masked)}
                      />
                      <div className="d-flex align-items-center justify-content-space-between">
                        <ListItemComp
                          loading={isLoading || isAadhaarLoading}
                          heading={"Aadhaar Linked Mobile Match :"}
                          text={f(updateDetails?.aadhaar_mobile_number_matched)}
                        />
                        <div>
                          {[1, 5].includes(Number(user?.role)) && (
                            <Button
                              color="primary"
                              variant="contained"
                              disableElevation
                              style={{
                                fontSize: 10,
                                padding: 2,
                                margin: "0px 5px",
                              }}
                              disabled={isAadhaarLoading}
                              onClick={() => {
                                handleBeFiSc();
                              }}
                            >
                              <RefreshOutlined style={{ fontSize: "14px" }} />{" "}
                              Re-Run
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} lg={5}>
                  <p className="sub-heading">Residential Address</p>
                  {Number(user?.role) === 1 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Is Property Owned:"}
                      text={f(updateDetails?.is_property_owned, "bool")}
                    />
                  )}
                  {Number(user?.role) === 1 && (
                    <ListItemComp
                      loading={isLoading}
                      heading={"Owned By:"}
                      text={f(updateDetails?.owned_by)}
                    />
                  )}
                  <ListItemComp
                    loading={isLoading}
                    heading={"City:"}
                    text={f(updateDetails?.residential_address_city)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"District:"}
                    text={f(updateDetails?.residential_address_district)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"State:"}
                    text={f(updateDetails?.residential_address_state)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Pincode:"}
                    text={f(updateDetails?.residential_address_pincode)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Address:"}
                    text={f(updateDetails?.residential_address)}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className="overview-card">
            <p className="card-heading">Basic Loan Details</p>
            <div className="card-content">
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Loan ID:"}
                    text={f(updateDetails?.loan_id)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Loan Type:"}
                    text={f(updateDetails?.loan_type)}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Loan Amount:"}
                    text={f(updateDetails?.loan_amount, "cur")}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Tenure:"}
                    text={`${updateDetails?.tenure_value} ${updateDetails?.tenure_type}`}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Interest Rate:"}
                    text={f(updateDetails?.interest_rate, "rate")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <ListItemComp
                    loading={isLoading}
                    heading={"Disbursement Status:"}
                    text={(() => {
                      if (updateDetails?.is_disbursed) {
                        return "Disbursed";
                      } else if (updateDetails?.is_disbursed === null) {
                        return "Not Disbursed";
                      } else {
                        return "Disbursal Rejected";
                      }
                    })()}
                  />
                  <ListItemComp
                    loading={isLoading}
                    heading={"Disbursed Date:"}
                    text={f(updateDetails?.disbursal_date)}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <CreditEngineComp
        open={creditEngineData?.open}
        type={creditEngineData?.type}
        handleClose={() => {
          setCreditEngineData({ open: false, type: "" });
        }}
        handleReload={() => {
          getApplicationDetails(application_id);
        }}
      />
      <CopyToDemoComp
        open={copyToDemoData?.open}
        demo={demo}
        dev={dev}
        // type={copyToDemoData?.type}
        handleClose={() => {
          setCopyToDemoData({ open: false });
          window.location.reload();
        }}
      />

      <Dialog
        open={openCrifData?.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>Crif Report</DialogTitle>
        <DialogContent>
          {openCrifData?.loading ? (
            <p>
              {" "}
              <CircularProgress size={12} /> Loading
            </p>
          ) : (
            <pre>{JSON.stringify(crifData, null, 2)}</pre>
          )}
          {/* <ReactJson
            src={crifData}
            collapsed={false}
            displayDataTypes={false}
            displayArrayKey={false}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <AssignNBFC
        openNBFC={openNBFC}
        setOpenNBFC={setOpenNBFC}
        setNbfcAssignData={setNbfcAssignData}
        nbfcAssignData={nbfcAssignData}
        nbfcList={nbfcList}
        Transition={Transition}
        refresh={refresh}
        application_id={application_id}
      />

      {openSactionLetter && (
        <SanctionLetterSend
          application_id={application_id}
          handleClose={() => {
            setOpenSactionLetter(false);
          }}
        />
      )}
    </>
  );
};

export default Overview;

const ListItemComp = ({ icon, heading, text, loading }) => {
  return (
    <ListItem disablePadding>
      <ListItemIcon className="list-key">
        {
          <span>
            {icon && icon}
            {heading}
          </span>
        }
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "13px" }}
        className="list-value"
        primary={
          loading ? (
            <span>
              <Skeleton />
            </span>
          ) : (
            text
          )
        }
      />
    </ListItem>
  );
};
