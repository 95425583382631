import * as React from "react";
import { styled } from "@mui/material/styles";

import IconButton from "@mui/material/IconButton";
import {
  Button,
  Grow,
  Pagination,
  PaginationItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Zoom,
  tableCellClasses,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffect } from "react";
import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#d8e0ff",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const NoDataTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const NoDataTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.disabled,
  fontStyle: "italic",
}));

function CustomTableV2({
  tableData = [],
  tableHeight = 400,
  columns = [],
  isLoading = false,
  loadingRows = 6,
  serialNo = false,
  limit = 0,
  page = 1,
  elevation = 0,
  isPaginated = false,
  totalCount = 0,
  onPageChange = null,
  maxHeight,
  borderBottom = false,
  headerComp,
  totalRow,
}) {
  const [editedPageNo, setEditedPageNo] = useState(page);

  useEffect(() => {
    setEditedPageNo(page);
  }, [page]);

  return (
    <React.Fragment>
      <Zoom in={true}>
        <TableContainer
          component={Paper}
          elevation={elevation}
          sx={{
            ...(maxHeight
              ? {
                  maxHeight,
                }
              : { height: tableHeight }),
            "::-webkit-progress-bar": { display: "none" },
            borderRadius: 0,
          }}
          className={`border ${borderBottom ? "" : "border-bottom-0"}`}
        >
          {headerComp && <div className="table-header-comp">{headerComp}</div>}
          <Table
            stickyHeader
            sx={{ width: "100%" }}
            aria-label="customized table"
          >
            <TableHead>
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...(true ? { timeout: 1000 } : {})}
              >
                <TableRow>
                  {serialNo && (
                    <StyledTableCell align="left" width={50}>
                      SL.No
                    </StyledTableCell>
                  )}
                  {columns?.map((item, index) => (
                    <StyledTableCell
                      key={index}
                      sx={{
                        backgroundColor: item?.bgcolor ?? "inherit",
                        width: item?.width ?? 100,
                      }}
                      align="left"
                    >
                      {item?.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </Grow>
            </TableHead>
            <TableBody>
              {!isLoading &&
                tableData.map((_, i) => (
                  <Grow
                    key={i}
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(true ? { timeout: 1000 } : {})}
                  >
                    <StyledTableRow key={i}>
                      {serialNo && (
                        <StyledTableCell align="left" width={50}>
                          {limit * (page - 1) + i + 1}
                        </StyledTableCell>
                      )}
                      {columns?.map((item, idx) => (
                        <StyledTableCell
                          key={idx}
                          align="left"
                          width={item?.width ?? 100}
                        >
                          {item?.render(i)}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </Grow>
                ))}
              {isLoading &&
                new Array(loadingRows).fill(null).map((_, i) => (
                  <Grow
                    key={i}
                    in={true}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(true ? { timeout: 1000 } : {})}
                  >
                    <StyledTableRow key={i}>
                      {new Array(
                        serialNo ? columns?.length + 1 : columns?.length
                      )
                        .fill(null)
                        .map((item, idx) => (
                          <StyledTableCell
                            key={idx}
                            align="left"
                            width={item?.width ?? 100}
                          >
                            <Skeleton width={"100%"} />
                          </StyledTableCell>
                        ))}
                    </StyledTableRow>
                  </Grow>
                ))}

              {totalRow && (
                <>
                  <Grow in={true} style={{ transformOrigin: "0 0 0" }}>
                    <StyledTableRow>
                      {Object?.keys(totalRow)?.map((row) => {
                        return (
                          <StyledTableCell align="left">
                            <b>{totalRow?.[row]}</b>
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  </Grow>
                </>
              )}
              {!isLoading && tableData?.length === 0 && (
                <Grow in={true} style={{ transformOrigin: "0 0 0" }}>
                  <NoDataTableRow>
                    <NoDataTableCell colSpan={(serialNo ? 1 : 0) + columns.length} rowSpan={loadingRows}>
                      No Data
                    </NoDataTableCell>
                  </NoDataTableRow>
                </Grow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Zoom>
      {isPaginated && (
        <>
          <Paper sx={{ width: "100%" }} elevation={1} square>
            <div className="d-flex flex-row align-items-center w-100 justify-content-center p-3">
              <Pagination
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
                page={page}
                onChange={(_, pageNumber) => {
                  onPageChange(pageNumber);
                }}
                count={Math.ceil(totalCount / limit)}
                variant="outlined"
                shape="rounded"
              />
              <div className="mx-2 ms-5">
                <span>Page </span>
                <input
                  className="mx-2 border-bottom rounded"
                  style={{
                    width: "34px",
                    height: "32px",
                    outline: "none",
                    textAlign: "center",
                    border: "1px solid #C0D5DE",
                  }}
                  value={editedPageNo}
                  onChange={(event) => {
                    setEditedPageNo(event.target.value);
                  }}
                />
                <span>Of {Math.ceil(totalCount / limit)}</span>
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  className="mx-2 rounded-pill"
                  onClick={() => {
                    if (onPageChange) {
                      onPageChange(editedPageNo);
                    }
                  }}
                >
                  Go
                </Button>
              </div>
            </div>
          </Paper>
        </>
      )}
    </React.Fragment>
  );
}

export default CustomTableV2;
