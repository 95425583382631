import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GetAppIcon from "@mui/icons-material/GetApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HistoryIcon from "@mui/icons-material/History";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SearchIcon from "@mui/icons-material/Search";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  TextField,
  Tooltip
} from "@mui/material";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BulkUploadStatus from "../../Components/BulkUploadStatus";
import CustomTable from "../../Components/CustomTable/CustomTable";
import DragDrop from "../../Components/DocumentUploader";
import DownloadTable from "../../Components/Download/TableExport";
import Dropdown from "../../Components/Dropdown";
import FileViewer from "../../Components/FileViewer/FileViewer";
import LoadingOver from "../../Components/Loading";
import AdvancedSort from "../../Components/Sort/AdvancedSort";
import TransactionHistory from "../../Components/TransactionHistory";
import { UserContext } from "../../Context/UserContext";
import {
  BulkTransationData,
  baseURL,
  changeApprovalStatusV2,
  fetchAllTransactionsV2,
  sendPaymentReceipt,
  uploadCollectionCsvV2
} from "../../api";
import uploadAnimation from "../../assets/cloud-upload.gif";
import { f } from "../../utility/Formater";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialStateBulkResponse = {
  successArray: [],
  failedArray: [],
  successCount: 0,
  failedCount: 0,
  open: false,
};

const docViewInitial = {
  open: false,
};

const columnsRemovedForNBFC = {
  NBFC: 1,
  "Approval Status": 1,
  "Edited Status": 1,
  "Fyno Message": 1,
};

export default function TransationsTableV2() {
  const { user } = useContext(UserContext);

  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [overLoading, setOverLoading] = useState({
    load: false,
    message: "",
  });
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [collectionCSV, setCollectionCSV] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const [trnxDialog_app_id, setTrnxDialog_app_id] = useState("");
  const navigate = useNavigate();

  const [openExport, setOpenExport] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);

  const [trnxDialog, setTrnxDialog] = useState(false);
  const [loadRow, setLoadRow] = useState(null);

  const [bulkUploadResponse, setBulkUploadResponse] = useState(
    initialStateBulkResponse
  );
  const [historyOpen, setHistoryOpen] = useState(false);
  const [docViewer, setDocViewer] = useState(docViewInitial);
  const [transactionData, setTransactionData] = useState([]);
  const [lastTrasactionData, setLastTrasactionData] = useState(null);

  function capitalizeFirstLetter(string) {
    if (string?.length > 0) {
      return (string?.charAt(0)?.toUpperCase() + string?.slice(1))?.replaceAll(
        "_",
        " "
      );
    }
  }

  let columns = [
    { label: "Application ID", value: "application_id", copy: true },

    {
      label: "Customer Name",
      value: "customerData.pan_details.name",
      render: (i) => {
        return (
          tableData[i]?.customerData?.pan_details?.name ||
          tableData[i]?.customerData?.corporate_pan_details?.name
        );
        // return statusChipFunc(tableData[i]);
      },
      isSortable: true,
      width: 250,
      freeze: true,
      left: "0px",
    },

    {
      label: "Transaction Date",
      render: (i) => {
        return tableData[i]?.transaction_dateMS
          ? moment(tableData[i]?.transaction_dateMS).format("DD-MM-YYYY")
          : "-";
      },
      value: "transaction.transaction_dateMS",
      isSortable: true,
      width: 180,
    },
    {
      label: "Transaction Amount",
      render: (i) => {
        return tableData[i]?.transaction_amount
          ? f((tableData[i]?.transaction_amount).toFixed(2), "cur")
          : "-";
      },
      value: "transaction.transaction_amount",
      isSortable: true,
      width: 150,
    },

    {
      label: "Payment Mode",
      render: (i) => {
        return tableData[i]?.payment_mode
          ? tableData[i]?.payment_mode?.replaceAll("_", " ")
          : "-";
      },
      value: "transaction.payment_mode",
      isSortable: true,
      width: 150,
    },
    {
      label: "Approval Status",
      render: (i) => {
        return loadRow === i ? (
          <CircularProgress size={20} />
        ) : (
          <Dropdown
            value={tableData[i]?.is_approved}
            ddList={[true, false]}
            onChange={(ddVal) => changeApprovalStatus(tableData[i], ddVal, i)}
          />
        );
      },
      value: "transaction.is_approved",
      isSortable: true,
      width: 260,
    },

    {
      label: "Updated Date",
      render: (i) => {
        return tableData[i]?.updated_date
          ? moment(tableData[i]?.updated_date).format("DD-MM-YYYY")
          : "-";
      },
      value: "transaction.updated_date",
      isSortable: true,
      width: 180,
    },

    {
      label: "Transaction Number",
      render: (i) => {
        return tableData[i]?.transaction_id || "--";
      },
      value: "transaction.transaction_id",
      isSortable: true,
      width: 260,
    },
    {
      label: "UTR Number",
      render: (i) => {
        return tableData[i]?.transaction_utr || "--";
      },
      value: "transaction.txn_utr",
      isSortable: true,
      width: 260,
    },
    {
      label: "Edited Status",
      render: (i) => {
        return tableData[i]?.is_edited ? "Edited" : "Not Edited";
      },
      value: "transaction.is_edited",
      isSortable: false,
      width: 200,
    },
    {
      label: "Anchor",
      render: (i) => {
        return tableData[i]?.anchorData?.name || "--";
      },
      value: "anchorData.name",
      isSortable: true,
      width: 260,
    },

    {
      label: "OEM",
      render: (i) => {
        return tableData[i]?.oemData?.company_name || "--";
      },
      value: "noemData.company_name",
      isSortable: true,
      width: 260,
    },

    {
      label: "NBFC",
      render: (i) => {
        return tableData[i]?.nbfcData?.name || "--";
      },
      value: "nbfcData.name",
      isSortable: true,
      width: 260,
    },
  ];

  columns = columns.concat([
    {
      label: "Action",
      render: (index) => {
        return (
          <>
            <Button
              onClick={() => {
                navigate(
                  `/view-application/${tableData[index].loanData?.application_id}/5`,
                  {
                    state: {
                      path: `transactions-v2`,
                    },
                  }
                );
              }}
            >
              view
            </Button>
            {Number(user?.role) !== 3 && (
              <Button
                disabled={!tableData[index]?.documents?.length > 0}
                onClick={() => {
                  setDocViewer({
                    open: true,
                    url: tableData[index]?.documents?.[0]?.fileLink,
                    title: capitalizeFirstLetter(
                      tableData[index]?.documents?.[0]?.name
                    ),
                    data: tableData[index],
                    isMulti: false,
                    application_id: tableData[index]?.application_id,
                  });
                }}
              >
                <VisibilityIcon />
              </Button>
            )}
            {/* {Number(user?.role) !== 3 && (
              <Button
                disabled={!tableData[index]?.is_approved}
                onClick={() => {
                  let fileName = `${
                    tableData[index]?.customerData?.pan_details?.name
                  }-${moment(tableData[index]?.transaction_dateMS).format(
                    "DD/MM/YYYY"
                  )}-${(tableData[index]?.transaction_amount).toFixed(2)}.pdf`;

                  handleDownloadReceipt(tableData[index]?._id, fileName);
                }}
              >
                <FileDownloadIcon />
              </Button>
            )} */}
            {Number(user?.role) !== 3 && (
              <Button
                disabled={!tableData[index]?.is_approved}
                onClick={() => {
                  handleDownloadReceipt(tableData[index]?._id);
                }}
              >
                <FileDownloadIcon />
              </Button>
            )}
          </>
        );
      },
      width: 200,
    },
    {
      label: "Fyno Message",
      render: (index) => {
        return (
          <>
            <Button
              style={{
                background: (() => {
                  let val = tableData[index]?.transaction_receipt_sent || null;
                  if (val === null) return "#175EE8";
                  else if (val) return "green";
                  else return "red";
                })(),
                color: "white",
                fontSize: 10,
              }}
              onClick={() => {
                if (tableData[index]?.transaction_receipt_sent) {
                  toast.warn("Receipt Already sent!");
                  return;
                }
                handleSendReceipt(tableData[index]?._id, index);
              }}
            >
              {[null, undefined]?.includes(
                tableData[index]?.transaction_receipt_sent
              ) && (
                <>
                  <span className="me-2">Send</span>
                  <SendOutlinedIcon sx={{ fontSize: 15 }} />
                </>
              )}
              {tableData[index]?.transaction_receipt_sent === true && (
                <>
                  <span className="me-2">Sent</span>
                  <DoneIcon sx={{ fontSize: 15 }} />
                </>
              )}
              {tableData[index]?.transaction_receipt_sent === false && (
                <>
                  <span className="me-2">Failed</span>
                  <CloseIcon sx={{ fontSize: 15 }} />
                </>
              )}
            </Button>
          </>
        );
      },
      width: 130,
    },
  ]);

  if (Number(user?.role) === 3) {
    columns = columns.filter((item) => !columnsRemovedForNBFC?.[item?.label]);
  }

  const fetchTableData = async (
    page,
    limit,
    type,
    typeValue,
    search,
    pageChange
  ) => {
    if (pageChange) setPageLoading(true);
    else setLoading(true);
    try {
      let params = `&page=${page}`;
      if (limit) params += `&limit=${limit}`;
      if (type === "filter")
        params += `&filters=${encodeURIComponent(
          JSON.stringify(typeValue).toString()
        )}`;
      if (search) params += `&query=${search}`;

      let { data } = await fetchAllTransactionsV2(params);
      setTableData(data?.data?.transactions || []);
      if(!lastTrasactionData) setLastTrasactionData(data?.data?.transactions?.[0]);
      setCount(data?.data?.count);
    } catch (error) {}
    if (pageChange) setPageLoading(false);
    else setLoading(false);
  };

  const fetchTransactionData = async () => {
    try {
      const { data } = await BulkTransationData();
      const formattedData = data?.data.map((ele) => ({
        name: `${ele.key.split("/")[0]}-${moment
          .unix(parseInt(ele.key.split("/")[1]) / 1000)
          .format("MMMM D,YYYY")}`,
        file: ele.fileLink,
        result: ele?.result || { success: [], failed: [] },
      }));
      setTransactionData(formattedData);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchTransactionData();
    if (localStorage.getItem("transactionFilterV2")) {
      setFilterApplied(true);
      fetchTableData(
        1,
        50,
        "filter",
        JSON.parse(localStorage.getItem("transactionFilterV2"))
      );
    } else {
      localStorage.removeItem("transactionFilterV2");
      localStorage.removeItem(`selectDataTransactionV2`);
      localStorage.removeItem(`filterDataTransactionV2`);
      localStorage.removeItem(`reduceDataTransactionV2`);
      fetchTableData(1, 50);
    }
    return ()=>{
      setLastTrasactionData(null);
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleHistoryClickOpen = () => {
    setHistoryOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCollectionCSV(null);
  };

  const handleHistoryClickClose = () => {
    setHistoryOpen(false);
  };

  const handleSearch = (value) => {
    if (value !== "") {
      setSearch(true);
      setSearchTxt(value);
      fetchTableData(
        1,
        limit,
        localStorage.getItem("transactionFilterV2") ? "filter" : null,
        JSON.parse(localStorage.getItem("transactionFilterV2")),
        value
      );
    } else {
      setSearchTxt("");
      setSearch(false);
      fetchTableData(
        1,
        limit,
        localStorage.getItem("transactionFilterV2") ? "filter" : null,
        JSON.parse(localStorage.getItem("transactionFilterV2"))
      );
    }
  };

  const handleUploadCollectionData = async () => {
    setUploading(true);
    try {
      let payload = {
        csv: collectionCSV,
      };

      let { data } = await uploadCollectionCsvV2(payload, setProgress);
      setBulkUploadResponse({
        successArray: data?.data?.successArr,
        failedArray: data?.data?.failedArr,
        successCount: data?.successLen,
        failedCount: data?.failedLen,
        failedCsv: data?.failedCsvBase64,
        open: true,
      });
      setProgress(0);
      handleClose();
    } catch (error) {
      setProgress(0);
      setCollectionCSV(null);
      toast.error(error?.response?.data?.message);
    }
    setUploading(false);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleSampleCsv = () => {
    let headers = [
      `application_id,txn_date,txn_amount,payment_mode,txn_id,txn_utr`,
    ];
    function padEntry(entry, length) {
      return entry.padEnd(length, " ");
    }
    let paddedHeaders = headers.map((header) => {
      const columns = header.split(",");
      const paddedColumns = [
        padEntry(columns[0], 50), // application_id
        padEntry(columns[1], 50), // txn_date
        padEntry(columns[2], 50), // txn_amount
        padEntry(columns[3], 50), // payment_mode
        padEntry(columns[4], 75), // txn_id
        padEntry(columns[5], 75), // txn_utr
      ];
      return paddedColumns.join(",");
    });
    downloadFile({
      data: [...paddedHeaders, []].join("\n"),
      fileName: `Bulk-upload-sample-(${moment(new Date()).format(
        "DD-MM-YYYY"
      )})`,
      fileType: "text/csv",
    });
  };

  const handleTrnxDialog = () => {
    if (trnxDialog_app_id == "") {
      toast.error("Application ID can't be blank");
    } else {
      navigate(`/update-collection-v2/${trnxDialog_app_id}`, {
        state: {
          path: "/transactions-v2",
        },
      });
    }
  };

  const handleTrnxDialogModal = () => {
    setTrnxDialog(!trnxDialog);
  };

  const handleSendReceipt = async (id, i) => {
    try {
      setOverLoading({
        load: true,
        message: "Sending Payment Receipt",
      });
      let { data } = await sendPaymentReceipt(id);
      if (data?.status) {
        tableData[i] = {
          ...tableData[i],
          transaction_receipt_sent: true,
        };
        toast.success("Payment receipt sent successfully!");
      }
    } catch (error) {
      toast.error(
        error?.response.data?.message || error?.message || "Failed to send"
      );
    }
    setOverLoading({
      load: false,
      message: "",
    });
  };

  const changeApprovalStatus = async (tbData, ddVal, i) => {
    setLoadRow(i);
    let payload = {
      _id: tbData?._id,
      is_approved: ddVal,
    };
    try {
      let { data } = await changeApprovalStatusV2(payload);
      if (data?.status) {
        tableData[i] = {
          ...tableData[i],
          is_approved: ddVal,
        };
        setTableData(tableData);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to change status");
    }
    setLoadRow(null);
  };

  const handleDownloadReceipt = async (id) => {
    try {
      setOverLoading({
        load: true,
        message: "Generating Receipt",
      });
      // let response = await downloadTxnRecieptV2(id);
      window.open(`${baseURL}/common/get-receipt-v2/${id}`);
      // await base64ToPdf(data?.base64, fileName);
    } catch (error) {}
    setOverLoading({
      load: false,
      message: "",
    });
  };

  return (
    <>
      <div className="px-3">
        {/* {!isLoading && ( */}
        <div
          className="d-flex p-3 ps-2"
          style={{ justifyContent: "space-between" }}
        >
          <div
            className="fw-semibold d-flex flex-row align-items-center"
            style={{ fontSize: "12px", color: "#595C5F" }}
          >
            <span>
              Last txn date :{" "}
              {moment(lastTrasactionData?.transaction_dateMS).format("DD-MM-YYYY")}
              &nbsp;&nbsp;&nbsp;
            </span>
            <span>
              Updated on :{" "}
              {moment(lastTrasactionData?.updated_date).format(
                "DD-MM-YYYY hh:mm:ss A"
              )}{" "}
            </span>
          </div>

          <div className="d-flex" style={{ fontSize: "20px" }}>
            {Number(user?.role) !== 3 && (
              <Tooltip title="Upload Collection Data">
                <Button
                  className="me-3 pointer"
                  style={{ color: "gray" }}
                  onClick={() => handleClickOpen()}
                >
                  <FileUploadIcon className="me-1" />
                  Bulk Transaction Upload
                </Button>
              </Tooltip>
            )}
            {Number(user?.role) !== 3 && (
              <Tooltip title="Transaction History">
                <Button
                  className="me-2 pointer"
                  style={{ color: "gray" }}
                  onClick={() => handleHistoryClickOpen()}
                >
                  <HistoryIcon className="me-1" />
                  Transaction History
                </Button>
              </Tooltip>
            )}

            <Tooltip title="Export Collection Data">
              <Button
                className="me-3 pointer"
                style={{ color: "gray" }}
                onClick={() => {
                  setOpenExport(true);
                }}
              >
                <GetAppIcon className="me-1" />
                Export
              </Button>
            </Tooltip>

            {Number(user?.role) !== 3 && (
              <Tooltip title="Export Collection Data">
                <Button
                  className="me-3 pointer"
                  style={{ color: "gray" }}
                  onClick={() => {
                    setTrnxDialog(!trnxDialog);
                  }}
                >
                  + Add Transaction
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
        {/* )} */}

        <div
          className="mb-4 mx-2 d-flex flex-row justify-content-start"
          style={{ fontSize: "12px", color: "#595C5F" }}
        >
          <TextField
            className="search-bar-table"
            id="input-with-icon-textfield"
            placeholder="Search"
            variant="standard"
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: "gray" }} />,
              disableUnderline: true,
              style: { color: "black", fontWeight: 400, height: 25 },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(e.target.value);
              }
            }}
          />

          {!(filterApplied && localStorage.getItem("transactionFilterV2")) ? (
            <Button
              sx={{ color: "gray" }}
              className="mx-3"
              onClick={() => {
                setFilterApplied(true);
                setOpenFilter(true);
              }}
            >
              <FilterAltIcon /> filters
            </Button>
          ) : (
            <div className="d-flex flex-row align-items-center">
              <Button
                size="small"
                className="mx-3"
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                <span className="zoom-animation d-flex flex-row align-items-center">
                  <FilterAltIcon fontSize="small" /> <span>Filters</span>
                </span>
              </Button>
              <span
                className="pointer"
                onClick={() => {
                  localStorage.removeItem("transactionFilterV2");
                  localStorage.removeItem(`selectDataTransactionV2`);
                  localStorage.removeItem(`filterDataTransactionV2`);
                  localStorage.removeItem(`reduceDataTransactionV2`);
                  fetchTableData(1, 50, null, null, null, true);
                  setFilterApplied(false);
                  setOpenFilter(false);
                }}
              >
                <HighlightOffIcon style={{ fontSize: 20 }} />
              </span>
            </div>
          )}
        </div>
        <CustomTable
          tableHeight={"75vh"}
          tableData={tableData}
          columns={columns}
          defaultLimit={50}
          totalCount={totalCount}
          isLoading={isLoading}
          pageLoading={isPageLoading}
          allowAll={true}
          onPageChange={(page, limit) => {
            setLimit(limit);
            setPage(page);
            // if (isSearch) {
            //   fetchTableData(page, limit, "search", searchTxt);
            // } else {
            //   if (filter === "all") {
            //     fetchTableData(page, limit);
            //   } else {
            //     fetchTableData(page, limit, "filter", filter);
            //   }
            // }

            fetchTableData(
              page,
              limit,
              localStorage.getItem("transactionFilterV2") ? "filter" : null,
              JSON.parse(localStorage.getItem("transactionFilterV2")),
              isSearch ? searchTxt : null,
              true
            );
          }}
          onLimitChange={(page, limit) => {
            setLimit(limit);
            setPage(page);

            fetchTableData(
              page,
              limit,
              localStorage.getItem("transactionFilterV2") ? "filter" : null,
              JSON.parse(localStorage.getItem("transactionFilterV2")),
              isSearch ? searchTxt : null
            );
          }}
          onSortChange={(page, limit, sortValue, sortField) => {
            fetchTableData(page, limit, "sort", null, sortValue, sortField);
          }}
          onDoubleClick={(data) => {
            navigate(`/view-application/${data?.loanData?.application_id}/5`, {
              state: {
                path: `transactions-v2`,
              },
            });
          }}
        />
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        {!isUploading ? (
          <DialogContent>
            {collectionCSV === null ? (
              <div className="mb-5">
                <div className="d-flex justify-content-end mb-4">
                  <Button
                    onClick={() => {
                      handleSampleCsv();
                    }}
                  >
                    <FileDownloadIcon /> Sample
                  </Button>
                </div>
                <DragDrop
                  customType={["CSV"]}
                  setFile={(val, name) => {
                    setCollectionCSV(val);
                  }}
                />
              </div>
            ) : (
              <>
                <div
                  className="mt-2 d-flex"
                  style={{ width: "300px", justifyContent: "space-between" }}
                >
                  <div>
                    <InsertDriveFileIcon /> Collection Data
                  </div>
                  <CloseIcon
                    className="pointer"
                    onClick={() => {
                      setCollectionCSV(null);
                    }}
                  />
                </div>
              </>
            )}
          </DialogContent>
        ) : (
          <DialogContent>
            <div>
              <p className="text-center">
                <img
                  className="mt-5"
                  src={uploadAnimation}
                  width={200}
                  alt="uploading..."
                />
              </p>
              <ProgressBar
                className="mt-4"
                striped
                variant="success"
                label={`${progress.toFixed(2)}%`}
                now={progress}
              />
            </div>
          </DialogContent>
        )}
        {!isUploading && (
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleUploadCollectionData}>Submit</Button>
          </DialogActions>
        )}
      </Dialog>

      {/* add transaction dialog */}
      <Dialog
        open={trnxDialog}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div
            className="d-flex justify-content-center flex-column"
            style={{ width: "20em" }}
          >
            <p className="at-title">Add Transaction</p>
            <input
              type="text"
              className="at-input"
              placeholder="Enter Application ID"
              value={trnxDialog_app_id}
              onChange={(e) => {
                setTrnxDialog_app_id(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleTrnxDialog();
                } else if (e.key === "Escape") {
                  handleTrnxDialogModal();
                }
              }}
            />
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleTrnxDialogModal}>Cancel</Button>
          <Button onClick={handleTrnxDialog}>Submit</Button>
        </DialogActions>
      </Dialog>

      <BulkUploadStatus
        open={bulkUploadResponse?.open}
        successArray={bulkUploadResponse?.successArray}
        failedArray={bulkUploadResponse?.failedArray}
        successCount={bulkUploadResponse?.successCount}
        failedCount={bulkUploadResponse?.failedCount}
        failedCsv={bulkUploadResponse?.failedCsv}
        handleClose={() => {
          setBulkUploadResponse(initialStateBulkResponse);
        }}
      />

      {filterApplied && (
        <AdvancedSort
          name="TransactionV2"
          open={openFilter}
          handleClose={() => {
            setOpenFilter(false);
          }}
          onApply={(filter) => {
            localStorage.setItem("transactionFilterV2", JSON.stringify(filter));
            fetchTableData(1, limit, "filter", filter, null, true);
            setOpenFilter(false);
          }}
          options={(() => {
            if (Number(user?.role) === 3) {
              return [
                "application_id",
                "customer_name",
                "oem",
                "payment_mode",
                "transaction_date",
                "created_date",
              ];
            }
            return [
              "application_id",
              "customer_name",
              "nbfc",
              "oem",
              "payment_mode",
              "transaction_date",
              "created_date",
              "is_approved",
              "edited status",
            ];
          })()}
        />
      )}
      <FileViewer
        application_id={docViewer.application_id}
        open={docViewer?.open}
        url={docViewer?.url}
        title={docViewer?.title}
        data={{ ...docViewer?.data }}
        onClose={() => {
          setDocViewer(docViewInitial);
        }}
        reload={(status) => {
          setDocViewer({
            ...docViewer,
            data: {
              ...docViewer?.data,
              isApproved: status,
            },
          });
        }}
        mandate_id={""}
        isMulti={false}
        onDownload={() => {}}
        type="transactions"
        index="none"
      />
      {overLoading?.load && (
        <LoadingOver text={overLoading?.message || "Loading"} />
      )}

      <DownloadTable
        open={openExport}
        type={"transactions"}
        filterData={localStorage.getItem("transactionFilterV2") || null}
        handleClose={() => setOpenExport(false)}
      />

      <TransactionHistory
        open={historyOpen}
        handleClose={handleHistoryClickClose}
        data={transactionData}
      />
    </>
  );
}
