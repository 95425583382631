import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Card,
  CardContent,
  Typography,
  IconButton,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import React, { useContext } from "react";
import UploadSection from "../../../Components/Anchor/UploadSection";
import { GlobalContext } from "../../../Context/GlobalContext";
import { useEffect } from "react";
import {
  anchorCrifReport,
  anchorOCR,
  decryptData,
  fetchAllOems,
} from "../../../api";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AadhaarUpload from "../../../Components/Anchor/AadhaarUpload";
import { anchorDocs } from "../../../Constants/AnchorConstants";
import { toast } from "react-toastify";
import useAnchor from "../../../hooks/useAnchor";
import FilingStatusPopup from "../../../Components/Anchor/AnchorGstDetailsFilingStatusPopup.js";
import ReturnFilingStatusPopup from "../../../Components/Anchor/AnchorGstDetailsReturnFilingStatusPopup.js";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import { f, formatDate } from "../../../utility/Formater.js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReportIcon from "@mui/icons-material/Assessment";
import { getAnchorCrifColor } from "../../../utility/anchorUtils.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BasicDetails = ({
  isUpdate,
  handleLoading,
  isDisabled = false,
  isDownloadAble,
}) => {
  const { dealerDetailState, updateDocsState } = useContext(GlobalContext);
  const [dealerDetails, setDealerDetails] = dealerDetailState;
  const [updateDocs, setUpdateDocs] = updateDocsState;
  const { handleSingleDocReupload, handleSingleDocDelete, getDocArr } =
    useAnchor();
  const [swipeDocs, setSwipeDocs] = useState(null);
  const [oemList, setOemList] = useState([]);
  const [nbfcList, setNbfcList] = useState([]);
  const [newAnchorData, setNewAnchorData] = useState(false);
  const [assignedNbfcAnchor, setAssignedNbfcAnchor] = useState(0);
  const [assignedNbfcOem, setAssignedNbfcOem] = useState(0);
  const [nbfc, setNbfc] = useState(null);
  const [filingStatusOpen, setFilingStatusOpen] = useState(false);
  const [returnFilingStatusOpen, setReturnFilingStatusOpen] = useState(false);
  const [openCrifData, setOpenCrifData] = useState({
    open: false,
    loading: false,
  });
  const [crifData, setCrifData] = useState({});

  const handleClose = () => {
    setOpenCrifData({
      open: false,
      loading: false,
    });
  };

  const handleFilingStatusOpen = () => {
    setFilingStatusOpen(true);
  };

  const handleReturnFilingStatusOpen = () => {
    setReturnFilingStatusOpen(true);
  };

  const handleFilingStatusClose = () => {
    setFilingStatusOpen(false);
  };

  const handleReturnFilingStatusClose = () => {
    setReturnFilingStatusOpen(false);
  };

  const handleChange = (key) => (event) => {
    setDealerDetails({
      ...dealerDetails,
      [key]: event.target.value,
    });
  };

  const handleArrayDetailChange = (array_name, i, key, value) => {
    let array = [...dealerDetails?.[array_name]];
    array[i] = {
      ...array[i],
      [key]: value,
    };

    let gstObj = null;
    if (
      array_name == "oems" &&
      ["sb_type", "gst_type", "subvention_amount", "subvention_perc"]?.includes(
        key
      )
    ) {
      let sb_type =
        key == "sb_type" ? value : dealerDetails?.[array_name]?.[i]?.sb_type;
      let gst_type =
        key == "gst_type" ? value : dealerDetails?.[array_name]?.[i]?.gst_type;
      let subvention_amount =
        key == "subvention_amount"
          ? Number(value)
          : dealerDetails?.[array_name]?.[i]?.subvention_amount;
      let subvention_perc =
        key == "subvention_perc"
          ? Number(value)
          : dealerDetails?.[array_name]?.[i]?.subvention_perc;
      gstObj = calculateDFGst(
        sb_type,
        gst_type,
        subvention_amount,
        subvention_perc
      );
      array[i]["df_exclusive_amount"] = gstObj?.df_exclusive_amount;
      array[i]["df_gst_amount"] = gstObj?.df_gst_amount;
    }
    for (let j = i; j < array.length - 1; j++) {
      if (array?.[j + 1] && array?.[j + 1]?.commercial_same) {
        let updateQuery = {
          ...array[j + 1],
          [key]: value,
        };
        if (gstObj != null) {
          updateQuery["df_exclusive_amount"] = gstObj?.df_exclusive_amount;
          updateQuery["df_gst_amount"] = gstObj?.df_gst_amount;
        }
        array[j + 1] = updateQuery;
      }
    }
    setDealerDetails({
      ...dealerDetails,
      [array_name]: array,
    });
  };

  function calculateDFGst(
    sb_type,
    gst_type,
    subvention_amount,
    subvention_perc
  ) {
    let obj = { df_exclusive_amount: "", df_gst_amount: "" };
    if (sb_type == "AMOUNT") {
      if (gst_type == "Inclusive") {
        obj["df_exclusive_amount"] =
          Number(subvention_amount) -
          Math.round((Number(subvention_amount) * 18) / 100);
        obj["df_gst_amount"] = Math.round(
          (Number(subvention_amount) * 18) / 100
        );
      } else if (gst_type == "Exclusive") {
        obj["df_exclusive_amount"] = Number(subvention_amount);
        obj["df_gst_amount"] = Math.round(
          (Number(subvention_amount) * 18) / 100
        );
      }
    } else if (sb_type == "PERCENTAGE") {
      let sb_amount = Math.round((100000 * Number(subvention_perc)) / 100);
      if (gst_type == "Inclusive") {
        obj["df_exclusive_amount"] =
          Number(sb_amount) - Math.round((Number(sb_amount) * 18) / 100);
        obj["df_gst_amount"] = Math.round((Number(sb_amount) * 18) / 100);
      } else if (gst_type == "Exclusive") {
        obj["df_exclusive_amount"] = Number(sb_amount);
        obj["df_gst_amount"] = Math.round((Number(sb_amount) * 18) / 100);
      }
    }
    return obj;
  }

  const fetchOem = async () => {
    const { data } = await fetchAllOems();
    if (data?.status) {
      setOemList(data?.data);
    }
  };

  const handleCommercialSame = (i, status) => {
    let array = [...dealerDetails?.oems];
    array[i] = {
      ...array[i],
      commercial_same: status,
      interest_rate: status ? dealerDetails?.oems?.[i - 1]?.interest_rate : "",
      processing_fees: status
        ? dealerDetails?.oems?.[i - 1]?.processing_fees
        : "",
      processing_fees_percent: status
        ? dealerDetails?.oems?.[i - 1]?.processing_fees_percent
        : "",
      hold_back_per_file_amount: status
        ? dealerDetails?.oems?.[i - 1]?.hold_back_per_file_amount
        : "",
      hold_back_total_amount: status
        ? dealerDetails?.oems?.[i - 1]?.hold_back_total_amount
        : "",
      subvention_amount: status
        ? dealerDetails?.oems?.[i - 1]?.subvention_amount
        : "",
      subvention_perc: status
        ? dealerDetails?.oems?.[i - 1]?.subvention_perc
        : "",
      pf_type: status ? dealerDetails?.oems?.[i - 1]?.pf_type : "",
      sb_type: status ? dealerDetails?.oems?.[i - 1]?.sb_type : "",
      gps_charges: status ? dealerDetails?.oems?.[i - 1]?.gps_charges : "",
      gst_type: status ? dealerDetails?.oems?.[i - 1]?.gst_type : "",
      df_exclusive_amount: status
        ? dealerDetails?.oems?.[i - 1]?.df_exclusive_amount
        : "",
      df_gst_amount: status ? dealerDetails?.oems?.[i - 1]?.df_gst_amount : "",
    };
    setDealerDetails({
      ...dealerDetails,
      oems: array,
    });
  };

  const handleOCR = async (file) => {
    try {
      handleLoading(true, "Running OCR");
      let payload = {
        files: [file],
      };
      let { data } = await anchorOCR(payload);
      handleLoading(false, "");
      if (data?.success?.length > 0) {
        toast.success("OCR completed successfully");
        return {
          status: true,
          data: data?.success?.[0]?.response?.value || {},
        };
      } else {
        toast.error("OCR Failed");
        return {
          status: false,
        };
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
      handleLoading(false, "");
      return {
        status: false,
      };
    }
  };

  function getDocNumber(docs, isAadhaar) {
    if (docs?.length === 0) return 1;
    let index = -1;
    let number = 0;
    if (isAadhaar) {
      index =
        docs?.[docs?.length - 1]?.[`aadhaar_front`]?.name?.lastIndexOf("_");
      number = docs?.[docs?.length - 1]?.[`aadhaar_front`]?.name?.slice(
        index + 1
      );
    } else {
      index = docs?.[docs?.length - 1]?.name?.lastIndexOf("_");
      number = docs?.[docs?.length - 1]?.name?.slice(index + 1);
    }
    return Number(number) + 1;
  }

  useEffect(() => {
    if (!dealerDetails?.oems || dealerDetails?.oems?.length === 0) {
      setDealerDetails({ ...dealerDetails, oems: [{}] });
    }
  }, [dealerDetails?.oems]);

  const getDocumentsArray = () => getDocArr(0);

  useEffect(() => {
    fetchOem();
  }, []);

  async function runAnchorCrif() {
    try {
      handleLoading(true, "Fetching CRIF Report");
      let nameArray = dealerDetails?.name?.split(" ");
      let crifPayload = {
        firstName: nameArray?.[0],
        middleName: "",
        lastName:
          nameArray?.length > 1 ? nameArray?.[nameArray?.length - 1] : "",
        dob: dealerDetails?.dob,
        phone1:
          dealerDetails?.outlet_details?.[0]?.relation_manager_details?.[0]
            ?.mobile,
        kyc: {
          pan: dealerDetails?.pan_ocr_results?.success?.[0]?.response?.value
            ?.number || dealerDetails?.pan_details?.[0]?.number,
        },
        address: dealerDetails?.outlet_details?.[0]?.address,
        city: dealerDetails?.outlet_details?.[0]?.city,
        state: dealerDetails?.outlet_details?.[0]?.state,
        pin: dealerDetails?.outlet_details?.[0]?.pincode,
        country: dealerDetails?.outlet_details?.[0]?.country,
        consent: "Y",
      };
      const { data } = await anchorCrifReport({
        anchor_id: dealerDetails?.anchor_id,
        payload: crifPayload,
      });
      setDealerDetails((prev) => ({
        ...prev,
        credit_pull: data?.credit_pull,
      }));
    } catch (err) {
      setDealerDetails((prev) => ({
        ...prev,
        credit_pull: {
          message: "Internal Server Error",
        },
      }));
    } finally {
      handleLoading(false, "");
    }
  }

  async function viewCrifReport() {
    setOpenCrifData({
      open: true,
      loading: true,
    });
    try {
      let { data } = await decryptData({
        data: dealerDetails?.credit_pull?.crifData,
      });
      if (data?.status) {
        setCrifData(data?.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setOpenCrifData({ open: true, loading: false });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            required
            id="name"
            name="name"
            label="Dealer Name"
            autoComplete="family-name"
            variant="standard"
            fullWidth
            value={dealerDetails?.name || ""}
            onChange={handleChange("name")}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <FormControl
            variant="standard"
            sx={{ width: "100%" }}
            disabled={isDisabled}
          >
            <InputLabel required id="demo-simple-select-standard-label">
              Firm Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={dealerDetails?.type || ""}
              onChange={(e) => {
                setDealerDetails({
                  ...dealerDetails,
                  type: e.target.value,
                });
              }}
            >
              <MenuItem value={"Proprietorship"}>Proprietorship</MenuItem>
              <MenuItem value={"Partnership"}>Partnership</MenuItem>
              <MenuItem value={"LLP"}>LLP</MenuItem>
              <MenuItem value={"Pvt Ltd"}>Pvt Ltd</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="dealership_name"
            name="dealership_name"
            label="Dealership Name"
            autoComplete="family-name"
            variant="standard"
            fullWidth
            value={dealerDetails?.dealership_name || ""}
            onChange={handleChange("dealership_name")}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth"
              format="DD/MM/YYYY"
              value={
                dealerDetails?.dob
                  ? dayjs(
                      `${dealerDetails?.dob?.split("/")?.[1]}/${
                        dealerDetails?.dob?.split("/")?.[0]
                      }/${dealerDetails?.dob?.split("/")?.[2]}`
                    )
                  : null
              }
              onChange={(date) => {
                console.log(date);
                setDealerDetails((prev) => ({
                  ...prev,
                  dob: moment(date?.["$d"]).format("DD/MM/YYYY"),
                }));
              }}
              disabled={isDisabled}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: true,
                  fullWidth: true,
                },
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        {/* <Grid item xs={6}></Grid> */}
        <Grid item xs={6}>
          <TextField
            //   required
            id="gstin"
            name="gstin"
            label="GSTIN"
            autoComplete="family-name"
            variant="standard"
            fullWidth
            value={dealerDetails?.gstin || ""}
            disabled={isDisabled}
            onChange={handleChange("gstin")}
          />
        </Grid>
      </Grid>
      <UploadSection
        mt={10}
        category={"anchor_gstin_pdf"}
        docs={dealerDetails?.gst_files || []}
        onChange={(file, dataType) => {
          setDealerDetails({
            ...dealerDetails,
            gst_files: [
              ...(dealerDetails?.gst_files || []),
              {
                isUploaded: true,
                fileLink: file,
                name: `anchor_gstin_pdf_${getDocNumber(
                  dealerDetails?.gst_files || []
                )}`,
                dataType,
              },
            ],
          });
        }}
        onDelete={(file, i) => {
          setDealerDetails({
            ...dealerDetails,
            gst_files: [...dealerDetails?.gst_files].filter(
              (item, index) => index !== i
            ),
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={dealerDetails?.anchor_id}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        mt={20}
        heading={"Dealer Image"}
        category={"dealer_image"}
        docs={dealerDetails?.dealer_images || []}
        onChange={(file, dataType) => {
          setDealerDetails({
            ...dealerDetails,
            dealer_images: [
              ...(dealerDetails?.dealer_images || []),
              {
                isUploaded: true,
                fileLink: file,
                name: `anchor_dealer_image_${getDocNumber(
                  dealerDetails?.dealer_images || []
                )}`,
                dataType,
              },
            ],
          });
        }}
        onDelete={(file, i) => {
          setDealerDetails({
            ...dealerDetails,
            dealer_images: [...dealerDetails?.dealer_images].filter(
              (item, index) => index !== i
            ),
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={dealerDetails?.anchor_id}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        mt={20}
        heading={"Personal PAN Image"}
        category={"personal_pan"}
        docs={dealerDetails?.pan_images || []}
        isPan={true}
        // isEditable={!isUpdate}
        onChange={async (file, dataType) => {
          let stateData = {
            ...dealerDetails,
            pan_updated: true,
            pan_images: [
              ...(dealerDetails?.pan_images || []),
              {
                isUploaded: true,
                fileLink: file,
                name: `anchor_personal_pan_${getDocNumber(
                  dealerDetails?.pan_images || []
                )}`,
                dataType,
              },
            ],
          };

          if (dealerDetails?.update) {
            let response = await handleOCR(file);
            if (response?.status) {
              setUpdateDocs([
                ...updateDocs,
                {
                  id: Number(new Date()),
                  new: true,
                  documentFile: file,
                  category: "personal_pan",
                  fileName: anchorDocs?.["personal_pan"],
                  type: "personal_pan",
                },
              ]);

              stateData.pan_details = [
                ...(stateData?.pan_details || []),
                response?.data,
              ];
            } else {
              return false;
            }
          }
          setDealerDetails(stateData);
        }}
        onDelete={(file, i) => {
          let state = { ...dealerDetails };
          // if (state?.pan_ocr_results) {
          //   state.pan_ocr_results.all = [
          //     ...state?.pan_ocr_results.all,
          //   ].filter((item, index) => item?.index !== i);
          // }

          if (isUpdate) {
            state.pan_details.splice(i, 1);
          }

          setDealerDetails({
            ...state,
            pan_updated: true,
            pan_images: [...dealerDetails?.pan_images].filter(
              (item, index) => index !== i
            ),
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={dealerDetails?.anchor_id}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        mt={20}
        heading={"Company PAN Image"}
        category={"company_pan_image"}
        docs={dealerDetails?.company_pan_image || []}
        onChange={(file, dataType) => {
          setDealerDetails({
            ...dealerDetails,
            company_pan_image: [
              ...(dealerDetails?.company_pan_image || []),
              {
                isUploaded: true,
                fileLink: file,
                name: `anchor_company_pan_image_${getDocNumber(
                  dealerDetails?.company_pan_image || []
                )}`,
                dataType,
              },
            ],
          });
        }}
        onDelete={(file, i) => {
          setDealerDetails({
            ...dealerDetails,
            company_pan_image: [...dealerDetails?.company_pan_image].filter(
              (item, index) => index !== i
            ),
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={dealerDetails?.anchor_id}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />

      <AadhaarUpload
        mt={20}
        heading={"Aadhaar Image"}
        docs={dealerDetails?.aadhaar_images}
        onChange={(file) => {
          Object.keys(file)?.map((item) => {
            if (item === "aadhaar_front" || item === "aadhaar_back") {
              file[item] = {
                ...file[item],
                name: `anchor_${item}_${getDocNumber(
                  dealerDetails?.aadhaar_images,
                  true
                )}`,
              };
            }
          });
          setDealerDetails({
            ...dealerDetails,
            aadhaar_images: [...(dealerDetails?.aadhaar_images || []), file],
          });
        }}
        onDelete={(file, i) => {
          let state = {
            ...dealerDetails,
            aadhaar_images: [...dealerDetails?.aadhaar_images].filter(
              (item, index) => index !== i
            ),
          };
          setDealerDetails({
            ...state,
          });
        }}
        onAddMore={(e) => {
          let state = { ...dealerDetails };
          state?.aadhaar_images?.push({
            aadhaar_front: {},
            aadhaar_back: {},
          });
          setDealerDetails({ ...state });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={dealerDetails?.anchor_id}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />

      <UploadSection
        mt={20}
        heading={"Trade License"}
        category={"trade_license_pdf"}
        docs={dealerDetails?.trade_license || []}
        onChange={(file, dataType) => {
          setDealerDetails({
            ...dealerDetails,
            trade_license: [
              ...(dealerDetails?.trade_license || []),
              {
                isUploaded: true,
                fileLink: file,
                name: `anchor_trade_license_pdf_${getDocNumber(
                  dealerDetails?.trade_license || []
                )}`,
                dataType,
              },
            ],
          });
        }}
        onDelete={(file, i) => {
          setDealerDetails({
            ...dealerDetails,
            trade_license: [...dealerDetails?.trade_license].filter(
              (item, index) => index !== i
            ),
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={dealerDetails?.anchor_id}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
        supportingText={
          dealerDetails?.trade_license_details?.expiry_date
            ? f(dealerDetails?.trade_license_details?.expiry_date, "date")
            : ""
        }
      />

      <UploadSection
        mt={20}
        heading={"Shop Image"}
        category={"shop_image"}
        docs={dealerDetails?.shop_image || []}
        onChange={(file, dataType) => {
          setDealerDetails({
            ...dealerDetails,
            shop_image: [
              ...(dealerDetails?.shop_image || []),
              {
                isUploaded: true,
                fileLink: file,
                name: `anchor_shop_image_${getDocNumber(
                  dealerDetails?.shop_image || []
                )}`,
                dataType,
              },
            ],
          });
        }}
        onDelete={(file, i) => {
          setDealerDetails({
            ...dealerDetails,
            shop_image: [...dealerDetails?.shop_image].filter(
              (item, index) => index !== i
            ),
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={dealerDetails?.anchor_id}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      <UploadSection
        mt={20}
        heading={"Agreement"}
        category={"anchor_agreement"}
        docs={dealerDetails?.agreement || []}
        onChange={(file, dataType) => {
          setDealerDetails({
            ...dealerDetails,
            agreement: [
              ...(dealerDetails?.agreement || []),
              {
                isUploaded: true,
                fileLink: file,
                name: `anchor_agreement_${getDocNumber(
                  dealerDetails?.agreement || []
                )}`,
                dataType,
              },
            ],
          });
        }}
        onDelete={(file, i) => {
          setDealerDetails({
            ...dealerDetails,
            agreement: [...dealerDetails?.agreement].filter(
              (item, index) => index !== i
            ),
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={dealerDetails?.anchor_id}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />

      <UploadSection
        mt={20}
        heading={"Other Image"}
        category={"other_image"}
        docs={dealerDetails?.other_image || []}
        onChange={(file, dataType) => {
          setDealerDetails({
            ...dealerDetails,
            other_image: [
              ...(dealerDetails?.other_image || []),
              {
                isUploaded: true,
                fileLink: file,
                name: `anchor_other_image_${getDocNumber(
                  dealerDetails?.other_image || []
                )}`,
                dataType,
              },
            ],
          });
        }}
        onDelete={(file, i) => {
          setDealerDetails({
            ...dealerDetails,
            other_image: [...dealerDetails?.other_image].filter(
              (item, index) => index !== i
            ),
          });
        }}
        isEditable={!isDisabled}
        isDownloadAble={isDownloadAble}
        anchor_id={dealerDetails?.anchor_id}
        swipeArr={swipeDocs}
        getSwipeArr={getDocumentsArray}
        handleSingleDocReupload={handleSingleDocReupload}
        handleSingleDocDelete={handleSingleDocDelete}
      />
      {dealerDetails?.gst_details?.gstin != undefined ? (
        <>
          <Grid className="mt-3">
            <div
              style={{
                backgroundColor: "#F7F7F7",
                marginTop: 20,
                padding: 15,
                paddingBottom: 40,
                position: "relative",
              }}
            >
              GSTIN Details
              <div
                style={{
                  position: "absolute",
                  top: 10,
                  right: 0,
                  marginTop: 10,
                  marginRight: 10,
                }}
              >
                {dealerDetails?.gst_details?.gstnDetailed?.gstinStatus ===
                "ACTIVE" ? (
                  <>
                    <CheckCircleIcon sx={{ color: "green" }} />
                    Active
                  </>
                ) : (
                  <>
                    <DoDisturbAltIcon sx={{ color: "red" }} />
                    Suspended
                  </>
                )}
              </div>
              <div style={{ marginTop: 25 }}></div>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    id="registrationDate"
                    name="registrationDate"
                    label="Registration Date"
                    autoComplete="family-name"
                    variant="standard"
                    value={
                      dealerDetails?.gst_details?.gstnDetailed
                        ?.registrationDate || ""
                    }
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="legalName"
                    name="legalName"
                    label="Legal Name"
                    autoComplete="family-name"
                    variant="standard"
                    sx={{
                      width: "20%",
                      left: "0",
                    }}
                    value={
                      dealerDetails?.gst_details?.gstnDetailed
                        ?.legalNameOfBusiness || ""
                    }
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: 25 }}></div>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number"
                    autoComplete="family-name"
                    variant="standard"
                    type="number"
                    value={
                      dealerDetails?.gst_details?.gstnDetailed
                        ?.principalPlaceAddress?.mobile || ""
                    }
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="directorName"
                    name="directorName"
                    label="Director Name"
                    autoComplete="family-name"
                    variant="standard"
                    value={
                      dealerDetails?.gst_details?.gstnDetailed
                        ?.directorNames?.[0] || ""
                    }
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: 25 }}></div>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    id="firmType"
                    name="firmType"
                    label="Firm Type"
                    autoComplete="family-name"
                    variant="standard"
                    value={
                      dealerDetails?.gst_details?.gstnDetailed
                        ?.constitutionOfBusiness || ""
                    }
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="annualaggregateturnover"
                    name="annualaggregateturnover"
                    label="Annual Aggregate Turnover"
                    autoComplete="family-name"
                    variant="standard"
                    sx={{
                      width: "md",
                    }}
                    value={
                      dealerDetails?.gst_details?.annualAggregateTurnOver || ""
                    }
                    disabled={true}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  marginBottom: 15,
                  marginRight: 10,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 15 }}
                  onClick={handleFilingStatusOpen}
                >
                  Filing Status
                </Button>
                <FilingStatusPopup
                  isOpen={filingStatusOpen}
                  onClose={handleFilingStatusClose}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReturnFilingStatusOpen}
                >
                  Return Filing Status
                </Button>
                <ReturnFilingStatusPopup
                  isOpen={returnFilingStatusOpen}
                  onClose={handleReturnFilingStatusClose}
                />
              </div>
            </div>
          </Grid>
        </>
      ) : (
        <></>
      )}

      <Grid container spacing={2} className="mt-3">
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="demo-simple-select-standard-label">
              Hold Back
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={dealerDetails?.hold_back_type || ""}
              disabled={isUpdate ? true : isDisabled}
              onChange={(e) => {
                let state = { ...dealerDetails };
                if (e.target.value === "DEALER") {
                  state?.oems.forEach((obj) => {
                    if (obj?.hasOwnProperty("hold_back_per_file_amount")) {
                      delete obj.hold_back_per_file_amount;
                    }
                    if (obj?.hasOwnProperty("hold_back_total_amount")) {
                      delete obj.hold_back_total_amount;
                    }
                  });
                } else {
                  if (state?.anchor_hold_back_per_file_amount)
                    delete state.anchor_hold_back_per_file_amount;
                  if (state?.anchor_hold_back_total_amount)
                    delete state.anchor_hold_back_total_amount;
                }
                setDealerDetails({
                  ...state,
                  hold_back_type: e.target.value,
                });
              }}
            >
              <MenuItem value={"N/A"}>N/A</MenuItem>
              <MenuItem value={"DEALER"}>Anchor Level</MenuItem>
              <MenuItem value={"OEM"}>OEM level</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {dealerDetails?.hold_back_type === "DEALER" && (
          <>
            <Grid item xs={isDownloadAble ? 2.5 : 4}>
              <TextField
                id="apfhb"
                name="apfhb"
                label="Per file Hold Back Amount"
                autoComplete="family-name"
                variant="standard"
                fullWidth
                type={"number"}
                onWheel={(e) => e.target.blur()}
                value={dealerDetails?.anchor_hold_back_per_file_amount || ""}
                onChange={handleChange("anchor_hold_back_per_file_amount")}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={isDownloadAble ? 2.5 : 4}>
              <TextField
                id="thb"
                name="thb"
                label="Total Hold Back Amount"
                autoComplete="family-name"
                variant="standard"
                fullWidth
                type={"number"}
                onWheel={(e) => e.target.blur()}
                value={dealerDetails?.anchor_hold_back_total_amount || ""}
                onChange={handleChange("anchor_hold_back_total_amount")}
                disabled={isDisabled}
              />
            </Grid>
            {isDownloadAble && (
              <Grid
                item
                xs={3}
                className="d-flex flex-column align-items-end justify-content-end"
              >
                <TextField
                  id="thjnkb"
                  name="tnknhb"
                  label="Assigned Holdback"
                  autoComplete="family-name"
                  variant="outlined"
                  defaultValue={0}
                  size="small"
                  fullWidth
                  type={"text"}
                  onWheel={(e) => e.target.blur()}
                  value={dealerDetails?.anchor_hold_back_utilized || ""}
                  onChange={handleChange("anchor_hold_back_total_amount")}
                  disabled
                />
              </Grid>
            )}
            {isUpdate && (
              <>
                {dealerDetails?.hold_back_nbfc_limits.map((ele) => (
                  <Grid container spacing={4} className="mt-2">
                    <Grid item xs={2} style={{ paddingLeft: "50px" }}>
                      <TextField
                        label="NBFC ID"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        value={ele?.nbfc_id || ""}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Assigned Holdback"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        value={ele?.utilized || 0}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Settled HoldBack"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        value={ele?.settled_amount || 0}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="Collected Holdback"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        value={ele?.collected_amount || 0}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label="To Be Collected"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        value={
                          (ele?.utilized || 0) - (ele?.collected_amount || 0)
                        }
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                ))}
              </>
            )}
          </>
        )}
      </Grid>

      {dealerDetails?.oems?.map((oem, i) => {
        return (
          <div
            style={{
              backgroundColor: "#F7F7F7",
              marginTop: 20,
              padding: 15,
              paddingBottom: 40,
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {i !== 0 && !isDisabled && (
                <Button
                  className="remove-oem-btn"
                  onClick={(e) => {
                    let state = { ...dealerDetails };
                    state.oems = state.oems.filter(
                      (item, index) => index !== i
                    );
                    setDealerDetails(state);
                  }}
                >
                  <CloseIcon sx={{ fontSize: 17, mr: 0.5 }} /> Remove
                </Button>
              )}
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <FormControl variant="standard" fullWidth disabled={isDisabled}>
                  <InputLabel required id="demo-simple-select-standard-label">
                    OEM name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={dealerDetails?.oems?.[i]?.oem_id || ""}
                    onChange={(e) => {
                      const oemData = oemList?.find(
                        (item) => item?.oem_id === e.target.value
                      );
                      let array = [...dealerDetails?.oems];
                      array[i] = {
                        ...array[i],
                        oem_name: oemData?.company_name,
                        oem_id: e.target.value,
                      };
                      setDealerDetails({
                        ...dealerDetails,
                        oems: array,
                      });
                    }}
                  >
                    {oemList?.map((val) => {
                      return (
                        <MenuItem
                          value={val?.oem_id}
                          // onClick={() => {
                          //   handleArrayDetailChange(
                          //     "oems",
                          //     i,
                          //     "oem_id",
                          //     val?.oem_id
                          //   );
                          //   handleArrayDetailChange(
                          //     "oems",
                          //     i,
                          //     "oem_name",
                          //     val?.company_name
                          //   );
                          // }}
                        >
                          {val?.company_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}></Grid>

              <Grid item xs={12} className="mt-3">
                <span className="upload-section-heading">Battery type</span>
              </Grid>

              <Grid item xs={12}>
                <div className="d-flex">
                  <FormControlLabel
                    disabled={isDisabled}
                    control={
                      <Checkbox
                        defaultChecked={oem?.battery_type?.lithium_ion?.checked}
                        value={oem?.battery_type?.lithium_ion?.checked}
                        onChange={(e) => {
                          handleArrayDetailChange("oems", i, "battery_type", {
                            ...(oem?.battery_type || {}),
                            lithium_ion: {
                              ...(oem?.battery_type?.lithium_ion
                                ? oem?.battery_type?.lithium_ion
                                : {}),
                              name: "battery_type",
                              checked: e.target.checked,
                            },
                          });
                        }}
                      />
                    }
                    label="Lithium ion"
                    sx={{ width: "30%" }}
                  />
                  {oem?.battery_type?.lithium_ion?.checked && (
                    <TextField
                      //   required
                      id="mal"
                      name="mla"
                      label="Max. loan amount"
                      autoComplete="family-name"
                      variant="standard"
                      className="pb-3"
                      type={"number"}
                      onWheel={(e) => e.target.blur()}
                      value={oem?.battery_type?.lithium_ion?.amount}
                      onChange={(e) => {
                        handleArrayDetailChange("oems", i, "battery_type", {
                          ...(oem?.battery_type || {}),
                          lithium_ion: {
                            name: "lithium_ion",
                            checked: true,
                            amount:
                              e.target.value !== ""
                                ? Number(e.target.value)
                                : "",
                          },
                        });
                      }}
                      disabled={isDisabled}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} className="pt-0">
                <div className="d-flex">
                  <FormControlLabel
                    disabled={isDisabled}
                    control={
                      <Checkbox
                        value={oem?.battery_type?.lead_acid?.checked}
                        defaultChecked={oem?.battery_type?.lead_acid?.checked}
                        onChange={(e) => {
                          handleArrayDetailChange("oems", i, "battery_type", {
                            ...(oem?.battery_type || {}),
                            lead_acid: {
                              ...(oem?.battery_type?.lead_acid
                                ? oem?.battery_type?.lead_acid
                                : {}),
                              name: "lead_acid",
                              checked: e.target.checked,
                            },
                          });
                        }}
                      />
                    }
                    label="Lead acid"
                    sx={{ width: "30%" }}
                  />
                  {oem?.battery_type?.lead_acid?.checked && (
                    <TextField
                      //   required
                      id="mal"
                      name="mla"
                      label="Max. loan amount"
                      autoComplete="family-name"
                      variant="standard"
                      className="mb-3"
                      type={"number"}
                      onWheel={(e) => e.target.blur()}
                      value={oem?.battery_type?.lead_acid?.amount}
                      onChange={(e) => {
                        handleArrayDetailChange("oems", i, "battery_type", {
                          ...(oem?.battery_type || {}),
                          lead_acid: {
                            name: "lead_acid",
                            checked: true,
                            amount:
                              e.target.value !== ""
                                ? Number(e.target.value)
                                : "",
                          },
                        });
                      }}
                      disabled={isDisabled}
                    />
                  )}
                </div>
              </Grid>
              {i !== 0 && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // value={oem?.battery_type?.lead_acid?.checked}
                        // defaultChecked={oem?.battery_type?.lead_acid?.checked}
                        onChange={(e) => {
                          handleCommercialSame(i, e.target.checked);
                        }}
                        disabled={isDisabled}
                      />
                    }
                    label="Commercials Same as above"
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <TextField
                  //   required
                  id="rot"
                  name="rot"
                  label="Rate of Interest "
                  autoComplete="family-name"
                  variant="standard"
                  fullWidth
                  type={"number"}
                  onWheel={(e) => e.target.blur()}
                  value={oem?.interest_rate || ""}
                  onChange={(e) => {
                    handleArrayDetailChange(
                      "oems",
                      i,
                      "interest_rate",
                      e.target.value !== "" ? Number(e.target.value) : ""
                    );
                  }}
                  disabled={isDisabled || oem?.commercial_same}
                />
              </Grid>

              {dealerDetails?.hold_back_type === "OEM" && (
                <>
                  <>
                    <Grid item xs={12} className="mt-3">
                      <span className="upload-section-heading">Hold Back</span>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        //   required
                        id="pfhb"
                        name="pfhb"
                        label="Per file Hold Back Amount"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        type={"number"}
                        onWheel={(e) => e.target.blur()}
                        value={oem?.hold_back_per_file_amount || ""}
                        onChange={(e) => {
                          handleArrayDetailChange(
                            "oems",
                            i,
                            "hold_back_per_file_amount",
                            e.target.value !== "" ? Number(e.target.value) : ""
                          );
                        }}
                        disabled={oem?.commercial_same || isDisabled}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        //   required
                        id="thb"
                        name="thb"
                        label="Total Hold Back Amount"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        type={"number"}
                        onWheel={(e) => e.target.blur()}
                        value={oem?.hold_back_total_amount || ""}
                        onChange={(e) => {
                          handleArrayDetailChange(
                            "oems",
                            i,
                            "hold_back_total_amount",
                            e.target.value !== "" ? Number(e.target.value) : ""
                          );
                        }}
                        disabled={oem?.commercial_same || isDisabled}
                      />
                    </Grid>
                  </>
                  {isUpdate && (
                    <>
                      {oem?.hold_back_nbfc_limits?.map((ele) => (
                        <Grid container spacing={4} className="mt-2">
                          <Grid item xs={2} style={{ paddingLeft: "50px" }}>
                            <TextField
                              label="NBFC ID"
                              autoComplete="family-name"
                              variant="standard"
                              fullWidth
                              value={ele?.nbfc_id || ""}
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              label="Assigned Holdback"
                              autoComplete="family-name"
                              variant="standard"
                              fullWidth
                              value={ele?.utilized || 0}
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              label="Settled HoldBack"
                              autoComplete="family-name"
                              variant="standard"
                              fullWidth
                              value={ele?.settled_amount || 0}
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              label="Collected Holdback"
                              autoComplete="family-name"
                              variant="standard"
                              fullWidth
                              value={ele?.collected_amount || 0}
                              disabled={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              label="To Be Collected"
                              autoComplete="family-name"
                              variant="standard"
                              fullWidth
                              value={
                                (ele?.utilized || 0) -
                                (ele?.collected_amount || 0)
                              }
                              disabled={true}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                </>
              )}

              {dealerDetails?.hold_back_type === "DEALER" && (
                <>
                  <>
                    <Grid item xs={12} className="mt-3">
                      <span className="upload-section-heading">Hold Back</span>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        //   required
                        id="pfhb"
                        name="pfhb"
                        label="Per file Hold Back Amount"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        type={"number"}
                        onWheel={(e) => e.target.blur()}
                        value={
                          dealerDetails?.anchor_hold_back_per_file_amount || ""
                        }
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        //   required
                        id="thb"
                        name="thb"
                        label="Total Hold Back Amount"
                        autoComplete="family-name"
                        variant="standard"
                        fullWidth
                        type={"number"}
                        onWheel={(e) => e.target.blur()}
                        value={
                          dealerDetails?.anchor_hold_back_total_amount || ""
                        }
                        disabled={true}
                      />
                    </Grid>
                  </>
                </>
              )}

              <Grid item xs={12} sm={12}></Grid>

              <Grid item xs={4} sm={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Processing Fee
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={dealerDetails?.oems?.[i]?.pf_type || ""}
                    disabled={oem?.commercial_same || isDisabled}
                    onChange={(e) => {
                      handleArrayDetailChange(
                        "oems",
                        i,
                        "pf_type",
                        e.target.value
                      );
                    }}
                  >
                    <MenuItem value={"AMOUNT"}>PF Amount</MenuItem>
                    <MenuItem value={"PERCENTAGE"}>PF %</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {dealerDetails?.oems?.[i]?.pf_type === "AMOUNT" && (
                <Grid item xs={4}>
                  <TextField
                    //   required
                    id="pfa"
                    name="pfa"
                    label="Processing Fees Amount "
                    autoComplete="family-name"
                    variant="standard"
                    fullWidth
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={oem?.processing_fees || ""}
                    onChange={(e) => {
                      handleArrayDetailChange(
                        "oems",
                        i,
                        "processing_fees",
                        e.target.value
                      );
                    }}
                    disabled={oem?.commercial_same || isDisabled}
                  />
                </Grid>
              )}
              {dealerDetails?.oems?.[i]?.pf_type === "PERCENTAGE" && (
                <Grid item xs={4}>
                  <TextField
                    //   required
                    id="pfr"
                    name="pfr"
                    label="Processing Fees %"
                    autoComplete="family-name"
                    variant="standard"
                    fullWidth
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={oem?.processing_fees_percent || ""}
                    onChange={(e) => {
                      const inputValue =
                        e.target.value !== "" ? Number(e.target.value) : "";

                      if (inputValue <= 100) {
                        handleArrayDetailChange(
                          "oems",
                          i,
                          "processing_fees_percent",
                          inputValue
                        );
                      }
                    }}
                    disabled={oem?.commercial_same || isDisabled}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12}></Grid>

              <Grid item xs={4} sm={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Dealer Fees
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={dealerDetails?.oems?.[i]?.sb_type || ""}
                    disabled={oem?.commercial_same || isDisabled}
                    onChange={(e) => {
                      handleArrayDetailChange(
                        "oems",
                        i,
                        "sb_type",
                        e.target.value
                      );
                    }}
                  >
                    <MenuItem value={"AMOUNT"}>Dealer Fees</MenuItem>
                    <MenuItem value={"PERCENTAGE"}>Dealer Fees %</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {dealerDetails?.oems?.[i]?.sb_type === "AMOUNT" && (
                <Grid item xs={4}>
                  <TextField
                    //   required
                    id="subvention_amount"
                    name="subvention_amount"
                    label="Dealer Fees"
                    autoComplete="family-name"
                    variant="standard"
                    fullWidth
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={oem?.subvention_amount || ""}
                    onChange={(e) => {
                      handleArrayDetailChange(
                        "oems",
                        i,
                        "subvention_amount",
                        e.target.value !== "" ? Number(e.target.value) : ""
                      );
                    }}
                    disabled={oem?.commercial_same || isDisabled}
                  />
                </Grid>
              )}
              {dealerDetails?.oems?.[i]?.sb_type === "PERCENTAGE" && (
                <Grid item xs={4}>
                  <TextField
                    //   required
                    id="subvention_perc"
                    name="subvention_perc"
                    label="Dealer Fees %"
                    autoComplete="family-name"
                    variant="standard"
                    fullWidth
                    type={"number"}
                    onWheel={(e) => e.target.blur()}
                    value={oem?.subvention_perc || ""}
                    onChange={(e) => {
                      const inputValue =
                        e.target.value !== "" ? Number(e.target.value) : "";

                      if (inputValue <= 100) {
                        handleArrayDetailChange(
                          "oems",
                          i,
                          "subvention_perc",
                          inputValue
                        );
                      }
                    }}
                    disabled={oem?.commercial_same || isDisabled}
                  />
                </Grid>
              )}
              <Grid item xs={4} sm={4}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    GST Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={dealerDetails?.oems?.[i]?.gst_type || ""}
                    disabled={oem?.commercial_same || isDisabled}
                    onChange={(e) => {
                      handleArrayDetailChange(
                        "oems",
                        i,
                        "gst_type",
                        e.target.value
                      );
                    }}
                  >
                    <MenuItem value={"Inclusive"}>Inclusive</MenuItem>
                    <MenuItem value={"Exclusive"}>Exclusive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}></Grid>
              <Grid item xs={4}>
                <TextField
                  //   required
                  id="gps"
                  name="gps"
                  label="GPS Charges "
                  autoComplete="family-name"
                  variant="standard"
                  fullWidth
                  type={"number"}
                  onWheel={(e) => e.target.blur()}
                  value={oem?.gps_charges || ""}
                  onChange={(e) => {
                    handleArrayDetailChange(
                      "oems",
                      i,
                      "gps_charges",
                      e.target.value
                    );
                  }}
                  disabled={oem?.commercial_same || isDisabled}
                />
              </Grid>
            </Grid>
            {dealerDetails?.oems?.[i]?.sb_type &&
              dealerDetails?.oems?.[i]?.gst_type && (
                <>
                  <h6
                    style={{
                      color: "#801AE6",
                      fontStyle: "italic",
                      marginTop: "20px",
                    }}
                  >
                    {dealerDetails?.oems?.[i]?.sb_type == "AMOUNT"
                      ? "Fixed amount of dealer fee per file"
                      : "Example - If loan amount is 1 lakh"}
                  </h6>
                  <div>
                    <TableContainer
                      component={Paper}
                      sx={{
                        width: "300px",
                      }}
                    >
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">Exclusive</TableCell>
                            <TableCell align="right">
                              {dealerDetails?.oems?.[i]?.df_exclusive_amount ||
                                0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">GST Amount</TableCell>
                            <TableCell align="right">
                              {dealerDetails?.oems?.[i]?.df_gst_amount || 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">Inclusive Total</TableCell>
                            <TableCell align="right">
                              {dealerDetails?.oems?.[i]?.df_exclusive_amount +
                                dealerDetails?.oems?.[i]?.df_gst_amount || 0}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <table>
                <tbody>
                  <tr>
                    <td>Exclusive</td>
                    <td>{dealerDetails?.oems?.[i]?.df_exclusive_amount}</td>
                  </tr>
                  <tr>
                    <td>GST Amount</td>
                    <td>{dealerDetails?.oems?.[i]?.df_gst_amount}</td>
                  </tr>
                  <tr>
                    <td>Inclusive Total</td>
                    <td>
                      {dealerDetails?.oems?.[i]?.df_exclusive_amount +
                        dealerDetails?.oems?.[i]?.df_gst_amount}
                    </td>
                  </tr>
                </tbody>
              </table> */}
                  </div>
                </>
              )}
          </div>
        );
      })}
      {!isDisabled && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Button
              className="mt-1"
              disabled={
                typeof dealerDetails?.oems?.[dealerDetails?.oems?.length - 1]
                  ?.oem_id !== "string"
              }
              onClick={(e) => {
                if (
                  typeof dealerDetails?.oems?.[dealerDetails?.oems?.length - 1]
                    ?.oem_id === "string"
                ) {
                  setDealerDetails({
                    ...dealerDetails,
                    oems: [...dealerDetails?.oems, {}],
                  });
                }
              }}
            >
              <AddIcon /> Add more OEM
            </Button>
          </Grid>
        </Grid>
      )}
      {dealerDetails?.createdAt >= new Date("4-9-2024") &&(<Grid container spacing={2}>
        <Grid item xs={12} sm={12}></Grid>
        <Grid item xs={6} sm={6}>
          <Card
            sx={{
              maxWidth: 450,
              m: 2,
              backgroundColor: getAnchorCrifColor(
                Number(dealerDetails?.credit_pull?.score)
              )?.background,
            }}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.secondary"
                gutterBottom
              >
                Credit Report
                <IconButton
                  sx={{
                    float: "right",
                  }}
                  aria-label="re-run"
                  onClick={runAnchorCrif}
                >
                  <RefreshIcon />
                </IconButton>
                {/* <RefreshIcon sx={{ float: "right" }} />
                <span style={{ float: "right", marginRight: "10px" }}>
                  Re-Run
                </span> */}
              </Typography>
              <Typography variant="body2" color="primary">
                Remark: {f(dealerDetails?.credit_pull?.message || "")}
              </Typography>
              <Typography variant="h6" component="div">
                Score:{" "}
                <div
                  style={{
                    color: getAnchorCrifColor(
                      Number(dealerDetails?.credit_pull?.score)
                    )?.text,
                    backgroundColor: getAnchorCrifColor(
                      Number(dealerDetails?.credit_pull?.score)
                    )?.background,
                    display: "inline-block",
                    borderRadius: "5px",
                    paddingLeft: "3px",
                    paddingRight: "3px",
                  }}
                >
                  <b>{dealerDetails?.credit_pull?.score ?? "N/A"}</b>
                </div>
              </Typography>
            </CardContent>
            <Button
              variant="outlined"
              startIcon={<ReportIcon />}
              sx={{ m: 1 }}
              disabled={
                dealerDetails?.credit_pull?.message !=
                "CRIF fetched successfully"
                  ? true
                  : false
              }
              onClick={viewCrifReport}
            >
              VIEW REPORT
            </Button>
          </Card>
        </Grid>
      </Grid>)}
      <Dialog
        open={openCrifData?.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>Crif Report</DialogTitle>
        <DialogContent>
          {openCrifData?.loading ? (
            <p>
              {" "}
              <CircularProgress size={12} /> Loading
            </p>
          ) : (
            <pre>{JSON.stringify(crifData, null, 2)}</pre>
          )}
          {/* <ReactJson
            src={crifData}
            collapsed={false}
            displayDataTypes={false}
            displayArrayKey={false}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BasicDetails;
