import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Tooltip
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "../Components/CustomTable/CustomTable";
import {
  baseURL,
  fetchOtherCollections,
  getAllOtherTransactions,
  getOtherTransactions,
} from "../api";
// import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import GetAppIcon from "@mui/icons-material/GetApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CountUp from "react-countup";
import { toast } from "react-toastify";
import OtherTransactionsPopup from "../Components/OtherCollections/OtherTransactionsPopup";
import PastTransactions from "../Components/OtherCollections/PastTransactions";
import OtherCollectionsFilter from "../Components/Sort/OtherCollectionsFilter";
import { UserContext } from "../Context/UserContext";
import { capitalize, f } from "../utility/Formater";
import OtherCollectionPopup from "./Tables/OtherCollections/OtherCollectionPopup";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const columnsRemovedForNBFC = {
  "Application Date": 1,
  NBFC: 1,
  Status: 1,
  // "Overall Status":1,
  Stage: 1,
  agent: 1,
};

function OtherCollections() {
  const [tableData, setTableData] = useState([]);
  const [totalCount, setCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const navigate = useNavigate();
  const [filter, setFilter] = useState("all");
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [openTransactions, setOpenTransactions] = useState({
    open: false,
    collectionData: null,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const openExportMenu = Boolean(anchorEl);

  const filterDialogRef = useRef();
  const [tableTotal, setTableTotal] = useState({
    total_expected: 0,
    total_recieved: 0,
    total_pending: 0,
  });

  const [filterApplied, setFilterApplied] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openPrevTrans, setOpenPrevTrans] = useState({
    open: false,
    data: null,
  });
  const [options, setOptions] = useState([]);
  const [filteredStr, setFilteredStr] = useState("");

  const { user, setUser } = useContext(UserContext);

  const handleClickExportMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseExportMenu = () => {
    setAnchorEl(null);
  };

  function handlePageChange(page, limit, isLimit) {
    if (page) {
      setPage(page);
    } else if (!!isLimit) {
      setPage(1);
    }
    let type = localStorage.getItem("otherCollectionFilter") ? "filter" : null;
    setLimit(limit);
    fetchTableData(
      page,
      limit,
      type,
      JSON.parse(localStorage.getItem("otherCollectionFilter")) || null,
      localStorage.getItem("otherCollectionSearch") || null,
      true
    );
  }

  async function handleExports(type, mode) {
    let url = `${baseURL}`;
    if (type === "transaction" && mode === "all") {
      const { data } = await getOtherTransactions();
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `other-transactions.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
    if (type === "collection" && mode === "all") {
      let params = `exportData=true`;
      const { data } = await fetchOtherCollections(params);
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `other-collections.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
    if (type === "collection" && mode === "filter") {
      let filter = localStorage.getItem("otherCollectionSearch")
        ? "search"
        : localStorage.getItem("otherCollectionFilter")
        ? "filters"
        : null;
      let filterData =
        localStorage.getItem("otherCollectionSearch") ||
        localStorage.getItem("otherCollectionFilter") ||
        null;
      if (!filterData) return toast.error("filter not applied");
      let params = `${filter}=${filterData}&exportData=true`;
      const { data } = await fetchOtherCollections(params);
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `other-collections.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  let columns = [
    {
      label: "Application Id",
      value: "application_id",
      copy: true,
      width: 200,
    },
    {
      label: "Name",
      value: "customer_name",
      width: 200,
      // left: 65,
    },
    {
      label: "Dealer name",
      render: (i) => {
        return f(tableData[i]?.dealer_name);
      },
      value: "dealer_name",
      width: 200,
      freeze: true,
    },
    {
      label: "Collection Type",
      render: (i) => {
        let cltnType =
          tableData?.[i]?.collection_type === "subvention"
            ? "dealer_fees"
            : tableData?.[i]?.collection_type;
        return f(capitalize(cltnType, "_"));
      },
      width: 220,
      freeze: true,
      left: 220,
    },
    {
      label: "Amount Pending",
      value: "amount_pending",
      render: (i) => {
        return f(tableData[i].amount_pending, "cur");
      },
      width: 140,
    },
    {
      label: "Amount Received",
      render: (i) => {
        return f(tableData[i]?.amount_received, "cur");
      },
      value: "amount_received",
      width: 140,
    },
    {
      label: "Amount Expected",
      value: "amount_expected",
      render: (i) => {
        return f(tableData[i]?.amount_expected, "cur");
      },
      width: 140,
    },
    {
      label: "Status",
      render: (i) => {
        let res = f(capitalize(tableData?.[i]?.status, "_"));
        let resColor =
          res === "Paid" ? "success" : res === "Unpaid" ? "error" : "warning";
        return (
          <Chip
            variant="outlined"
            sx={{ minWidth: 100 }}
            size="small"
            label={res}
            color={resColor}
          />
        );
      },
      value: "status",
      width: 140,
    },
    {
      label: "DO uploaded date",
      render: (i) => {
        return f(tableData[i]?.do_uploaded_date, "date");
      },
      value: "do_uploaded_date",
      width: 120,
    },
    {
      label: "Disbursal Date",
      render: (i) => {
        return f(tableData[i]?.disbursed_date, "date");
      },
      value: "disbursed_date",
      width: 120,
    },
    {
      label: "Stage of the file",
      render: (i) => {
        return f(tableData[i]?.file_stage?.split("_")?.join(" "));
      },
      value: "file_stage",
      width: 200,
    },
    { label: "NBFC", value: "nbfc_name", width: 200 },
  ];

  columns = columns.concat([
    {
      label: "Invoice",
      render: (index) => {
        let fileLink =
          tableData?.[index]?.dealerFeesInvoiceData?.fileLink || null;
        return (
          <Button
            disabled={!fileLink}
            onClick={() => {
              window.open(`${fileLink}&file=true`);
            }}
          >
            <FileDownloadIcon />
          </Button>
        );
      },
      width: 80,
    },
    {
      label: "Add transaction",
      render: (index) => {
        return (
          <Button
            variant="contained"
            disableElevation
            style={{ fontSize: "10px" }}
            onClick={() => {
              getAllTransactions(tableData?.[index]);
            }}
            startIcon={<AddIcon />}
          >
            Add transaction
          </Button>
        );
      },
      width: 200,
    },
  ]);

  if (Number(user.role) === 3) {
    columns = columns.filter((item) => !columnsRemovedForNBFC?.[item?.label]);
  }

  const fetchTableData = async (
    page,
    limit,
    type,
    typeValue,
    search,
    pageChange
  ) => {
    if (pageChange) setPageLoading(true);
    else setLoading(true);
    try {
      let params = `&page=${page}`;
      if (limit) params += `&limit=${limit}`;
      if (type === "filter")
        params += `&filters=${encodeURIComponent(
          JSON.stringify(typeValue).toString()
        )}`;
      if (search) params += `&search=${search}`;

      let { data } = await fetchOtherCollections(params);
      setTableData(data?.result || []);
      setTableTotal({
        total_expected: data?.total_expected,
        total_pending: data?.total_pending,
        total_pending_file_count: data?.total_pending_count,
        total_recieved: data?.total_received,
      });
      setCount(data?.total_count);
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    }
    if (pageChange) setPageLoading(false);
    else setLoading(false);
  };

  const IntialDataFetch = () => {
    if (localStorage.getItem("otherCollectionFilter")) {
      setFilterApplied(true);
      fetchTableData(
        page ?? 1,
        50,
        "filter",
        JSON.parse(localStorage.getItem("otherCollectionFilter"))
      );
    } else {
      fetchTableData(page ?? 1, 50);
    }
  };

  useEffect(() => {
    IntialDataFetch();
    getFilterdFields();
  }, []);

  useEffect(() => {
    let filterData =
      JSON.parse(localStorage.getItem("filterDataOtherCollections")) || [];
    const index = filterData.findIndex(
      (item) => item.field === "overall_status"
    );

    if ((filterData?.[index]?.values || []).length === 1)
      setFilter(filterData?.[index]?.values?.[0]);
    else if ((filterData?.[index]?.values || []).length === 5) setFilter("all");
    else setFilter(null);

    if (index < 0) setFilter("all");
  }, [localStorage.getItem("filterDataOtherCollections")]);

  let timeOut;

  const handleSearch = (value) => {
    if (value !== "") {
      setSearch(true);
      fetchTableData(1, limit, null, null, value, false);
      localStorage.setItem("otherCollectionSearch", value);
      localStorage.removeItem("otherCollectionFilter");
      setFilterApplied(false);
    } else {
      setSearchTxt("");
      setSearch(false);
      fetchTableData(
        1,
        limit,
        localStorage.getItem("otherCollectionFilter") ? "filter" : null,
        JSON.parse(localStorage.getItem("otherCollectionFilter"))
      );
    }
  };

  function getFilterdFields() {
    let filteredFields = JSON.parse(
      localStorage.getItem(`filterDataOtherCollections`)
    );
    if (!filteredFields?.length) return "";

    let addPlus = (arr, idx) => (arr?.length - 1 === idx ? "" : " + ");

    let outputString = "", dealers = "", collection_type = "", status = "";

    filteredFields.map((item, i) => {
      if(["other_collection_type","dealer","status"].includes(item?.field)){
        item?.value?.map((itm, idx) => {
          if (item?.field === "other_collection_type") {
            collection_type +=
              itm?.[item?.field]?.name + addPlus(item?.value, idx);
          } else if (item?.field === "dealer") {
            dealers += itm?.[item?.field]?.name + addPlus(item?.value, idx);
          } else if (item?.field === "status") {
            status += itm?.[item?.field]?.name + addPlus(item?.value, idx);
          }
        });
      }
      });
      outputString += Boolean(collection_type) ? collection_type + " , " : "";
      outputString += Boolean(dealers) ? dealers + " , " : "";
      outputString += Boolean(status) ? status : "";
  
      setFilteredStr(outputString);
      // return <span className="mx-2 d-grid place-items-center" style={{fontSize:"12px",color:"#1976D2",fontWeight:500 }}>{(outputString += dealers)}</span>;
    }


  async function getAllTransactions(data) {
    try {
      let params = `application_id=${data?.application_id}&transaction_type=${data?.collection_type}`;
      const { data: res } = await getAllOtherTransactions(params);
      setOptions(res?.transactions || []);
      setOpenTransactions({
        open: true,
        collectionData: data,
        options: res?.transactions,
      });
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    if (!filterApplied) {
      localStorage.removeItem("otherCollectionFilter");
    }
  }, [filterApplied]);

  useEffect(() => {
    if (!isSearch) {
      localStorage.removeItem("otherCollectionSearch");
    }
  }, [isSearch]);

  useEffect(() => {
    if (tableData?.length) {
      let { application_id, collection_type } =
        openTransactions?.collectionData || {};
      let transact = tableData?.filter((item) => {
        return (
          item?.application_id === application_id &&
          item?.collection_type === collection_type
        );
      });
      setOpenTransactions((prev) => ({
        ...prev,
        collectionData: transact[0],
      }));
    }
  }, [tableData]);

  return (
    <>
      <div className="px-3">
        <Grid container spacing={2} className="mb-3 pt-3">
          <Grid item xs={12} sm={2}>
            <div className="over-view-card border">
              <p className="head">Total Expected</p>
              <p className="amount">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={tableTotal?.total_expected} />
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className="over-view-card border">
              <p className="head">Total Received</p>
              <p className="amount">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={tableTotal?.total_recieved} />
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className="over-view-card border">
              <p className="head">Total Pending</p>
              <p className="amount">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={tableTotal?.total_pending} />
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}>
            <div className="over-view-card border">
              <p className="head">Total Pending Files</p>
              <p className="amount">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <CountUp end={tableTotal?.total_pending_file_count} />
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={2} className="d-flex flex-row-reverse">
            <div>
              <Tooltip title="Export Other Collection Data">
                <Button
                  className="me-3 pointer"
                  variant="outlined"
                  onClick={handleClickExportMenu}
                  disabled={isLoading}
                >
                  <GetAppIcon className="me-1" />
                  Export
                </Button>
              </Tooltip>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openExportMenu}
                onClose={handleCloseExportMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  divider
                  onClick={() => {
                    handleCloseExportMenu();
                    setOpenExport(true);
                  }}
                >
                  Collection
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseExportMenu();
                    handleExports("transaction", "all");
                  }}
                >
                  Transaction
                </MenuItem>
              </Menu>
            </div>
          </Grid>
        </Grid>

        <div className="d-flex p-3" style={{ justifyContent: "space-between" }}>
          <div className="d-flex flex-row align-items-center">
            <TextField
              className="search-bar-table me-2 other-collection-searchbar"
              id="input-with-icon-textfield"
              placeholder="Search  Application Id/ Customer Name / Transaction ID"
              variant="standard"
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "gray" }} />,
                disableUnderline: true,
                style: {
                  color: "black",
                  fontSize: "14px",
                  fontWeight: 400,
                  height: 25,
                  width: 400,
                },
              }}
              value={searchTxt}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(e.target.value);
                }
              }}
              onChange={(e) => {
                setSearchTxt(e.target.value);
              }}
            />
            {!(
              filterApplied &&
              localStorage.getItem(`filterDataOtherCollections`)
            ) ? (
              Number(user?.role) !== 3 && (
                <Button
                  sx={{ color: "gray", p: 0, m: 0 }}
                  onClick={() => {
                    setFilterApplied(true);
                    setOpenFilter(true);
                  }}
                >
                  <FilterAltIcon fontSize="small" /> Filters
                </Button>
              )
            ) : (
              <div
                style={{ paddingRight: "15px" }}
                className="d-flex flex-row align-items-center"
              >
                <Button
                  size="small"
                  onClick={() => {
                    setFilterApplied(true);
                    setOpenFilter(true);
                  }}
                >
                  <span className="zoom-animation d-flex flex-row align-items-center">
                    <FilterAltIcon fontSize="small" /> <span>Filters</span>
                  </span>
                </Button>
                <span
                  className="ms-3 pointer d-flex flex-row align-items-center"
                  onClick={() => {
                    localStorage.removeItem(`selectDataOtherCollections`);
                    localStorage.removeItem(`filterDataOtherCollections`);
                    localStorage.removeItem(`reduceDataOtherCollections`);
                    localStorage.removeItem("otherCollectionFilter");
                    fetchTableData(1, 50, null, null, null, true);
                    setFilterApplied(false);
                    setOpenFilter(false);
                    setFilteredStr("");
                  }}
                >
                  <HighlightOffIcon style={{ fontSize: 20 }} />
                </span>
                {Boolean(filteredStr) && (
                  <span
                    className="mx-2 d-grid place-items-center"
                    style={{
                      fontSize: "12px",
                      color: "#1976D2",
                      fontWeight: 500,
                    }}
                  >
                    {filteredStr}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <hr className="border-secondary-subtle" />
        <CustomTable
          tableData={tableData}
          tableHeight="65vh"
          columns={columns}
          defaultLimit={50}
          totalCount={totalCount}
          isLoading={isLoading}
          // serialNo={true}
          // stickSL={true}
          allowAll={true}
          pageLoading={isPageLoading}
          onPageChange={handlePageChange}
          onLimitChange={(page, limit) =>
            handlePageChange(page, limit, "limit")
          }
        />

        {/* Filter Modal */}
        <OtherCollectionsFilter
          open={openFilter}
          ref={filterDialogRef}
          filterApplied={filterApplied}
          name={"OtherCollections"}
          handleClose={() => setOpenFilter(false)}
          onApply={(filter) => {
            localStorage.setItem(
              "otherCollectionFilter",
              JSON.stringify(filter)
            );
            localStorage.removeItem("otherCollectionSearch");
            fetchTableData(1, limit, "filter", filter, null, true);
            setOpenFilter(false);
            setSearch(false);
            setSearchTxt("");
            getFilterdFields();
          }}
          options={[
            "dealer",
            "status",
            "stage",
            "other_collection_type",
            "nbfc",
            "do_uploaded_date",
            "disbursed_date",
          ]}
        />

        <OtherCollectionPopup
          open={openExport}
          header={"Export Collections"}
          handleClose={() => setOpenExport(false)}
          handleDownload={(filterType) => {
            if (filterType === "download_all") {
              handleExports("collection", "all");
            }

            if (filterType === "download_with_current_filter") {
              handleExports("collection", "filter");
            }

            setOpenExport(false);
          }}
        />
        <OtherTransactionsPopup
          open={openTransactions?.open}
          collectionData={openTransactions?.collectionData}
          options={openTransactions?.options}
          handleClose={() => {
            setOpenTransactions({
              open: false,
              collectionData: null,
              options: [],
            });
          }}
          openPrevTrans={openPrevTrans}
          setOpenPrevTrans={setOpenPrevTrans}
          handlePageChange={() => {
            handlePageChange(page, limit);
          }}
        />

        <PastTransactions
          data={openPrevTrans}
          tableData={options}
          handleClose={() => {
            setOpenPrevTrans({
              open: false,
              data: null,
            });
          }}
          getAllTransactions={getAllTransactions}
          getCollections={() => {
            handlePageChange(page, limit);
          }}
        />
      </div>
    </>
  );
}

export default OtherCollections;
