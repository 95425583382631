import { Button, InputAdornment, TextField } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import {
  fetchAllAnchorsTable,
  fetchAllApplications2,
  fetchUsersTable,
} from "../../api";
import CustomTable from "../../Components/CustomTable/CustomTable";
import SearchIcon from "@mui/icons-material/Search";
import {
  Approved,
  Closed,
  Disbursed,
  Pending,
  Rejected,
} from "../../Components/StatusChip";
import SideNavBar from "../../Components/SideNavBar/SideNavBar";
import { useNavigate } from "react-router-dom";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

export const UserTable = () => {
  const [tableData, setTableData] = useState([]);
  const [totalCount, setCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState("all");
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);

  const handleFilter = (event, value) => {
    setFilter(value);
    if (value === "all") {
      fetchTableData(1, 100);
    }
    fetchTableData(1, 100, "filter", value);
  };

  const ToggleButtons = () => {
    return (
      <div>
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilter}
          aria-label="text alignment"
          size="small"
          className="toggle-btn-holder"
        >
          <ToggleButton
            value="all"
            aria-label="left aligned"
            className="toggle-btn"
          >
            All
          </ToggleButton>
          <ToggleButton
            value="user_embifi"
            aria-label="centered"
            className="toggle-btn"
          >
            Embifi
          </ToggleButton>
          <ToggleButton
            value="user_nbfc"
            aria-label="right aligned"
            className="toggle-btn"
          >
            Nbfc
          </ToggleButton>
          <ToggleButton
            value="user_oem"
            aria-label="right aligned"
            className="toggle-btn"
          >
            OEM
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  };

  let columns = [
    { label: "User ID", value: "user_id", copy: true, width: 200 },
    {
      label: "User Name",
      value: "name",
      width: 220,
    },

    {
      label: "User Type",
      value: "user_type",
      width: 120,
    },
    {
      label: "Role",
      value: "role",
      width: 120,
    },
    {
      label: "Email",
      value: "email",
      width: 220,
      copy: true,
    },
  ];

  columns = columns.concat([
    {
      label: "Actions",
      align: "right",
      render: (index) => {
        return (
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => {
                // navigate(`/update-anchor/${tableData[index]?.anchor_id}`, {
                //   state: {
                //     path: `/anchors`,
                //   },
                // });
              }}
              sx={{ fontSize: "12px" }}
            >
              <ModeEditIcon sx={{ fontSize: "12px", mr: 1 }} /> Edit
            </Button>
          </div>
        );
      },
      width: 200,
    },
  ]);

  const fetchTableData = async (
    page,
    limit,
    type,
    typeValue,
    sortType,
    sortField
  ) => {
    setLoading(true);
    try {
      let params = `&page=${page}`;
      if (limit) params += `&limit=${limit}`;

      switch (type) {
        case "filter":
          params += `&type=filter&filter=${typeValue}`;
          break;
        case "search":
          params += `&type=search&param=${typeValue}`;
          break;
        case "sort":
          if (sortType && sortField)
            params += `&type=sort&sortType=${sortType}&sortField=${sortField}`;
          break;
      }

      let { data } = await fetchUsersTable(params);
      setTableData(data?.data);
      setCount(data?.count);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchTableData(1, 100);
  }, []);

  let timeOut;

  const handleSearch = (value) => {
    if (value !== "") {
      setSearch(true);
      setSearchTxt(value);
      fetchTableData(1, null, "search", value);
    } else {
      setSearchTxt("");
      setSearch(false);
      fetchTableData(1, 100);
    }
  };

  return (
    <>
      <div className="px-3">
        {/* {!isLoading && ( */}
        <div className="d-flex p-3" style={{ justifyContent: "space-between" }}>
          <div>
            <ToggleButtons />
          </div>
          <div className="d-flex">
            <TextField
              className="search-bar-table me-2"
              id="input-with-icon-textfield"
              placeholder="Search"
              variant="standard"
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: "gray" }} />,
                disableUnderline: true,
                style: { color: "black", fontWeight: 400, height: 25 },
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch(e.target.value);
                }
              }}
            />
            <Button
              onClick={() => {
                navigate(`/create-user`, {
                  state: {
                    path: `/users`,
                  },
                });
              }}
            >
              <AddIcon /> Add User
            </Button>
          </div>
        </div>
        {/* )} */}
        <CustomTable
          tableData={tableData}
          columns={columns}
          defaultLimit={100}
          totalCount={totalCount}
          isLoading={isLoading}
          onPageChange={(page, limit) => {
            setLimit(limit);
            setPage(page);
            if (isSearch) {
              fetchTableData(page, limit, "search", searchTxt);
            } else {
              if (filter === "all") {
                fetchTableData(page, limit);
              } else {
                fetchTableData(page, limit, "filter", filter);
              }
            }
          }}
          onLimitChange={(page, limit) => {
            setLimit(limit);
            setPage(page);
            if (filter === "all") {
              fetchTableData(1, limit);
            } else {
              fetchTableData(1, limit, "filter", filter);
            }
          }}
          onSortChange={(page, limit, sortValue, sortField) => {
            fetchTableData(page, limit, "sort", null, sortValue, sortField);
          }}
          // onDoubleClick={(data) => {
          //   navigate(`/view-application/${data.application_id}`);
          // }}
        />
      </div>
    </>
  );
};
