import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getAllCities } from "../api";
import { toast } from "react-toastify";
import { Input } from "@mui/material";

function DebounceInput(props) {
  const { handleDebounce, debounceTimeout, ...rest } = props;

  const timerRef = React.useRef();

  const handleChange = (event) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  return <TextField {...rest} onChange={handleChange} />;
}

function AutocompleteCityNames({ fullWidth, label, onChange }) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectValue, setSelectValue] = useState(null);
  const loaded = useRef(false);

  async function fetchAllCities() {
    try {
      let params = `search=${inputValue}`;
      params += `&limit=100`;
      const {
        data: { data },
      } = await getAllCities(params);
      setOptions(data || []);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDebounce = (value) => {
    setIsLoading(true);
    fetchAllCities(value);
  };

  return (
    <Autocomplete
      id="google-map-demo"
      fullWidth={fullWidth}
      getOptionLabel={(option) => {
        if(!option?.city || !option?.pincode) return ""
        return option?.city + " - " + option?.pincode 
      }}
      inputValue={inputValue}
      value={selectValue}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      loading={isLoading}
      noOptionsText="No locations"
      onChange={(event, newValue) => {
        onChange(event, newValue || {});
        setSelectValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <DebounceInput
          {...params}
          label={label}
          variant="standard"
          fullWidth={fullWidth}
          placeholder="Type in here…"
          debounceTimeout={1000}
          handleDebounce={handleDebounce}
        />
      )}
      renderOption={(props, { pincode, city }) => {
        return (
          <li key={pincode + city} {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                className="d-flex flex-row align-items-center justify-content-between"
              >
                <Typography variant="body2" color="text.secondary">
                  {city}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {pincode}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default AutocompleteCityNames;
