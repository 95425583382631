import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Chip,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AutoFillBankNames from "../../../Components/AutoFillBankNames.js";
import DocumentPopup from "../../../Components/DocumentPopup.js";
import LoadingOver from "../../../Components/Loading";
import {
  NotAvailable
} from "../../../Components/StatusChip";
import { icons } from "../../../Constants/icons.js";
import { GlobalContext } from "../../../Context/GlobalContext";
import { UserContext } from "../../../Context/UserContext";
import { updateBankDetails } from "../../../api";
import useApplication from "../../../hooks/useApplication";
import { f } from "../../../utility/Formater";

const filter = createFilterOptions();

// const icons

const initialDetails = {
  benificiary_name: "",
  account_number: "",
  confirm_account_number: "",
  bank_name: "",
  account_type: "",
  ifsc_code: "",
};

const BankDetails = () => {
  const { updateDetailState } = useContext(GlobalContext);
  const { user } = useContext(UserContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const { getApplicationDetails } = useApplication();
  const [bankDtlsEdit, setBankDtlsEdit] = useState(false);
  const [bankDetails, setBankDetails] = useState(initialDetails);
  const [bankUpdateLoading, setBankUpdateLoading] = useState(false);
  const [docCount, setDocCount] = useState({
    nbfc: 0,
    embifi: 0,
  });
  const [openDocumentsPopup, setOpenDocumentsPopup] = useState({
    open: false,
    type: null,
    image: null,
    documents: [],
  });
  // console.log('bank details : ', updateDetails?.penny_drop_logs)

  async function handleSubmit() {
    try {
      if (bankDetails?.account_number !== bankDetails?.confirm_account_number) {
        toast?.error(
          "Account Number and Confirm Account Number fields are not same",
          {
            style: {
              width: 380,
            },
          }
        );
        return;
      }
      setBankDtlsEdit(false);
      setBankUpdateLoading(true);

      const { data } = await updateBankDetails({
        ...bankDetails,
        bank_id: updateDetails?.bank_id,
      });
      if (data?.status) {
        toast(data?.message);
        // setUpdateDetails({
        //   ...updateDetails,
        //   ...bankDetails
        // })
      }
    } catch (error) {
      // toast.error(error?.message);
      toast.error(error?.response?.data?.message || "Penny drop failed !!!", {
        style: {
          width: 250,
        },
      });
    }
    try {
      await getApplicationDetails(updateDetails?.application_id);
    } catch (error) {}
    setBankUpdateLoading(false);
  }

  function handleClose() {
    setBankDtlsEdit(false);
  }

  useEffect(() => {
    if (updateDetails) {
      setBankDetails({
        benificiary_name: updateDetails?.benificiary_name,
        account_number: updateDetails?.account_number,
        confirm_account_number: updateDetails?.account_number,
        bank_name: updateDetails?.bank_name,
        account_type: updateDetails?.account_type,
        ifsc_code: updateDetails?.ifsc_code,
      });
      setDocCount({
        embifi:
          updateDetails?.bank_cheques_received?.cheque_with_embifi?.length || 0,
        nbfc:
          updateDetails?.bank_cheques_received?.cheque_with_nbfc?.length || 0,
      });
    }
  }, [updateDetails]);

  return (
    <>
      <Grid container spacing={2} className="mb-4">
        <Grid item xs={12}>
          <div className="overview-card">
            <div className="card-heading">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: 20,
                  paddingLeft: 5,
                }}
              >
                <div>Bank Details</div>
                {[1, 5].includes(Number(user?.role)) &&
                  updateDetails?.bank_id && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setBankDtlsEdit(true);
                      }}
                      style={{
                        fontSize: 10,
                        width: 100,
                      }}
                    >
                      {" "}
                      <EditIcon
                        style={{
                          boxSizing: "content-box",
                          fontSize: 15,
                          padding: "0px 5px",
                        }}
                      />{" "}
                      Edit
                    </Button>
                  )}
              </div>
            </div>
            <div className="card-content">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ListItemComp
                    heading={"ID:"}
                    text={f(updateDetails?.bank_id)}
                  />
                  <ListItemComp
                    heading={"Name:"}
                    text={f(updateDetails?.benificiary_name)}
                  />
                  <ListItemComp
                    heading={"Account No:"}
                    text={f(updateDetails?.account_number)}
                  />

                  <ListItemComp
                    heading={"Bank:"}
                    text={f(
                      updateDetails?.penny_drop_data?.verified
                        ? updateDetails?.bank_name
                        : updateDetails?.nachable_bank_name
                    )}
                  />
                  <ListItemComp
                    heading={"Account Type:"}
                    text={f(updateDetails?.account_type)}
                  />
                  <ListItemComp
                    heading={"IFSC Code:"}
                    text={f(updateDetails?.ifsc_code)}
                  />
                  <ListItemComp
                    heading={"Bank Verified:"}
                    text={f(updateDetails?.bank_verified, "bool")}
                  />
                  <ListItemComp
                    heading={"UPI"}
                    text={<UPIID upiIds={updateDetails?.customer_mobile_upi} />}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      {Number(user?.role) !== 3 && (
        <div>
          <Accordion sx={{ borderRadius: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ border: "none" }}
              className="d-flex flex-row align-items-center"
            >
              <span>Bank Cheque Received</span>
              <Chip
                size="small"
                sx={{
                  ml: 1,
                  color: "#fff",
                  height: 20,
                  padding: "3px 5px",
                  fontSize: "12px",
                  backgroundColor:
                    docCount?.embifi + docCount?.nbfc > 0
                      ? "#4D19D2"
                      : "#bab9b6",
                }}
                label={docCount?.embifi + docCount?.nbfc}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid container className="mb-4">
                {["NBFC", "Embifi"].map((item) => {
                  let type = item?.toLowerCase();
                  return (
                    <Grid item xs={12} sm={1.5}>
                      <div
                        style={{
                          width: 120,
                          height: 140,
                          position: "relative",
                          cursor: "pointer",
                          display: "grid",
                          placeItems: "center",
                          paddingTop: 10,
                        }}
                        onClick={() => {
                          let documents =
                            updateDetails?.bank_cheques_received?.[
                              `cheque_with_${type}`
                            ] || [];
                          setOpenDocumentsPopup({
                            open: true,
                            type: item,
                            image: icons[type],
                            documents,
                          });
                        }}
                      >
                        <Paper
                          elevation={3}
                          sx={{
                            width: 100,
                            height: 80,
                            display: "grid",
                            placeItems: "center",
                            borderRadius: "8px",
                          }}
                        >
                          <span
                            className="icons"
                            style={{
                              backgroundColor:
                                docCount?.[type] > 0 ? "#4D19D2" : "#bab9b6",
                              hieght: 25,
                              width: 38,
                              textAlign: "center",
                              top: -5,
                              right: -5,
                              fontSize: "20px !important",
                            }}
                          >
                            <span
                              className="text-center"
                              style={{ fontSize: 10 }}
                            >
                              {docCount?.[type]}
                            </span>
                          </span>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "relative",
                              overflow: "hidden",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "60px",
                                zIndex: 1,
                                position: "absolute",
                                cursor: "pointer",
                              }}
                            ></div>
                            <img src={icons[type]} width={60} />
                          </div>
                        </Paper>
                        <div
                          style={{
                            width: 100,
                            height: 80,
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <p
                            className="text-center"
                            style={{ fontSize: "13px", fontWeight: 500 }}
                          >
                            With {item}
                          </p>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {Number(user?.role) !== 3 && (
        <Grid container spacing={2} className="mb-4 mt-2">
          <Grid item xs={12}>
            <div className="overview-card">
              <p className="card-heading">Penny Drop Logs</p>
              <div className="card-content">
                {[null, undefined]?.includes(updateDetails?.penny_drop_logs) ||
                updateDetails?.penny_drop_logs?.length == 0 ? (
                  <NotAvailable />
                ) : (
                  <Grid container spacing={2}>
                    {updateDetails?.penny_drop_logs?.map((val, index) => {
                      // console.log('===> ', val?.provider == "digio" ? val?.error?.error_msg : val?.errorMessage)
                      return (
                        <Grid item xs={12}>
                          <div className="pl-container">
                            <div className="pl-box">
                              <p className="pl-label">Timestamp : </p>
                              <p className="pl-label-2">
                                {moment(val?.timestamp)?.format("DD-MM-YYYY")}
                              </p>
                            </div>
                            <div className="pl-box">
                              <p className="pl-label">Provider : </p>
                              <p className="pl-label-2">{val?.provider}</p>
                            </div>
                            <div className="pl-box">
                              <p className="pl-label">Message : </p>
                              <p className="pl-label-2">
                                {val?.provider == "digio"
                                  ? val?.error?.error_msg
                                  : val?.errorMessage}
                              </p>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      )}
      <Dialog open={bankDtlsEdit} onClose={handleClose}>
        <DialogTitle>Edit Bank Details</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={bankDetails?.benificiary_name}
            onChange={(e) => {
              setBankDetails((prev) => ({
                ...prev,
                benificiary_name: e.target.value,
              }));
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="account_no"
            label="Account Number"
            type="text"
            fullWidth
            variant="standard"
            value={bankDetails?.account_number}
            onChange={(e) => {
              setBankDetails((prev) => ({
                ...prev,
                account_number: e.target.value,
              }));
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="conf_account_no"
            label="Confirm Account Number"
            type="text"
            fullWidth
            variant="standard"
            value={bankDetails?.confirm_account_number}
            onChange={(e) => {
              setBankDetails((prev) => ({
                ...prev,
                confirm_account_number: e.target.value,
              }));
            }}
          />
          <AutoFillBankNames
            label={"Bank Name"}
            value={bankDetails?.bank_name}
            onChange={(value) => {
              setBankDetails((prev) => ({
                ...prev,
                bank_name: value,
              }));
            }}
          />
          <Autocomplete
            options={["Savings", "Current"]}
            // getOptionLabel={(option) => option?.replaceAll("_", " ")}
            value={bankDetails?.account_type}
            id="account_type"
            clearOnBlur
            renderInput={(params) => (
              <TextField {...params} label="Account Type" variant="standard" />
            )}
            onChange={(e, value) => {
              setBankDetails((prev) => ({
                ...prev,
                account_type: value,
              }));
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="ifsc"
            label="IFSC Code"
            type="text"
            fullWidth
            variant="standard"
            value={bankDetails?.ifsc_code}
            onChange={(e) => {
              setBankDetails((prev) => ({
                ...prev,
                ifsc_code: e.target.value,
              }));
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>submit</Button>
        </DialogActions>
      </Dialog>
      <DocumentPopup
        open={openDocumentsPopup.open}
        documents={openDocumentsPopup.documents}
        title={openDocumentsPopup.type}
        docIcon={openDocumentsPopup.image}
        firstDocName="cheque_1"
        application_id={updateDetails?.application_id}
        handleClose={() => {
          setOpenDocumentsPopup({ open: false, type: null, documents: [] });
        }}
      />
      {bankUpdateLoading && <LoadingOver text={"Updating Bank Details ..."} />}
    </>
  );
};

export default BankDetails;

const ListItemComp = ({ icon, heading, text, component }) => {
  return (
    <ListItem disablePadding>
      <ListItemIcon className="list-key">
        {
          <span>
            {icon && icon}
            {heading}
          </span>
        }
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontSize: "13px" }}
        className="list-value"
        primary={text}
      />
      {component}
    </ListItem>
  );
};

const UPIID = ({ upiIds }) => {
  upiIds = typeof(upiIds) === "string" ? [upiIds] : upiIds;
  return (
  <>
    {upiIds
      ? upiIds?.map((item) => (
          <>
            <Chip
              variant="outlined"
              color="info"
              size="small"
              className="mx-1"
              label={item}
            />
          </>
        ))
      : f(upiIds)}
  </>
)}
