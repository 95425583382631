import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Rotate90DegreesCwIcon from "@mui/icons-material/Rotate90DegreesCw";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { GlobalContext } from "../../Context/GlobalContext";
import { UserContext } from "../../Context/UserContext";
import {
  baseURL,
  combineDocs,
  deleteDocument,
  fetchSingleDocData,
  updateDocStatus,
  uploadMandate,
} from "../../api";
import courierSentIco from "../../assets/courierSentIco.svg";
import useImageWithLocation from "../../hooks/useImageWithLocation";
import { f, getDocKey } from "../../utility/Formater";
import DeletePopup from "../DeletePopup";
import LoadingOver from "../Loading";
import "./FileViewer.css";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FileViewer({
  open,
  onClose,
  url,
  title,
  data,
  application_id,
  reload,
  isMulti,
  multiData,
  mandate_id,
  onDownload,
  type,
  handleSwipe,
  setswipeDocs,
  index,
  handleReupload,
  getApplicationDetails,
  getAllDocuments,
  handleDownloadFn,
}) {
  const { updateDetailState } = useContext(GlobalContext);
  const { user, setUser } = useContext(UserContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;

  const [docStatus, setStatus] = useState(null);
  const [loading, setLoading] = useState({
    isLoad: false,
    text: "Loading...",
  });
  const [isLoadingOver, setLoadingOver] = useState(false);
  const [fieldName, setFieldName] = useState(null);
  const [docs, setDocs] = useState([]);
  const [locationAddress, setAddress] = useState("");
  const embedRef = useRef(null);
  const [rotate, setRotate] = useState(0);
  const [captureData, setCaptureData] = useState({
    isCapture: false,
    dataUrl: null,
  });
  const [rejectReasonModal, setRejectReasonModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [documentName, setdocumentName] = useState("");
  const [videoData, setVideoData] = useState({
    isVideo: false,
    url: null,
    loader: null,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [confDltPopup, setConfDltPopup] = useState(false);
  const openDownloadMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let name = "";
    if (
      ["form_22", "form_21", "form_20", "form_26_35"].includes(
        data?.name?.substring(0, 7)
      )
    ) {
      name = data?.name?.substring(0, 7)?.trim();
    } else if (["form_26_35"].includes(data?.name?.substring(0, 10))) {
      name = data?.name?.substring(0, 10)?.trim();
    } else if (data?.category === "other_documents") {
      name = data?.category;
    } else if (
      [
        "vehicle_back_photo_with_customer",
        "vehicle_side_photo_with_customer",
        "vehicle_front_photo_with_customer",
      ].includes(data?.name)
    ) {
      name = "vehicle_delivery_photos";
    } else {
      name = data?.name
        ?.replaceAll(/[0-9]/g, "")
        ?.replaceAll("_", " ")
        ?.trim()
        ?.replaceAll(" ", "_");

      if (
        [
          "customer_house_inside",
          "customer_house_outside",
          "customer_photo_with_electricity_meter",
        ].includes(name)
      ) {
        name = "customer";
      }
    }

    setFieldName(name);

    if (isMulti) {
      (async () => {
        let { data: response } = await fetchSingleDocData({
          applicationId: application_id,
          fieldName: name,
          collection: data?.collection,
        });

        response?.data?.sort((a, b) => a.page - b.page);
        setDocs([...response?.data]);
      })();
    }
    setdocumentName(data.name);
  }, [data]);

  useEffect(() => {
    if (open) {
      if (data?.fileLink?.match("data:video")) {
        setVideoData({
          ...videoData,
          isVideo: true,
          loader: true,
        });
        handleFetchVideo(data);
      } else {
        setVideoData({
          isVideo: false,
          url: null,
          loader: null,
        });
      }
    } else {
      setVideoData({
        isVideo: false,
        url: null,
        loader: null,
      });
    }
  }, [open, data?.fileLink]);

  const handleFetchVideo = async (fileData) => {
    try {
      const { key, dataType } = await getDocKey(fileData?.fileLink);
      let url = await handleDownloadFn(key, null, fileData, false, dataType);
      if (open && fileData?.name === data?.name) {
        setVideoData({
          isVideo: true,
          url,
          loader: false,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const handleDocStatus = async (status) => {
    try {
      setLoading({
        isLoad: true,
        text: status ? "Approving..." : "Rejecting...",
      });

      if (isMulti) {
        let newFileName = data?.name?.replace(/_\d+$/, "");

        let payload_1 = {
          applicationId: updateDetails?.application_id,
          collection: data?.collection,
          document_name: newFileName,
        };

        const { data: combinedRes } = await combineDocs(payload_1);

        if (combinedRes?.status) {
          toast("documents combined !!!");
        }
      }

      let payload = {
        applicationId: application_id,
        data: [
          {
            fieldName,
            fileName: data?.name,
            collection: data?.collection,
            status,
            rejectionReason,
            rejectedBy: user?.name,
          },
        ],
      };

      let { data: response } = await updateDocStatus(payload);
      if (response?.status) {
        toast.success(data?.response);
        setStatus(status);
        setswipeDocs((prev) => {
          prev[index].isApproved = status;
          return [...prev];
        });

        reload(status);
      }
    } catch (error) {
      // console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
    }
    setLoading({ isLoad: false, text: "Loading..." });
  };

  async function handleDeleteDoc() {
    let fieldName;
    if (
      ["form_22", "form_21", "form_20", "form_26_35"].some((str) =>
        new RegExp(str).test(data?.name)
      )
    ) {
      fieldName = data?.name?.replace(/_([0-9]+)$/, "");
    } else if (data?.category === "other_documents") {
      fieldName = data?.category;
    } else {
      fieldName = data?.name
        ?.replaceAll(/[0-9]/g, "")
        ?.replaceAll("_", " ")
        ?.trim()
        ?.replaceAll(" ", "_");
    }

    let payload = {
      applicationId: application_id,
      fieldName,
      fileLink: data?.fileLink,
      collection: data?.collection,
    };
    // console.log("on delete -> ",payload);
    try {
      toast.loading("Deleting...");
      if (isMulti) {
        let docArray = [];
        for (let doc of docs) {
          let payld = {
            applicationId: application_id,
            fieldName,
            fileLink: doc?.fileLink,
            collection: doc?.collection,
          };
          docArray.push(deleteDocument(payld));
        }
        let { data } = await Promise.allSettled(docArray);
      } else {
        let { data } = await deleteDocument(payload);
      }
      toast.dismiss();
      await getApplicationDetails();
      await getAllDocuments();
      handleSwipe("prev", index - 1, true);
      toast.success("Deleted successfully");
    } catch (error) {
      toast.dismiss();
      toast.error("Error While Deleting Document !!!");
    }
    setConfDltPopup(false);
  }

  const handleUploadEnach = async () => {
    setLoadingOver(true);
    try {
      fetch(`${baseURL}/common/view?key=${data?.key}`, {
        method: "get",
        credentials: "include",
        headers: {
          responseType: "blob",
          "Content-Type": "application/json",
          application: "EMBEDDED-TOOLS",
        },
      })
        .then((res) => res.blob())
        .then((res) => {
          var reader = new FileReader();
          reader.readAsDataURL(res);
          reader.onloadend = async function () {
            var base64 = reader.result;
            // console.log(base64);

            let payload = {
              document_include_g: true,
              mandate_id,
              application_id: application_id,
              file_data: base64,
            };

            try {
              let { data } = await uploadMandate(payload);
              toast.success(data?.message);
              setLoadingOver(false);
              reload();
            } catch (err) {
              setLoadingOver(false);
              toast.error(
                err?.response?.data?.message || "Something went wrong"
              );
            }
          };
        });
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong");
      setLoadingOver(false);
    }
  };

  const handleLocation = async (lat, long) => {
    try {
      let { data: response } = await axios.get(
        `https://api.common-wrapper.embifi.in/v1/map/geocode?lat=${lat}&long=${long}`
      );
      // setAddress(response?.data?.results?.[0]?.formatted_address);
      return response?.data?.results?.[0]?.formatted_address || "";
    } catch (error) {
      toast?.error(error?.response?.data?.message || error?.message);
      return "";
    }
  };

  const handleRejectReason = () => {
    if (rejectionReason != "") {
      handleDocStatus(false);
      setRejectionReason("");
      setRejectReasonModal(false);
    } else {
      toast.error("Please provide valid rejection reason.");
    }
  };

  useEffect(() => {
    setAddress("");
    setStatus(data?.isApproved);

    (async () => {
      if (data?.location) {
        if (
          data?.location?.ending_location &&
          data?.location?.starting_location
        ) {
          setAddress(
            await handleLocation(
              data?.location?.starting_location?.lat,
              data?.location?.starting_location?.long
            )
          );
        } else {
          setAddress(
            await handleLocation(
              data?.location?.lat || data?.location?.latitude,
              data?.location?.long || data?.location?.longitude
            )
          );
        }
      }
    })();
  }, [data]);

  const AnyReactComponent = () => (
    <div className="pin-map">
      <div class="pin bounce"></div>
      <div class="pulse"></div>
    </div>
  );

  const handleRotation = () => {
    let currentRotation = rotate;
    currentRotation += 90;
    if (currentRotation === 360) {
      currentRotation = 0;
    }
    setRotate(currentRotation);
    embedRef.current.style.transform = "rotate(" + currentRotation + "deg)";

    if (currentRotation === 90 || currentRotation === 270) {
      embedRef.current.style.width = "50%";
    } else {
      embedRef.current.style.width = "100%";
    }
  };

  const divRef = useRef(null);

  const { getImageWithLocation } = useImageWithLocation();

  const handleCapture = async () => {
    let file = await getImageWithLocation(
      {
        lat: data?.location?.lat || data?.location?.latitude,
        long: data?.location?.long || data?.location?.longitude,
      },
      captureData?.dataUrl
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = file;
    downloadLink.download = `${data?.name}.png`;
    downloadLink.click();
  };

  useEffect(() => {
    if (data?.key) {
      fetch(`${baseURL}/common/view?key=${data?.key}`, {
        method: "get",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          application: "EMBEDDED-TOOLS",
        },
      })
        .then((res) => res.blob())
        .then((res) => {
          const href = URL.createObjectURL(res);
          setCaptureData({
            ...captureData,
            dataUrl: href,
            isCapture: false,
          });
        });
    }
  }, [data?.location]);

  const handleDownload = () => {
    if (
      (data?.location?.lat || data?.location?.latitude) &&
      (data?.location?.long || data?.location?.longitude) &&
      !data?.location?.imageLocation
    ) {
      handleCapture();
    } else {
      if (isMulti) onDownload(docs, isMulti, fieldName);
      else onDownload(data, isMulti);
    }
    // if (isMulti) onDownload(docs, isMulti, fieldName);
    // else onDownload(data, isMulti);
  };

  return (
    <>
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={open}
          onClose={onClose}
          TransitionComponent={Transition}
          onKeyDown={(e) => {
            if (index !== "none") {
              if (e.key === "ArrowLeft") {
                handleSwipe("prev", index - 1);
              }
              if (e.key === "ArrowRight") {
                handleSwipe("next", index + 1);
              }
            }
          }}
        >
          <AppBar
            sx={{ position: "relative" }}
            style={{ backgroundColor: "white", color: "black" }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {title}
                {fieldName === "customer" && (
                  <Button
                    className="ms-3 border border-secondary border-secondary text-dark"
                    variant="outlined"
                    disabled={true}
                    sx={{
                      color: "#757575",
                    }}
                  >
                    Fi distance -{" "}
                    {f(
                      updateDetails?.fi_distance
                        ?.toFixed(3)
                        ?.toString()
                        ?.concat(" km")
                    )}
                  </Button>
                )}
              </Typography>

              {!isMulti &&
                !videoData?.isVideo &&
                // type === "documents" &&
                data?.dataType !== "pdf" &&
                fieldName !== "courier_receipt" && (
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleRotation}
                    aria-label="close"
                    className="me-2"
                  >
                    <Rotate90DegreesCwIcon />
                  </IconButton>
                )}
              {loading?.isLoad && (
                <span
                  style={{
                    color: "black",
                    padding: "5px 10px",
                  }}
                >
                  <CircularProgress size={15} /> {loading?.text}
                </span>
              )}
              {!loading?.isLoad &&
                docStatus !== undefined &&
                Number(user?.role) !== 3 &&
                !(
                  (documentName === "aadhaar_back" ||
                    documentName === "aadhaar_front" ||
                    documentName === "applicant_pan" ||
                    documentName === "co_customer_aadhaar_back" ||
                    documentName === "co_customer_aadhaar_front" ||
                    documentName === "co_customer_pan") &&
                  ![1, 5].includes(Number(user?.role))
                ) && (
                  <div className="dropdown">
                    <Button
                      className="btn btn-sm dropdown-toggle doc-status-dropdown"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                      sx={{
                        marginRight: 2,
                      }}
                    >
                      <EditIcon
                        sx={{
                          paddingRight: 1,
                        }}
                      />{" "}
                      Edit document
                    </Button>
                    <ul
                      className="dropdown-menu font-rocknroll"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {[1, 5].includes(Number(user?.role)) && (
                        <li
                          className="dropdown-item user-select-none"
                          onClick={() => {
                            data.index = index;
                            handleReupload(data);
                          }}
                        >
                          {"Re-upload"}
                        </li>
                      )}
                      {!(
                        documentName === "aadhaar_back" ||
                        documentName === "aadhaar_front" ||
                        documentName === "applicant_pan" ||
                        documentName === "co_customer_aadhaar_back" ||
                        documentName === "co_customer_aadhaar_front" ||
                        documentName === "co_customer_pan"
                      ) && (
                        <li
                          className="dropdown-item user-select-none"
                          onClick={() => {
                            setConfDltPopup(true);
                          }}
                        >
                          {"Delete"}
                        </li>
                      )}
                    </ul>
                  </div>
                )}

              {!loading?.isLoad && docStatus !== undefined && (
                <>
                  {docStatus === null ? (
                    // isMulti && !data?.completed ? (
                    //   <p className="mb-0">Waiting to combine</p>
                    // ) :
                    Number(user?.role) !== 3 && (
                      <div>
                        <CancelIcon
                          onClick={() => {
                            setRejectReasonModal(true);
                            // handleDocStatus(false);
                          }}
                          sx={{
                            color: "red",
                            fontSize: 35,
                            cursor: "pointer",
                          }}
                        />
                        <CheckCircleIcon
                          onClick={() => {
                            handleDocStatus(true);
                          }}
                          sx={{
                            color: "green",
                            fontSize: 35,
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    )
                  ) : (
                    <span
                      style={{
                        color: "white",
                        backgroundColor: docStatus ? "green" : "red",
                        padding:
                          updateDetails?.current_status?.toLowerCase() ==
                            "disbursed" ||
                          documentName === "aadhaar_back" ||
                          documentName === "aadhaar_front" ||
                          documentName === "applicant_pan" ||
                          documentName === "co_customer_aadhaar_back" ||
                          documentName === "co_customer_aadhaar_front" ||
                          documentName === "co_customer_pan" ||
                          Number(user?.role) === 3
                            ? "5px 10px"
                            : 0,
                        borderRadius: 15,
                      }}
                    >
                      {updateDetails?.current_status?.toLowerCase() ==
                        "disbursed" ||
                      documentName === "aadhaar_back" ||
                      documentName === "aadhaar_front" ||
                      documentName === "applicant_pan" ||
                      documentName === "co_customer_aadhaar_back" ||
                      documentName === "co_customer_aadhaar_front" ||
                      documentName === "co_customer_pan" ||
                      Number(user?.role) === 3 ? (
                        docStatus ? (
                          "Approved"
                        ) : (
                          "Rejected"
                        )
                      ) : documentName === "customer_house_inside" ||
                        documentName === "customer_house_outside" ||
                        documentName ===
                          "customer_photo_with_electricity_meter" ? (
                        <div className="dropdown">
                          <button
                            className={`btn ${
                              data.uploadedFrom === "LOAN_JOURNEY"
                                ? "doc-status-dropdown dropdown-toggle"
                                : ""
                            }`}
                            type="button"
                            id={`${
                              data.uploadedFrom === "LOAN_JOURNEY"
                                ? "dropdownMenuButton1"
                                : ""
                            }`}
                            data-bs-toggle={`${
                              data.uploadedFrom === "LOAN_JOURNEY"
                                ? "dropdown"
                                : ""
                            }`}
                            aria-expanded="false"
                            disabled={data.uploadedFrom !== "LOAN_JOURNEY"}
                          >
                            {docStatus ? "Approved" : "Rejected"}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li
                              className="dropdown-item user-select-none"
                              onClick={() => {
                                let docStatusTemp = !docStatus;
                                if (docStatusTemp === true) {
                                  handleDocStatus(true);
                                } else if (docStatusTemp === false) {
                                  setRejectReasonModal(true);
                                }
                              }}
                            >
                              {!docStatus ? "Approve" : "Reject"}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        <div className="dropdown">
                          <button
                            className="btn dropdown-toggle doc-status-dropdown "
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {docStatus ? "Approved" : "Rejected"}
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li
                              className="dropdown-item user-select-none"
                              onClick={() => {
                                let docStatusTemp = !docStatus;
                                if (docStatusTemp === true) {
                                  handleDocStatus(true);
                                } else if (docStatusTemp === false) {
                                  setRejectReasonModal(true);
                                }
                              }}
                            >
                              {!docStatus ? "Approve" : "Reject"}
                            </li>
                          </ul>
                        </div>
                      )}
                    </span>
                  )}
                </>
              )}

              {fieldName === "nach_form" &&
                type === "documents" &&
                docStatus &&
                (!updateDetails?.physical_nach_status?.uploaded && updateDetails?.physical_nach_data?.bank_details?.state!=="register_failed") ? (
                  <Button
                    className="ms-2"
                    color="inherit"
                    onClick={handleUploadEnach}
                  >
                    <FileUploadIcon /> Send to Digio
                  </Button>
                ) : (
                  <></>
                )}

              {!isMulti && fieldName !== "courier_receipt" && (
                <Button
                  color="inherit"
                  className="ms-2"
                  disabled={Number(user?.role) == 2}
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  <FileDownloadIcon />
                </Button>
              )}

              {isMulti && type !== "transactions" && (
                <Button
                  id="basic-button"
                  aria-controls={openDownloadMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDownloadMenu ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ color: "black", ml: 1 }}
                >
                  <FileDownloadIcon />
                </Button>
              )}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDownloadMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    handleDownload();
                  }}
                >
                  ZIP
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    onDownload(docs, isMulti, fieldName, true);
                  }}
                >
                  PDF
                </MenuItem>
              </Menu>
              <Button
                color="inherit"
                onClick={() => {
                  window.open(url, `_blank`);
                }}
              >
                <FullscreenIcon />
              </Button>
            </Toolbar>
          </AppBar>
          <div
            className="w-100 bg-dark"
            style={{ height: "100vh", overflowY: "scroll" }}
            ref={divRef}
          >
            {!isMulti && (
              <div className="file-image-holder d-flex justify-content-center">
                {data?.location && (
                  <>
                    <div
                      className="location-holder"
                      style={{ zIndex: 2, marginRight: "80px" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "250px",
                          marginBottom: "5px",
                        }}
                      >
                        {(((data?.location?.lat || data?.location?.latitude) &&
                          (data?.location?.long ||
                            data?.location?.longitude)) ||
                          (data?.location?.starting_location?.lat &&
                            data?.location?.starting_location?.long) ||
                          (data?.location?.ending_location?.lat &&
                            data?.location?.ending_location?.long)) && (
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: process.env.REACT_APP_MAP_KEY,
                            }}
                            defaultCenter={{
                              lat:
                                data?.location?.lat ||
                                data?.location?.latitude ||
                                data?.location?.starting_location?.lat ||
                                data?.location?.ending_location?.lat,
                              lng:
                                data?.location?.long ||
                                data?.location?.longitude ||
                                data?.location?.starting_location?.long ||
                                data?.location?.ending_location?.long,
                            }}
                            defaultZoom={11}
                          >
                            {(data?.location?.lat ||
                              data?.location?.latitude) &&
                              (data?.location?.long ||
                                data?.location?.longitude) && (
                                <AnyReactComponent
                                  lat={
                                    data?.location?.lat ||
                                    data?.location?.latitude
                                  }
                                  lng={
                                    data?.location?.long ||
                                    data?.location?.longitude
                                  }
                                  text={"Kreyser Avrora"}
                                />
                              )}
                            {data?.location?.starting_location?.lat &&
                              data?.location?.starting_location?.long && (
                                <AnyReactComponent
                                  lat={data?.location?.starting_location?.lat}
                                  lng={data?.location?.starting_location?.long}
                                  text={"Start Location"}
                                />
                              )}
                            {data?.location?.ending_location?.lat &&
                              data?.location?.ending_location?.long && (
                                <AnyReactComponent
                                  lat={data?.location?.ending_location?.lat}
                                  lng={data?.location?.ending_location?.long}
                                  text={"End Location"}
                                />
                              )}
                          </GoogleMapReact>
                        )}
                      </div>

                      <div
                        className="location-div py-3"
                        style={{ width: "100%" }}
                      >
                        <p>
                          <LocationOnIcon /> Location Details
                        </p>
                        <hr />
                        <p style={{ fontSize: 15 }}>
                          <span>Coordinates:</span>
                          <br />{" "}
                          {data?.location?.starting_location ||
                          data?.location?.ending_location ? (
                            <>
                              <div>
                                Start : {data?.location?.starting_location?.lat}
                                , {data?.location?.starting_location?.long}
                              </div>
                              <div>
                                End : {data?.location?.ending_location?.lat},
                                {data?.location?.ending_location?.long}
                              </div>
                            </>
                          ) : (
                            <>
                              {data?.location?.lat || data?.location?.latitude},{" "}
                              {data?.location?.long ||
                                data?.location?.longitude}
                            </>
                          )}
                        </p>
                        <p style={{ maxWidth: 300, fontSize: 15 }}>
                          <span>Address:</span>
                          <br></br> {locationAddress}
                        </p>
                        {data?.uploadedDateMS && (
                          <p className="mt-3" style={{ fontSize: 12 }}>
                            <DateRangeIcon
                              sx={{
                                fontSize: 16,
                                mb: 0.5,
                                mr: 1,
                                color: "#00bfff",
                              }}
                            />
                            {moment(data?.uploadedDateMS).format(
                              "DD-MM-YYYY, hh:mm:ss a"
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {url ? (
                  <div
                    style={{
                      position: "relative",
                      // display: "grid",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // gridTemplateColumns:
                      //   index === "none" ? "100%" : "10% 80% 10%",
                      // placeItems: "center",
                    }}
                  >
                    {index !== "none" && (
                      <Button
                        style={{
                          position: "absolute",
                          left: 0,
                        }}
                        disabled={videoData?.loader}
                        onClick={() => {
                          handleSwipe("prev", index - 1);
                        }}
                      >
                        <ArrowBackIosIcon
                          sx={{ color: "#fff", fontSize: "40px" }}
                        />
                      </Button>
                    )}
                    {videoData?.isVideo ? (
                      <>
                        {videoData?.loader && (
                          <div
                            style={{
                              position: "absolute",
                              zIndex: 6000,
                            }}
                          >
                            <div className="content-center">
                              <CircularProgress />
                            </div>
                            <p className="text-center mb-0 mt-2 text-white">
                              Loading Video
                            </p>
                          </div>
                        )}
                        <video
                          src={videoData?.url}
                          width={"50%"}
                          height={"50%"}
                          controls={true}
                        />
                      </>
                    ) : (
                      <embed
                        ref={embedRef}
                        width="100%"
                        height={"100%"}
                        src={url}
                      ></embed>
                    )}
                    {index !== "none" && (
                      <Button
                        onClick={() => {
                          handleSwipe("next", index + 1);
                        }}
                        disabled={videoData?.loader}
                        style={{ zIndex: 2, position: "absolute", right: 0 }}
                      >
                        <ArrowForwardIosIcon
                          sx={{ color: "#fff", fontSize: "40px" }}
                        />
                      </Button>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      marginTop: 120,
                    }}
                  >
                    <img ref={embedRef} width="100%" src={courierSentIco} />
                    <p
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: 26,
                        marginTop: 40,
                      }}
                    >
                      Your courier has been sent by the dealer
                    </p>
                    <p
                      style={{
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Please approve once the documents are recieved physically!
                    </p>
                  </div>
                )}
              </div>
            )}
            {isMulti && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  overflowY: "scroll",
                }}
              >
                {docs?.length > 0 ? (
                  docs?.map((doc) => {
                    return (
                      <>
                        <div
                          style={{
                            height: "100%",
                            position: "relative",
                            display: "grid",
                            gridTemplateColumns:
                              index === "none" ? "100%" : "10% 80% 10%",
                            placeItems: "center",
                          }}
                        >
                          {index !== "none" && (
                            <Button>
                              <ArrowBackIosIcon
                                sx={{ color: "#fff", fontSize: "40px" }}
                                onClick={() => {
                                  handleSwipe("prev", index - 1);
                                }}
                              />
                            </Button>
                          )}
                          <embed
                            width="100%"
                            height={"100%"}
                            src={doc?.fileLink}
                          ></embed>

                          {index !== "none" && (
                            <Button>
                              <ArrowForwardIosIcon
                                sx={{ color: "#fff", fontSize: "40px" }}
                                onClick={() => {
                                  handleSwipe("next", index + 1);
                                }}
                              />
                            </Button>
                          )}
                          {[1, 5].includes(Number(user?.role)) && (
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{
                                position: "absolute",
                                bottom: "50px",
                                right: 30,
                                fontSize: 12,
                                padding: "5px 15px",
                                borderRadius: 15,
                                width: 100,
                                height: 25,
                              }}
                              onClick={() => {
                                handleReupload({
                                  ...doc,
                                  collection: data?.collection,
                                  pageUpload: true,
                                });
                              }}
                            >
                              Reupload
                            </Button>
                          )}
                          <div
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: 30,
                              fontSize: 12,
                              backgroundColor: "black",
                              color: "white",
                              padding: "5px 15px",
                              borderRadius: 15,
                              width: 100,
                              textAlign: "center",
                            }}
                          >
                            Page {doc?.page}
                          </div>
                        </div>
                        {index !== docs?.length - 1 && <hr />}
                      </>
                    );
                  })
                ) : (
                  <div className="text-center text-white">
                    No Document Found
                  </div>
                )}
              </div>
            )}
          </div>
        </Dialog>

        {/* rejection reason modal */}
        <>
          <Dialog
            open={rejectReasonModal}
            onClose={() => setRejectReasonModal(false)}
            fullWidth={true}
            maxWidth={"sm"}
            TransitionComponent={Transition}
          >
            <DialogTitle sx={{ marginBottom: "-0.8em" }}>
              Rejection purpose
            </DialogTitle>
            <DialogContent>
              <textarea
                className="reject-reason"
                placeholder="Write your message here"
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <button className="reject-btn" onClick={handleRejectReason}>
                Reject
              </button>
            </DialogActions>
          </Dialog>
        </>
        {/* deletion modal */}
        <>
          <DeletePopup
            confDltPopup={confDltPopup}
            setConfDltPopup={setConfDltPopup}
            handleDeleteDoc={handleDeleteDoc}
          />
        </>
      </div>
      {isLoadingOver && <LoadingOver text={"Sending NACH to Digio"} />}
    </>
  );
}
