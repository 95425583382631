import { useContext, useEffect } from "react";
import { Navigate, NavLink, Outlet, useLocation } from "react-router-dom";
import AuthLoading from "../../Components/Loaders/AuthLoading";
import { UserContext } from "../../Context/UserContext";

const ProtectedRoute = () => {
  const { user, isLoading } = useContext(UserContext);
  const { pathname } = useLocation();

  if (pathname === "/create-user" && user?.role != 1) {
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }
  if (pathname === "/create-anchor" && ![1,2,5].includes(Number(user?.role))) {
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }
  if (pathname === "/create-agent" && ![1,2,5].includes(Number(user?.role))) {
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }
  if (pathname === "/create-oem" && ![1,2,5].includes(Number(user?.role))) {
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }

  if (
    (pathname === "/aadhaar-ocr" ||
      pathname === "/add-product" ||
      pathname === "/partners" ||
      pathname === "/b2c-validations" ||
      pathname === "/create-anchor-v2" ||
      pathname === "/create-anchor" ||
      pathname === "/create-app" ||
      pathname === "/create-user" ||
      pathname === "/create-agent" ||
      pathname === "/create-oem" ||
      pathname.includes("/dealer-profile") ||
      pathname === "/dealer-score" ||
      pathname === "/evproducts" ||
      pathname === "/generate-schedule" ||
      pathname === "/gps-overview" ||
      pathname === "/shared-dealer-data" ||
      pathname === "/table" ||
      pathname === "/test" ||
      pathname === "/tools" ||
      pathname.includes("/upload-documents") ||
      pathname.includes("/update-anchor-v2") ||
      pathname.includes("/update-stage-v2") ||
      pathname.includes("/update-collection-v2") ||
      pathname.includes("/update-collection") ||
      pathname.includes("/update-anchor") ||
      pathname.includes("/update-app") ||
      pathname.includes("/view-documents")) &&
    Number(user?.role) === 3
  ) {
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }

  if (pathname === "/users" && user?.role != 1) {
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }

  if (isLoading) {
    return <AuthLoading />;
  }

  if (!user) {
    // return (
    //   <div className="unauthorized">
    //     <h1>Unauthorized :(</h1>
    //     <span>
    //       <NavLink to="/">Login</NavLink> to gain access
    //     </span>
    //   </div>
    // );
    return <Navigate to="/login" state={{ from: pathname }} replace />;
  }

  return <Outlet />;
};
export default ProtectedRoute;
