import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CircularProgress, Grid } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartComp = ({ pieData, title, breakWord }) => {
  const colors = [
    "#357AF6",
    "#5CC8BE",
    "#AF52DE",
    "#dea916",
    "#EA3354",
    "#abf227",
    "#7D3C98",
    "#7c8f59",
    "#D35400",
    "#2b2c54",
    "#8E44AD",
  ];

  let validData = notEmpty(Object?.values(pieData));
  const data = {
    labels: Object?.keys(pieData) || [],
    datasets: [
      {
        label: "Count",
        data: Object?.values(pieData) || [],
        backgroundColor: colors,
        borderColor: ["#ffffff"],
        borderWidth: 0.5,
      },
    ],
  };

  return (
    // <div className="pie-holder" style={{ width: "300px" }}>
    <div className="home-pie-holder">
      <p className="home-pie-heading">{title}</p>
      <div className="d-flex w-100 p-5 pt-0 pb-4 ps-4">
        <div style={{ flexGrow: 1, marginRight: 30 }}>
          {validData ? (
            <Pie
              width={100}
              height={100}
              data={data}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  datalabels: {
                    color: "#ffffff",
                    formatter: function (value, context) {
                      return value ? value : ""
                    },
                  },
                },
              }}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <div
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                background: "gray",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.5,
              }}
            >
              No Data
            </div>
          )}
        </div>
        <div style={{ flexGrow: 1, maxWidth: 100 }}>
          {(Object?.keys(pieData) || [])?.map((item, i) => {
            return (
              <div className="pie-label">
                <div
                  className="color-box"
                  style={{
                    backgroundColor: colors[i],
                    marginRight: "5px",
                    marginTop: 2.5,
                    flexShrink: 0,
                  }}
                ></div>
                <div
                  style={
                    breakWord
                      ? {
                          display: "flex",
                          alignItems: "center",
                        }
                      : {
                          whiteSpace: "nowrap",
                        }
                  }
                >
                  {item}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const notEmpty = (data) => {
  if (
    (data || [])?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    ) !== 0
  ) {
    return true;
  } else return false;
};

const ProfilingDashboard = ({ loader, profileData }) => {
  return loader === "PROFILE" ? (
    <>
      <div className="content-center w-100">
        <CircularProgress size={70} />
      </div>
    </>
  ) : (
    <>
      <Grid spacing={2} container>
        <Grid item lg={4} md={6}>
          <PieChartComp title={"Age (in yrs)"} pieData={profileData?.age} />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp title={"Gender"} pieData={profileData?.gender} />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp
            title={"Monthly Income"}
            pieData={profileData?.monthly_income}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp
            title={"Monthly household income"}
            pieData={profileData?.monthly_household_income}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp
            title={"Martial Status"}
            pieData={profileData?.martial_status}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp
            title={"Number of family members"}
            pieData={profileData?.no_of_family_members}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp title={"Education"} pieData={profileData?.education} />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp
            title={"Phone Type"}
            pieData={profileData?.phone_type}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp
            title={"Preferred repayment mode"}
            pieData={profileData?.prefered_repayment_mode}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp
            title={"Source of downpayment"}
            pieData={profileData?.source_downpayment}
            breakWord={true}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp
            title={"Relation with co"}
            pieData={profileData?.relation_with_co}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <PieChartComp
            title={"Whatsapp User"}
            pieData={profileData?.whatsapp_user}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProfilingDashboard;
