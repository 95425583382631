import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { appGPSData, updateMMINumber } from "../api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";
import { GlobalContext } from "../Context/GlobalContext";
import useApplication from "../hooks/useApplication";

export default function MMIupdateDialog({ open, handleClose, application_id }) {
  const { updateDetailState } = useContext(GlobalContext);
  const [updateDetails, setUpdateDetails] = updateDetailState;
  const { getApplicationDetails } = useApplication();
  const [unique_number, setUniqueNumber] = useState("");
  // const [gpsData, setGpsData] = useState({});
  const [procurer, setProcurer] = useState("");
  const [DOI, setDOI] = useState(null);
  const [simNumber, setSimNumber] = useState("");
  const [activate, setActivate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [uniqueIdentifier, setUniqueIdentifier] = useState("");

  const today = dayjs();

  const handleUpdateMMINumber = async () => {
    if (
      dayjs(dayjs(DOI).format("DD/MM/YYYY")).isAfter(
        dayjs().format("DD/MM/YYYY")
      )
    ) {
      toast.error("The date must not be in the future.");
      return;
    }

    try {
      let payload = {
        application_id,
        gps_unique_number: unique_number,
        gps_installation_date: dayjs(DOI).valueOf(),
        gps_procurer: procurer,
        gps_activated: activate,
        identifier:uniqueIdentifier
      };
      if (procurer !== "mmi") {
        payload = {
          ...payload,
          gps_sim_number: simNumber,
        };
      }

      setLoading(true);
      let { data } = await updateMMINumber(payload);
      toast.success("GPS updated successfully!!");
      await getApplicationDetails(application_id);
      setUpdateDetails({
        ...updateDetails,
        gps_unique_number: unique_number,
        gps_installation_date: dayjs(DOI).valueOf(),
        gps_procurer: procurer,
        gps_activated: activate,
        gps_sim_number: simNumber,
        gps_unique_identifier: uniqueIdentifier,
      });
      handleClose();
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error updating mmi number"
      );
    } finally {
      setLoading(false);
      makeDefault();
    }
  };

  async function getGpsDetails() {
    try {
      // setLoading(true);
      // const { data } = await appGPSData(updateDetails?.application_id);
      setIsDisabled(updateDetails?.gps_unique_number ? true : false);
      // setGpsData(data);
      if (updateDetails?.gps_unique_number) {
        setUniqueNumber(updateDetails?.gps_unique_number);
      }
      if (updateDetails?.gps_installation_date) {
        setDOI(
          dayjs(updateDetails?.gps_installation_date, {
            format: "DD-MM-yyyy",
          })
        );
      }
      if (updateDetails?.gps_procurer) {
        setProcurer(updateDetails?.gps_procurer);
      }
      if (updateDetails?.gps_activated) {
        setActivate(Boolean(updateDetails?.gps_activated));
      }
      if (updateDetails?.gps_sim_number) {
        setSimNumber(updateDetails?.gps_sim_number);
      }
      if (updateDetails?.gps_unique_identifier) {
        setUniqueIdentifier(updateDetails?.gps_unique_identifier);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      // setLoading(false);
    }
  }

  function makeDefault() {
    setUniqueIdentifier("");
    setUniqueNumber("");
    setProcurer("");
    setDOI(null);
  }

  function closeFun() {
    handleClose();
    makeDefault();
  }

  useEffect(() => {
    if (open && updateDetails?.application_id) {
      getGpsDetails();
    }
  }, [open, updateDetails]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeFun}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>GPS Number</span>
            {updateDetails?.gps_unique_number && (
              <Button
                className="d-flex align-items-center"
                color={isDisabled ? "primary" : "error"}
                variant="contained"
                onClick={() => {
                  setIsDisabled((prev) => !prev);
                }}
                sx={{
                  fontSize: 12,
                }}
                endIcon={
                  isDisabled ? (
                    <EditOutlinedIcon
                      style={{
                        fontSize: "14px",
                      }}
                    />
                  ) : (
                    <CloseIcon
                      style={{
                        fontSize: "16px",
                      }}
                    />
                  )
                }
              >
                Edit
              </Button>
            )}
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container className="w-100">
            <Grid item xs={12} className="mb-4">
              <div style={{ width: "50%" }}>
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                  className="font-inter"
                >
                  Procurer{" "}
                  <span
                    style={{
                      color: "rgba(255, 34, 34, 1)",
                    }}
                  >
                    *
                  </span>
                </h6>
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    variant="outlined"
                    value={procurer}
                    disabled={isDisabled}
                    onChange={(e) => {
                      setProcurer(e.target.value);
                    }}
                  >
                    <MenuItem value={"mmi"}>MMI</MenuItem>
                    <MenuItem value={"mufin"}>Mufin</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} className="mb-4">
              <div style={{ width: "50%" }}>
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                  className="font-inter"
                >
                  Enter {procurer === "mmi" ? "MMI" : "Unique"} number{" "}
                  <span
                    style={{
                      color: "rgba(255, 34, 34, 1)",
                    }}
                  >
                    *
                  </span>
                </h6>
                <div>
                  <TextField
                    variant="outlined"
                    // className="mt-2"
                    className="w-100"
                    id="outlined-multiline-static"
                    rows={4}
                    fullWidth
                    value={unique_number}
                    onChange={(e) => {
                      setUniqueNumber(e.target.value);
                    }}
                    disabled={isDisabled}
                  />
                </div>
              </div>
            </Grid>
            {procurer !== "mmi" && (
              <Grid item xs={12} className="mb-4">
                <div style={{ width: "50%" }}>
                  <h6
                    style={{
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                    className="font-inter"
                  >
                    Enter Sim number{" "}
                    <span
                      style={{
                        color: "rgba(255, 34, 34, 1)",
                      }}
                    >
                      *
                    </span>
                  </h6>
                  <div>
                    <TextField
                      variant="outlined"
                      // className="mt-2"
                      className="w-100"
                      id="outlined-multiline-static"
                      rows={4}
                      fullWidth
                      value={simNumber}
                      onChange={(e) => {
                        setSimNumber(e.target.value);
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={12} className="mb-4">
              <div style={{ width: "50%" }}>
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                  className="font-inter"
                >
                  GPS installation date
                  <span
                    style={{
                      color: "rgba(255, 34, 34, 1)",
                    }}
                  >
                    {" "}
                    *
                  </span>
                </h6>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box>
                      <DemoItem>
                        <DatePicker
                          sx={{ width: 200 }}
                          slotProps={
                            {
                              // textField: {
                              //   helperText: error.settlementDateMs,
                              // },
                              // field: {
                              //   clearable: true,
                              //   onClear: () => setCleared(true),
                              // },
                            }
                          }
                          format="DD-MM-YYYY"
                          // minDate={
                          //   updateDetails?.disbursal_date
                          //     ? dayjs(
                          //         dayjs(
                          //           updateDetails?.disbursal_date,
                          //           "DD-MM-yyyy"
                          //         )
                          //       )
                          //     : ""
                          // }
                          disableFuture
                          value={DOI}
                          disabled={isDisabled}
                          inputVariant="outlined"
                          onChange={(date) => {
                            setDOI(date);
                          }}
                          animateYearScrolling
                        />
                      </DemoItem>
                    </Box>
                  </LocalizationProvider>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} className="mb-4">
              <div style={{ width: "50%" }}>
                <h6
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                  className="font-inter"
                >
                  Unique Identifier{" "}
                  <span
                    style={{
                      color: "rgba(255, 34, 34, 1)",
                    }}
                  >
                    *
                  </span>
                </h6>
                <div>
                  <TextField
                    variant="outlined"
                    // className="mt-2"
                    className="w-100"
                    id="outlined-multiline-static"
                    rows={4}
                    fullWidth
                    value={uniqueIdentifier}
                    onChange={(e) => {
                      setUniqueIdentifier(e.target.value);
                    }}
                    disabled={isDisabled}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      isDisabled || Boolean(updateDetails?.gps_activated)
                    }
                    checked={activate}
                    onChange={(e, checked) => {
                      setActivate(checked);
                    }}
                  />
                }
                label="Activate"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeFun}>Cancel</Button>
          {loading ? (
            <>
              <Button>
                <CircularProgress size={15} />
              </Button>
            </>
          ) : (
            <Button
              onClick={handleUpdateMMINumber}
              disabled={
                isDisabled ||
                unique_number === "" ||
                uniqueIdentifier === "" ||
                !DOI ||
                procurer === ""
              }
            >
              Proceed
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
