import React, { useEffect } from "react";
import { useState } from "react";
import CustomTableV2 from "../../../Components/CustomTable/CustomTableV2";
import { toast } from "react-toastify";
import { getGpsInventoryV2 } from "../../../api";
import { f } from "../../../utility/Formater";
import { Chip } from "@mui/material";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";

const statusColor={
    "GPS Required (High Priority)":"#FF0000",
    "GPS Required (Low Priority)":"#D15800",
    "GPS Required":"#D15800",
    "GPS Not Required":"#04BD00",
    "New Dealer":"#0068FF",
    "Inactive Dealer":"#757575",
    "Denied For Gps":"#CCCCCC",
}

const GPSDistribution= forwardRef(({ setCardData }, ref) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(100);
  const [totalCount, setTotalCount] = useState(0);

  let columns = [
    {
      label: <span className="text-nowrap">Dealer Name</span>,
      render: (index) => f(tableData?.[index]?.dealership_name),
    },
    {
      label: <span className="text-nowrap">Dealer Contact</span>,
      render: (index) => f(tableData?.[index]?.dealer_contact),
    },
    {
      label: <span className="text-nowrap">Status</span>,
      render: (index) =>{
        let status = tableData?.[index]?.status;
        if(!status) f(null);
        return <Chip label={status?.replace(/\(.*?\)/g, '')} sx={{backgroundColor:statusColor?.[status],color:"#fff",fontWeight:500}} fullWidth />
      },
    },
    {
      label: <span className="text-nowrap">Installation Target</span>,
      render: (index) => f(tableData?.[index]?.installation_target),
    },
    {
      label: <span className="text-nowrap">Sent to Dealer</span>,
      render: (index) => f(tableData?.[index]?.sent_to_dealer),
    },
    {
      label: <span className="text-nowrap">No. of GPS intalled</span>,
      render: (index) => f(tableData?.[index]?.gps_installed),
    },
    {
      label: <span className="text-nowrap">Inventory Remaining</span>,
      render: (index) => f(tableData?.[index]?.gps_remaining),
    },
  ];

  async function handleFetchData(search,filter,pg,setLoader){
    try{
        if(setLoader) setLoader(true);
        setTableLoading(true);
        if(pg) setPage(pg);
        let params = `page=${pg || page}&limit=${limit}`;
        if(search) params+=`&search=${search}`;
        if(filter) params+=`&filters=${JSON.stringify(filter)}`;
        const { data } = await getGpsInventoryV2(params);
        setTableData(data?.data?.data || []);
        setTotalCount(data?.data?.total_count || 0);
        setCardData({
            gps_installed:data?.data?.total_gps_installed ||0,
            gps_remaining:data?.data?.total_gps_remaining ||0,
            installation_target:data?.data?.total_installation_target || 0,
            sent_to_dealer:data?.data?.total_sent_to_dealer || 0
        })
    }catch(error){
        toast.error(error?.response?.data?.message || error?.message || 'failed to fetch data');
    }finally{
        setTableLoading(false);
        if(setLoader) setLoader(false);
    }
  }

  useEffect(()=>{
    handleFetchData();
  },[limit,page,]);

  useImperativeHandle(ref, () => ({
    handleFilterData(search,filter,page,setLoader){
        handleFetchData(search,filter,page,setLoader);
    },
  }));

  return (
    <div className="w-100 mt-1">
      <CustomTableV2
        serialNo={true}
        tableHeight={"58vh"}
        columns={columns}
        tableData={tableData}
        loadingRows={20}
        isLoading={tableLoading}
        isPaginated={true}
        totalCount={totalCount}
        page={page}
        limit={limit}
        onPageChange={(pg) => {
          setPage(pg);
        }}
      />
    </div>
  );
})

export default GPSDistribution;
